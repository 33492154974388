import React from "react";
import { getLanguageContent } from "../../../../../../../../../../../../core/react/src/helpers/Language";
import { generateCSSString } from "../../../../../../../helpers/style";
import Button from "../../../../Button";
// import { getLanguageAsString } from "../../../../../../../helper/Language";
import axios from "axios";


export default function UniversityCard(props) {

    const component = props.component;
    const card = props.card;
    const backgroundColor = generateCSSString(component, ['cardBackground_color']);
    const primaryColor = generateCSSString(component, ['cardPrimary_color']);
    const secondaryColor = generateCSSString(component, ['cardSecondary_color']);


    const getType = () => {
        if(card.therapyarea)
            return getLanguageContent('producttraining', 'Produkttraining');
        if(card.time_date)
            return getLanguageContent('webinar', 'Webinar');
        return getLanguageContent('eLearning', 'Zertifizierte Weiterbildung');
    }

    const getTimeString = () => {
        if (card['time_date'] && card['time_from'] && card['time_to']) {
            const splittedFrom = card['time_from'].split(':');
            const splittedTo = card['time_to'].split(':');

            if (splittedFrom.length === 3 && splittedTo.length === 3) {
                return getLanguageContent('text-at', 'Am') + ' ' + card['time_date'] + ' ' + splittedFrom[0] + ':' + splittedFrom[1] + ' ' +
                    getLanguageContent('text-to', 'bis') + ' ' + splittedTo[0] + ':' + splittedTo[1] + ' ' + getLanguageContent('text-oClock', 'Uhr ')
            }
            return getLanguageContent('text-at', 'Am') + ' ' + card['time_date'] + ' ' + card['time_from'] + ' ' +
                getLanguageContent('text-to', 'bis') + ' ' + card['time_to'] + ' ' + getLanguageContent('text-oClock', 'Uhr ')
        }

    }

    const getDuration = (durationAsString) => {
        const [ hours, minutes, seconds ] = durationAsString.split(':');
        let output = '';
        if(Number(hours) > 0) {
            if(Number(hours) > 1) {
                output += Number(hours) + ' ' + getLanguageContent('hours', 'Stunden');
            } else {
                output += Number(hours) + ' ' + getLanguageContent('hour', 'Stunde');
            }
        }
        if(Number(minutes) > 0) {
            if(Number(hours) > 0) {
                output += ' ' + getLanguageContent('and', 'und') + ' ';
            }
            if(Number(minutes) > 1) {
                output += Number(minutes) + ' ' + getLanguageContent('minutes', 'Minuten');
            } else {
                output += Number(minutes) + ' ' + getLanguageContent('minute', 'Minute');
            }
        }

        return output;
    }

    const generateCertificate = (e) => {
        e.preventDefault();
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getCertificate/certificate",
            data: {
                type: "elearning",
                uuid: card.uuid, // uuid
                // lang: getLanguageAsString(card.lang) TO-DO: Update to accept diferent languages(!!!)
            }
        }).then(data => {
            if (data.data && data.data.status === 'success') {
                const aEl = document.createElement('a');
                aEl.href = data.data.data.file;
                aEl.target = '_blank';
                aEl.download = 'certificate';
                aEl.click();
            } else {
                if(data.data.message) {
                    alert(data.data.message)
                } else {
                    alert('Error while generating the certificate - please try again')
                }
            }
        });
    }
 
    return (
        <a className={`w-full h-full relative ${!card.link ? 'card-shadow' : ''}`} href={card.link} >

            <div 
                className="flex flex-col university-card rounded-lg h-full relative overflow-hidden"
                style={{
                        backgroundColor: backgroundColor
                    }}
            >

                <img 
                    src={`${card.image}`} 
                    className='w-full object-cover cardImageHeight' 
                />

                <div className='flex flex-col h-full p-5 grow'>
                    <div 
                        className="text-sm uppercase font-600 mb-2"
                        style={{
                            color: secondaryColor
                        }}
                    >
                        {getType()}
                    </div>

                    <div 
                        className='text-xl font-bold mb-3'
                        style={{
                            color: primaryColor
                        }}
                    >
                        {card.title}
                    </div>

                    <div className='grid grid-rows-2 gap-y-3 mt-auto'>
                    
                        {card.time_date && <div className='flex'>
                            <svg className="w-6 h-6"
                                 color="#7A00E6"
                                 xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 22"
                                 stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
                            </svg>

                            <div className="ml-2">{getTimeString()}</div>
                        </div>}
                        {card.duration &&
                            <div className='flex'>
                                <svg className="w-6 h-6"
                                     color="#7A00E6"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 22"
                                     stroke-width="1.5" 
                                     stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                <div className="ml-2">{getDuration(card.duration)}</div>
                            </div>}

                        {card.accreditation &&
                            <div className='flex'>
                            <svg className="w-6 h-6"
                                color="#7A00E6"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor">
                                <g id="Gruppe_1101" data-name="Gruppe 1101" transform="translate(0 0)">
                                    <g id="Gruppe_1100" data-name="Gruppe 1100" clip-path="url(#clip-path)"
                                    transform="translate(3.5, 0)">
                                    <path id="Pfad_843" data-name="Pfad 843" d="M3.605,13.619l.052,5.2h0v2.763a1.376,1.376,0,0,0,.758,1.245,1.438,1.438,0,0,0,.65.163,1.243,1.243,0,0,0,.759-.217l2.491-1.625,2.437,1.571.109.054a1.443,1.443,0,0,0,1.408-.054,1.36,1.36,0,0,0,.65-1.191V14.063"/>
                                    <path id="Pfad_844" data-name="Pfad 844" d="M7.6,1.208a1.255,1.255,0,0,1,1.383,0l.676.445a1.267,1.267,0,0,0,.764.205l.809-.05a1.255,1.255,0,0,1,1.2.691l.365.726a1.257,1.257,0,0,0,.558.559l.733.369a1.254,1.254,0,0,1,.691,1.2l-.049.808a1.253,1.253,0,0,0,.2.764l.448.677a1.249,1.249,0,0,1,0,1.382l-.448.681a1.267,1.267,0,0,0-.2.764l.049.808a1.254,1.254,0,0,1-.691,1.2l-.726.365a1.257,1.257,0,0,0-.558.559l-.369.733a1.255,1.255,0,0,1-1.2.691l-.809-.05a1.267,1.267,0,0,0-.764.2l-.676.449a1.255,1.255,0,0,1-1.383,0l-.679-.449a1.263,1.263,0,0,0-.764-.206l-.809.05a1.255,1.255,0,0,1-1.2-.691l-.365-.726a1.262,1.262,0,0,0-.558-.559L2.5,12.434a1.253,1.253,0,0,1-.687-1.2l.049-.808a1.267,1.267,0,0,0-.205-.764l-.445-.68a1.255,1.255,0,0,1,0-1.383l.445-.677a1.264,1.264,0,0,0,.205-.763l-.049-.809a1.255,1.255,0,0,1,.688-1.2l.725-.365a1.282,1.282,0,0,0,.562-.564L4.149,2.5a1.255,1.255,0,0,1,1.2-.691l.809.049a1.257,1.257,0,0,0,.767-.207Z"/>
                                    </g>
                                </g>
                                </svg>


                                <div className="ml-2 mb-1">{card.accreditation.map(obj => obj.accreditation_points).join(', ')}</div>
                            </div>
                        }

                        {card.therapyarea &&
                            <div className='flex'>
                            <svg className="w-6 h-6"
                                color="#7A00E6"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="3.5" 
                                stroke="currentColor">

                                <g id="Gruppe_1422" data-name="Gruppe 1422" clip-path="url(#clip-path)" transform="matrix(0.34155943,0,0,0.34155943,-1.483015,-1.2916849)">
                                    <path id="Pfad_940" data-name="Pfad 940" d="M62.591,214.013v.263h0a4.41,4.41,0,0,1,2.637,7.945L50.5,233.213a15.558,15.558,0,0,1-9.256,3.1l-18.743.113-18.7.113H3.784a3.484,3.484,0,0,1-3.476-3.455l-.045-7.479a3.48,3.48,0,0,1,3.455-3.5l4.3-.026a.264.264,0,0,0,.164-.059l5.221-4.238a14.727,14.727,0,0,1,9.164-3.27l9.149-.055,1.87-.011,7.478-.045h.022a3.476,3.476,0,0,1,.02,6.953l-7.479.045-1.87.011a2.133,2.133,0,0,0,.013,4.265h.013l14.092-.085a.264.264,0,0,0,.156-.052l13.925-10.391a4.376,4.376,0,0,1,2.633-.875Zm0,0a4.656,4.656,0,0,0-2.79.927L45.876,225.331l-14.092.085h-.011a1.87,1.87,0,0,1-.011-3.739l1.87-.011,7.479-.045a3.739,3.739,0,0,0-.022-7.479h-.023l-7.479.045-1.87.011-9.149.055a14.928,14.928,0,0,0-9.329,3.328l-5.221,4.238-4.3.026A3.735,3.735,0,0,0,0,225.608l.045,7.479A3.735,3.735,0,0,0,3.784,236.8h.023l18.7-.113,18.743-.113a15.906,15.906,0,0,0,9.411-3.153l14.728-10.992a4.673,4.673,0,0,0-2.794-8.419" transform="translate(5.842 -164.803)"/>
                                    <path id="Pfad_941" data-name="Pfad 941" d="M175.06.263a10.928,10.928,0,0,1,7.735,18.651L167.669,34.025a2.665,2.665,0,0,1-3.764,0L148.795,18.914A10.928,10.928,0,0,1,164.249,3.46l1.359,1.359a.263.263,0,0,0,.372,0L167.34,3.46a10.992,10.992,0,0,1,7.72-3.2m0-.263a11.2,11.2,0,0,0-7.906,3.273l-1.359,1.359-1.359-1.359A11.191,11.191,0,0,0,148.608,19.1l15.111,15.111a2.928,2.928,0,0,0,4.136,0L182.98,19.1A11.191,11.191,0,0,0,175.06,0" transform="translate(-114.009 5.841)"/>
                                </g>
                                </svg>

                                <div className="ml-2 mb-1">{card.therapyarea}</div>
                            </div>
                        }

                        {card.buttonLink &&
                            <div className={"flex justify-center"}>
                                <Button 
                                    component={
                                        {
                                        ...component,
                                        btnText: getLanguageContent("my-content-join-zoom", "Teilnehmen"),
                                        btnLink: card.buttonLink,
                                        }
                                    }
                                />
                            </div>
                        }

                        {card.certificate &&
                            <div className={"flex justify-center"}>
                                <Button 
                                    component={
                                        {
                                            ...component,
                                            btnText: getLanguageContent("text-eLearning-success-certificate-download", "Zertifikat herunterladen"),
                                            btnLink: "#",
                                            btnTarget: '_self',
                                            onClick: generateCertificate
                                        }
                                    }
                                />
                            </div>
                        }

                    </div>
                </div>

                {!card.buttonLink && !card.certificate &&
                    <div
                        className="group-hover:block absolute inset-0 rounded-lg h-full opacity-75 text-transformation"
                        style={{
                            backgroundColor: primaryColor
                        }}
                        >
                    </div>
                }
                {/* .. */}

                {!card.buttonLink && !card.certificate &&
                <div
                    className="group-hover:block absolute inset-0 h-full text-white text-transformation">
                    <div className="px-4 py-14">
                        <div className="text-sm uppercase font-600 mb-2 ">
                            {getType()}
                        </div>
                        <div className='text-xl font-bold mb-3'>
                            {card.title}
                        </div>
                        {card.description && <div className='h-full text-sm overflow-hidden font-sans'>
                            <div className='ellipsis-card' dangerouslySetInnerHTML={{__html: card.description}}></div>
                            <a className="underline underline-offset-1"
                               href={card.link}>{getLanguageContent('text-more', 'Mehr')}</a>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
        </a>
    )
}