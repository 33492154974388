import React, {useEffect, useState} from 'react';
import CustomInput from "../../../../elements/Input/CustomInput";
import SecondDefaultButton from "../../../../elements/Button/SecondDefaultButton";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faCircleCheck, faCircleExclamation, faDoorOpen} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import {getLanguageContent, TranslateHtml} from "../../../../libs/LanguageProvider";
import BackButton from "../../../../elements/Button/BackButton";


/*
 *  The intital Login Screen - bunddle all components for the Login Page
    props:
            languageitemfunction: function to get the languagecomponent
            token: the token to reset password

 *
 */
export default function PasswordNew(props) {
    const [newPassword, setNewPassword] = useState(['', false])
    const [userId, setUserId] = useState(-1)

    const [windowstate, setWindowstate] = useState('...')
    const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\.#?!@$%^&*-]).{8,}$');
    const [validToken, setValidToken] = useState(null)
    const [disableNewPwd, setDisableNewPwd] = useState(false)

    useEffect(async () => {
        if (props.runTokenCheck) {
            await checkForValidToken();
        }
    }, []);

    async function checkForValidToken() {
        axios.post('/checkForValidToken/resetPassword', {
            token: props.token,
        }).then(r => {
            //console.log(r.data)
            if (r.data.status === 'success') {
                setWindowstate('ValidToken')
                setValidToken(true)
                setUserId(r.data.userid)
            } else {
                setWindowstate('InvalidToken')
                setValidToken(false)
            }
        })
    }

    const validatePassword = (newPwd) => {
        if (!validPassword.test(newPwd)) {
            setNewPassword([newPwd, true]);
        } else {
            setNewPassword([newPwd, false]);
        }
    }

    const resetPassword = () => {
        validatePassword(newPassword[0])
        if (!newPassword[1]) {
            setDisableNewPwd(true)
            axios.post('/newPassword', {
                language: props.lang,
                userId: userId,
                password: newPassword[0]
            }).then(r => {
                if (r.data.status === 'success') {
                    setWindowstate('SuccessUpdatePassword')
                } else {
                    setWindowstate('ErrorUpdatePassword')
                }
                setDisableNewPwd(false)
            })
        }
    }

    return (
        <div className={"loginscreen-left-content no-border"}>
            <div className="loginscreen-left col-12 col-xl-5">
                <div>
                    {(() => {
                        switch (windowstate) {
                            case 'ValidToken':
                                return (
                                    <div className="login-mt-5 pb-2">
                                        <h1 className="LoginHeader AvantGardeGothicBold">
                                            {getLanguageContent("reset-password-token", "Passwort zurücksetzen")}</h1>
                                        <div className="mt-2">{getLanguageContent(
                                            "login-reset-password-token-description",
                                            "Geben Sie ihr neues Passwort ein.")}
                                        </div>

                                        <div className="login-mt-1">
                                            <CustomInput
                                                title={getLanguageContent('login-new-password', 'Neues Passwort')}
                                                type='password'
                                                errormessage={<TranslateHtml name='validation-password'/>}
                                                showErrorAsDefaultText={true}
                                                validation={newPassword[1]}
                                                changeEvent={(e) => validatePassword(e)}
                                            />
                                        </div>
                                        <div className="login-mt-2 registration">
                                            <SecondDefaultButton
                                                title={getLanguageContent("login-reset-password-token", "Passwort ändern")}
                                                clickevent={resetPassword}
                                                disable={disableNewPwd}
                                            />
                                        </div>
                                    </div>
                                )
                            case 'InvalidToken':
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className='center-content'><FontAwesomeIcon icon={faCircleExclamation}
                                                                                         size='7x'
                                                                                         color='#9B0805'/></div>

                                        <h2
                                            className='login-message-header login-error-message-header'>
                                            {getLanguageContent("login-invalid-token-header", 'Ungültiges Token')}
                                        </h2>
                                        <div
                                            className='login-error-message-text login-message-text mt-4'>
                                            {getLanguageContent(
                                                "login-invalid-token-content",
                                                'Dies ist kein valides Token entweder ist es abgelaufen, bereits benutzt worden oder falsch. Bitte fordern Sie, falls gewünscht, ein neues Token an.'
                                            )}
                                        </div>
                                        <div className='center-content login-mt-2'>
                                            <BackButton
                                                title={
                                                    <div>
                                                        {getLanguageContent("back-loginpage", 'Zurück zu Loginpage')}
                                                    </div>}
                                                clickevent={() => {props.showLoginForm();}}
                                                />
                                        </div>
                                    </div>)
                            case 'SuccessUpdatePassword':
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className='center-content'><FontAwesomeIcon icon={faCircleCheck} size='7x'
                                                                                         color='#22004C'/></div>
                                        <div className='mt-3'>
                                            <div
                                                className='login-message-header login-success-message-header'>
                                                {getLanguageContent("login-pwd-reset-header-success", 'Wir konnten Ihr Passwort erfolgreich updaten.')}</div>
                                            <div className='center-content login-mt-2'>
                                                <BackButton
                                                    title={
                                                        <div>
                                                            {getLanguageContent("back-loginpage", 'Zurück zu Loginpage')}
                                                        </div>}
                                                    clickevent={() => {props.showLoginForm();}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            case 'ErrorUpdatePassword':
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className='center-content'><FontAwesomeIcon icon={faCircleExclamation}
                                                                                         size='7x'
                                                                                         color='#9B0805'/></div>

                                        <h2
                                            className='login-message-header login-error-message-header'>
                                            {getLanguageContent("login-unable-reset-pwd-header", 'Passwort konnte nicht zurückgesetzt werden')}
                                        </h2>
                                        <div
                                            className='login-error-message-text login-message-text'>
                                            {getLanguageContent(
                                                "login-unable-reset-pwd-content",
                                                'Ups, etwas ist schief gegangen. Wir konnten Ihr Passwort nicht ändern. Bitte laden Sie die Seite neu und versuchen es erneut.'
                                            )}
                                        </div>
                                    </div>
                                )
                            default:
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className='center-content'>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    </div>
                                )
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}