import React, {useState, useEffect, useMemo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faUndo} from "@fortawesome/free-solid-svg-icons";
import {getLanguageContent} from "../../../libs/LanguageProvider";

export default function PDBBrandList(props) {
    const preview = props.preview;
    const [brands, setBrands] = useState(props.brands);
    const [itemHeight, setItemHeight] = useState('auto');
    const [state, setState] = useState({
        searchTerm: '',
        filteredBrands: props.brands ?? [],
    });

    const [activeCategories, setActiveCategories] = useState([]);

    const categoryFilters = [
        getLanguageContent("pdb-brand-category-0", "Allergie"),
        getLanguageContent("pdb-brand-category-1", "Husten & Erkältung"),
        getLanguageContent("pdb-brand-category-2", "Verdauung"),
        getLanguageContent("pdb-brand-category-3", "Schmerzen"),
        getLanguageContent("pdb-brand-category-4", "Vitamine"),
    ];

    const openLink = (brandLink) => {
        window.location = brandLink;
    }

    useEffect(() => {
        setState({
            ...state,
            ...{
                filteredBrands: brands
            }
        });
    }, [brands]);

    const resizeProductItems = () => {
        const element = document.querySelector('.product-item-wrapper');
        if (element == null) {
            return;
        }

        if (itemHeight != element.clientWidth) {
            setItemHeight(element.clientWidth);
        }
    }

    useEffect(() => {
        resizeProductItems();

        window.addEventListener('resize', resizeProductItems);

        return () => {
            window.removeEventListener('resize');
        }
    }, [])

    const onSearchTextChange = (e) => {
        const search = e.currentTarget.value;

        setState({...state,
            ...{
                searchTerm: search,
                filteredBrands: brands.filter((brand) => search.length == 0 || brand.name.toLowerCase().indexOf(search.toLowerCase()) != -1),
            }
        })
    }

    const resetSearch = () => {
        setState({...state,
            ...{
                searchTerm: '',
                filteredBrands: brands,
            }
        })
    }

    const switchCategory = (index) => {
        const activeCats = [...activeCategories];
        if (activeCats.includes(index)) {
            for (let x in activeCats) {
                if (activeCats[x] == index) {
                    activeCats.splice(x, 1);
                }
            }
        } else {
            activeCats.push(index);
        }

        setActiveCategories(activeCats);
    }

    return (
        <>
            <div className="page-section-top">
                <p>{getLanguageContent("pdb-brandlist-title", "Produktfinder - Informationen über die Wirkstoffe und die Anwendung der Produkte von Sanofi in Österreich.")}</p>
            </div>

            <div className="page-section-content">
                <div className="toolbar-base first" style={{flexDirection: 'column'}}>
                    <div className="search-holder">
                        <div className="text-wrapper ref-marker">
                            <p>{getLanguageContent("pdb-brandlist-search-caption", "Markensuche")}</p>
                        </div>
                        <div className="search-term-wrapper">
                            <input className="search-term-input" value={state.searchTerm}
                                   type="text"
                                   maxLength="50"
                                   spellCheck="false"
                                   onChange={onSearchTextChange}
                            ></input>
                            <FontAwesomeIcon className={"fa fa-search"} icon={faSearch} />
                        </div>
                        <FontAwesomeIcon className={"fa fa-undo"} icon={faUndo} style={{ display: (state.searchTerm.length == 0 ? 'none' : '') }} onClick={resetSearch} />
                    </div>
                    <div id={"brand-filter"}>
                        {categoryFilters.map((filterName, index) => {
                            return (
                                <div key={index}
                                     className={"brand-filter-btn" + (activeCategories.includes(index) ? ' active' : '')}
                                     onClick={() => switchCategory(index)}>
                                    {filterName}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="element-list">
                    { state.filteredBrands.length > 0 ? state.filteredBrands.map((brand, index) => {
                        if (activeCategories.length > 0 && !activeCategories.includes(brand.category)) {
                            return (
                                <></>
                            );
                        }

                        return (
                            <div key={index} className="product-item-wrapper" style={{ height: itemHeight }} onClick={() => {openLink(brand.link)}}>
                                <div className="element-list-item bg-sanofi-offer-item">
                                    <div class={"brand-center"}>
                                        { (brand.img.length > 0 && brand.img.indexOf('core') == -1) ? <img src={ brand.img } title={brand.imgAlt ?? ''} alt={brand.imgAlt ?? ''}></img> : <h2 dangerouslySetInnerHTML={{__html: brand.name}}></h2> }
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <h3 className="no-product-found">Es konnten keine passenden Marken gefunden werden.</h3>}
                </div>
            </div>
        </>
    )
}