import React, {Fragment} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import {useParams} from "react-router-dom";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ProducttrainingComponent from "../../components/Producttraining";
import {getLanguageContent} from "../../libs/LanguageProvider";


// PATH => /university/producttrainings/{slug}
export default function Producttraining(props) {

    let {slug} = useParams();

    return (
        <Page>
            <TopBar logo={'Sanofi University'}/>
            <Hero/>
            <ContentArea>
                <ProducttrainingComponent slug={slug}/>
            </ContentArea>
            <Footer/>
        </Page>
    )
}

