import React from 'react';
import './Buttondesign.css'

/*
    Custom Button
    props:
        title: title of the Button
        clickevent: method to callback by click
         disable: disable button
 */
const DefaultButton = (props) => {
    return (
        <div>
            <button className="defaultBtnSecond w h" type={props.type || "submit"} onClick={props.clickevent}
                    disabled={props.disable}>  {(props.disable) ?
                <div className="flex justify-center items-center">
                    <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div> :
                <div>{props.title}</div>}</button>
        </div>
    )
}

export default DefaultButton;