import React from 'react';
import {useState} from "react";
import './Buttondesign.css'
import {Spinner} from "react-bootstrap";

/*
    Custom Button
    props:
        title: title of the Button
        clickevent: function to handle clickevent
        btype: type of button (default:button)
        bSize: define size/width of button (currently xl and default)
        disable: disable button
 */
const DefaultButton = (props) => {

    return (
        <div>
            {
                (props.bSize && props.bSize === 'xl') ?
                    <button className="defaultBtn w-xl h" onClick={props.clickevent}
                            type={(props && props.btype) ? props.btype : 'button'} disabled={props.disable}>
                        {(props.disable) ?
                            <div className="flex justify-center items-center">
                                <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div> : <div>{props.title}</div>}
                    </button>
                    :
                    <button className="defaultBtn w h" onClick={props.clickevent}
                            type={(props && props.btype) ? props.btype : 'button'}>
                        {(props.disable) ?
                            <div className="flex justify-center items-center">
                                <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div> : <div>{props.title}</div>}
                    </button>


            }
        </div>
    )
}

export default DefaultButton;