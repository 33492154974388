import React, {Fragment, useEffect, useState} from "react";
import {getLanguageContent} from "../../../../libs/LanguageProvider";
import "./style/schedule.css"

// PATH => /university/producttrainings/{slug}
export default function Schedule() {

// inspired by https://codepen.io/havardob/pen/xxPqXdO
    return (
        <Fragment>
            <div className="bg-sanofi-dark-lila rounded-md p-5 pb-20 md:hidden">

                <h2 className="text-xl avgDemi font-bold text-center uppercase font-600 mb-5 text-sanofi-white">{getLanguageContent('how-does-producttraining-work-title', 'Wie läuft ein Produkttraining ab?')}</h2>

                <div className="mt-5">

                    <div className="flex justify-center">

                        <ol className="timeline">
                            <li className="timeline-item">
                            <span className="timeline-item-icon | faded-icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16" viewBox="0 0 69 69">
                                    <g id="Gruppe_1185" data-name="Gruppe 1185"
                                       transform="translate(-518.683 -1108.177)">
                                        <g id="Ellipse_40" data-name="Ellipse 40"
                                           transform="translate(518.683 1108.177)"
                                           fill="#3d2469" stroke="#fff" stroke-width="2">
                                            <circle cx="34.5" cy="34.5" r="34.5" stroke="none"/>
                                            <circle cx="34.5" cy="34.5" r="33.5" fill="none"/>
                                        </g>
                                        <path id="Pfad_877" data-name="Pfad 877"
                                              d="M23.2,13.4l7.324-7.324a1.164,1.164,0,0,1,1.986.822V24.552a1.164,1.164,0,0,1-1.986.822L23.2,18.051M5.741,26.2H19.706A3.491,3.491,0,0,0,23.2,22.706V8.741A3.491,3.491,0,0,0,19.706,5.25H5.741A3.491,3.491,0,0,0,2.25,8.741V22.706A3.491,3.491,0,0,0,5.741,26.2Z"
                                              transform="translate(536.902 1126.967)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
		                    </span>
                                <div className="timeline-item-description">
                                    <div
                                        className="place-self-center text-sanofi-white text-bold">
                                        <b> 1. {getLanguageContent('producttraining-procedure-see-producttraining', 'Training anschauen')} </b>
                                        <div
                                            className="text-sm mt-2">
                                            {getLanguageContent('producttraining-procedure-see-producttraining-description', 'Nachdem das Video vollständig angeschaut wurde, wird der Schritt 2 freigeschaltet')}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="timeline-item mt-5">
                            <span className="timeline-item-icon | faded-icon mt-7">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16" viewBox="0 0 69 69">
                                <g id="Gruppe_1411" data-name="Gruppe 1411" transform="translate(-1300 -1087)">
                                    <g id="Ellipse_42" data-name="Ellipse 42" transform="translate(1300 1087)"
                                       fill="#3d2469" stroke="#fff" stroke-width="2">
                                        <circle cx="34.5" cy="34.5" r="34.5" stroke="none"/>
                                        <circle cx="34.5" cy="34.5" r="33.5" fill="none"/>
                                    </g>
                                    <path id="Pfad_879" data-name="Pfad 879"
                                          d="M31.847,7.915V23.852a3.415,3.415,0,0,1-3.415,3.415H5.665A3.415,3.415,0,0,1,2.25,23.852V7.915m29.6,0A3.415,3.415,0,0,0,28.432,4.5H5.665A3.415,3.415,0,0,0,2.25,7.915m29.6,0v.369a3.415,3.415,0,0,1-1.624,2.908l-11.383,7a3.415,3.415,0,0,1-3.582,0l-11.383-7A3.415,3.415,0,0,1,2.25,8.285v-.37"
                                          transform="translate(1317.952 1107.872)" fill="none" stroke="#fff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
		                    </span>
                                <div className="timeline-item-description">
                                    <div
                                        className="place-self-center text-sanofi-white ">
                                        <b>2. {getLanguageContent('producttraining-procedure-certificate', 'Zertifikat herunterladen')}</b>
                                        <div
                                            className="text-sm mt-2">
                                            {getLanguageContent('producttraining-procedure-certificate-description', 'Sie können ihr Zertifikat sofort herunterladen.')}
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ol>

                    </div>
                </div>

            </div>
            <div className="bg-sanofi-dark-lila rounded-md p-5 pb-20 hidden md:block">
                <h2 className="text-2xl avgDemi font-bold text-center uppercase font-600 mb-5 text-sanofi-white">{getLanguageContent('how-does-producttraining-work-title', 'Wie läuft ein Produkttraining ab?')}</h2>

                <div className="mt-20">

                    <div className="flex justify-center">
                        <div className="bg-sanofi-white h-1 w-1/2"></div>
                    </div>


                    <div className="basis-full grid grid-cols-2 -m-8">
                        <div className="place-self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16" viewBox="0 0 69 69">
                                <g id="Gruppe_1185" data-name="Gruppe 1185" transform="translate(-518.683 -1108.177)">
                                    <g id="Ellipse_40" data-name="Ellipse 40" transform="translate(518.683 1108.177)"
                                       fill="#3d2469" stroke="#fff" stroke-width="2">
                                        <circle cx="34.5" cy="34.5" r="34.5" stroke="none"/>
                                        <circle cx="34.5" cy="34.5" r="33.5" fill="none"/>
                                    </g>
                                    <path id="Pfad_877" data-name="Pfad 877"
                                          d="M23.2,13.4l7.324-7.324a1.164,1.164,0,0,1,1.986.822V24.552a1.164,1.164,0,0,1-1.986.822L23.2,18.051M5.741,26.2H19.706A3.491,3.491,0,0,0,23.2,22.706V8.741A3.491,3.491,0,0,0,19.706,5.25H5.741A3.491,3.491,0,0,0,2.25,8.741V22.706A3.491,3.491,0,0,0,5.741,26.2Z"
                                          transform="translate(536.902 1126.967)" fill="none" stroke="#fff"
                                          stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>

                        <div className="place-self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16" viewBox="0 0 69 69">
                                <g id="Gruppe_1411" data-name="Gruppe 1411" transform="translate(-1300 -1087)">
                                    <g id="Ellipse_42" data-name="Ellipse 42" transform="translate(1300 1087)"
                                       fill="#3d2469" stroke="#fff" stroke-width="2">
                                        <circle cx="34.5" cy="34.5" r="34.5" stroke="none"/>
                                        <circle cx="34.5" cy="34.5" r="33.5" fill="none"/>
                                    </g>
                                    <path id="Pfad_879" data-name="Pfad 879"
                                          d="M31.847,7.915V23.852a3.415,3.415,0,0,1-3.415,3.415H5.665A3.415,3.415,0,0,1,2.25,23.852V7.915m29.6,0A3.415,3.415,0,0,0,28.432,4.5H5.665A3.415,3.415,0,0,0,2.25,7.915m29.6,0v.369a3.415,3.415,0,0,1-1.624,2.908l-11.383,7a3.415,3.415,0,0,1-3.582,0l-11.383-7A3.415,3.415,0,0,1,2.25,8.285v-.37"
                                          transform="translate(1317.952 1107.872)" fill="none" stroke="#fff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>

                        <div
                            className="place-self-center text-sanofi-white mt-7">
                            1. {getLanguageContent('producttraining-procedure-see-producttraining', 'Training anschauen')}
                        </div>
                        <div
                            className="place-self-center text-sanofi-white mt-7">
                            2. {getLanguageContent('producttraining-procedure-certificate', 'Zertifikat herunterladen')}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

