import axios from 'axios';
import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { mapLangstoInitString, getDefaultActiveLangs } from '../helpers/language';
import { findComponentByKey, findComponentBySection, sections, setComponentObject } from '../helpers/components';

//initialPage 
const initialPage = {
    //language
    activeLang: null,
    editComponentActiveLang: null,
    availableLangs: null,

    //Settings
    pageparent: null,
    cmsproperties: null,
    forms: null,
    pdb: null, 
    availableComponents: null,

    //Page
    id: null,
    uuid: null,
    parentId: null,
    name: '',
    slug: '',
    roleId: null,
    published:  0, //unpublished 0 , published 1, published with changes 2
    hideHeader: 0,
    hideFooter: 0,
    changedFields: [],
    title: {},
    backgroundColor: null,

    //Meta
    noIndex: 0,
    metaTitle:null,
    metaDescription: null,
    metaKeywords: null,

    //optional
    matNumber: undefined,
    authProvider: undefined,

    //Components
    nextId: 0,
    toAddComponent: null,
    newComponent: null,
    changedNewCompFields: [], //TO-DO: handle
    selectedComponentId: null,
    selectedComponentParentId: null,
    cmsPageHeader: [],
    cmsPageHero:[],
    components: [],
    cmsPageFooter: [], // nedd component with page.id || page.uuid
    
    //validation
    validate: false,
    validationErrorFields: [],

    //others
    loading: true,
    openModal: null
}


// ASYNC THUNKS
export const getPageInBackend = createAsyncThunk("page/getPageInBackend", (arg) => { 
	return axios
		.post("/module/getPageInBackend/cmspage", arg) 
		.then((res) => res.data);
});


const pageSlice = createSlice({

    name: 'page',

    initialState: initialPage,

    reducers: {
        setInitialData: (page, action) => {
            const pageparent = action.payload.pageparent;
            const cmsproperties =  action.payload.cmsproperties;
            const forms =  action.payload.forms;
            const pdb =  action.payload.pdb;
            const languages = cmsproperties.languages;

            const initStringsByLang = mapLangstoInitString(languages);

            const getDefaultValue = (key) => {
                let entry = cmsproperties.properties.defaultValues?.find((prop) => prop.option === key);
                return entry ? entry.value : undefined;
            }

            let allComponents = {
                'cmsPageHeader': [
                   'cmsComponentMenu'
                ],
                'default': [
                    'cmsComponentContainer',
                    'cmsComponentAccordionList',
                    'cmsComponentButton', 
                    'cmsComponentCard',
                    'cmsComponentCardList', 
                    'cmsComponentCarousel',
                    'cmsComponentForm',
                    'cmsComponentHtml',
                    'cmsComponentImage',
                    'cmsComponentMenu',
                    'cmsComponentTitle', 
                    'cmsComponentText',
                    ],
                'cmsComponentAccordionList': [
                    'cmsComponentAccordion'
                ],
            };

            let availableComponents = {};

            for(let component of Object.keys(allComponents)){
                if(cmsproperties.properties[component] || component === 'default'){
                    availableComponents[component] = [];
                    for(let child of allComponents[component]){
                        if(cmsproperties.properties[child])
                            availableComponents[component].push(child);
                    }
                }
            }

            page.pageparent = pageparent ?? page.pageparent;
            page.cmsproperties = cmsproperties ?? page.cmsproperties;
            page.forms = forms ?? page.forms;
            page.pdb = pdb ?? page.pdb;
            page.availableComponents = availableComponents;

            page.activeLang =  languages[0];
            page.editComponentActiveLang =  languages[0];
            page.availableLangs = getDefaultActiveLangs(languages);

            page.parentId = pageparent?.id ??  null;
            page.roleId = getDefaultValue('roleId_');
            page.title = initStringsByLang;
            page.backgroundColor= getDefaultValue('pageBackground_color');
        
            //Meta
            page.metaTitle = initStringsByLang;
            page.metaDescription = initStringsByLang;
            page.metaKeywords = initStringsByLang;
        
            //optional
            page.matNumber = cmsproperties.matNumber ? initStringsByLang : page.matNumber;
            page.authProvider = cmsproperties.authproviders ? 'none' : page.authProvider;
        },
        setAvailableLang: (page, action) => {
            let {lang, value} = action.payload;
            page.availableLangs[lang] = value;
        },
        setValue: (page, action) => {
            const key = action.payload.key;
            if (action.payload.trackChange && !page.changedFields.includes(key)) 
                page.changedFields.push(key);
            page[key] = action.payload.value;
        },
        setNewCompValue: (page, action) => {
            const key = action.payload.key;
            if (!page.changedNewCompFields.includes(key)) 
                page.changedNewCompFields.push(key);
            page.newComponent[key] = action.payload.value; 
        },
        setValidate(page, action){
            page.validate = action.payload;
        },
        updateValidationErrorFields(page, action){
            const fieldName = action.payload.field;

            if(action.payload.action === 'add')
                page.validationErrorFields.push(fieldName);
            
            if(action.payload.action == 'delete'){
                const index = page.validationErrorFields.indexOf(fieldName) 
                if(index > -1)
                    page.validationErrorFields.splice(index, 1)
            }
        },
        //Components
        addComponent(page, action){
            let newComponent = JSON.parse(JSON.stringify(action.payload.newComponent));

            let { section } = action.payload.section ?  { section: action.payload.section } : findComponentBySection(page, 'internId', page.selectedComponentId);

            const setNewId = (component, parentId) => {

                component.internParentId = parentId,
                component.internId = page.nextId++;
                component.id = undefined;
                component.uuid = undefined;

                component.children?.forEach((child) => setNewId(child, component.internId));
            }

            newComponent.internId = page.nextId++;
            newComponent.id = undefined;
            newComponent.uuid = undefined;
            newComponent.internParentId = page.selectedComponentParentId;

            newComponent.children?.forEach((child) => setNewId(child, newComponent.internId));

            let parentComponents = page[section];
            if(page.selectedComponentParentId !== null)
                parentComponents = findComponentByKey(page[section], 'internId', page.selectedComponentParentId).children;
            
            let index = parentComponents.length;
            if(page.selectedComponentId && page.selectedComponentId !== page.selectedComponentParentId)
                index = parentComponents.findIndex((comp) => comp.internId === page.selectedComponentId) + 1;

            parentComponents.splice(index, 0, newComponent);
            page.selectedComponentId = newComponent.internId;
        },
        selectComponent(page, action){
            page.selectedComponentId = action.payload.id;
            page.selectedComponentParentId = action.payload.parentId;
        },
        deselectComponent(page, action){
            page.selectedComponentId = null;
            page.selectedComponentParentId = null;
        },
        updateComponent(page, action){
            let { section } = findComponentBySection(page, 'internId', page.selectedComponentId);
            let parentComponents = page[section];
            if(page.selectedComponentParentId !== null){
                parentComponents = findComponentByKey(page[section], 'internId', page.selectedComponentParentId).children;
            }
            let  componIndex = parentComponents.findIndex((comp) => comp.internId === page.selectedComponentId);
            
            parentComponents[componIndex] = page.newComponent;
        },
        deleteComponent(page, action){
            const setDeleted = (component) => {
                component.deleted = 1,
                component.children?.forEach((child) => setDeleted(child));
            }
            let { section } = findComponentBySection(page, 'internId', page.selectedComponentId);

            let parentComponents = page[section];
            if(page.selectedComponentParentId !== null)
                parentComponents = findComponentByKey(page[section], 'internId', page.selectedComponentParentId).children;

            let index = parentComponents.length;
            if(page.selectedComponentId !== null)
                index = parentComponents.findIndex((comp) => comp.internId === page.selectedComponentId);

            if (parentComponents[index].id === undefined)
                parentComponents.splice(index, 1);
            else{ 
                let deletedComponent = JSON.parse(JSON.stringify(parentComponents[index]))
                deletedComponent.deleted = 1;
                deletedComponent.children?.forEach((child) => setDeleted(child));
                parentComponents[index] = deletedComponent;
            }
        },
        moveComponent(page, action){
            let sourceId = action.payload.sourceId;
            let targetId = action.payload.targetId;

            if(sourceId === targetId) return;
            
            let sourceBySection = findComponentBySection(page, 'internId', sourceId);
            let section = sourceBySection.section;
            let source = sourceBySection.component;
            let target = findComponentBySection(page, 'internId', targetId).component;
    
            if(source.internParentId === target.internParentId){

                let parentComponents = page[section];
                if(source.internParentId !== null)
                    parentComponents = findComponentByKey(page[section], 'internId', source.internParentId).children;
    
                let oldIndex = parentComponents.findIndex((component) => component.internId === sourceId);
                if (oldIndex > -1) parentComponents.splice(oldIndex, 1);
    
                let newIndex = parentComponents.findIndex((component) => component.internId === targetId);
                if (newIndex > -1) parentComponents.splice(newIndex, 0, source);

            }
        },
        //Modal
        openModal(page, action){
            let modal = action.payload.modal;
            page.openModal = modal;

            //If a new property was added, it is added automatically when editing
            if(modal === 'editComponent' || modal === 'addComponent'){
                let newComponent = JSON.parse(JSON.stringify(action.payload.component));

                let componentProps = setComponentObject(newComponent.component, page.cmsproperties);

                for(let prop in componentProps){
                    if(newComponent[prop] === undefined)
                       newComponent[prop] = componentProps[prop];
                }
                page.newComponent =  newComponent;
            }


            if(modal === 'selectComponent'){
                let { component } = findComponentBySection(page, 'internId', page.selectedComponentParentId);
                page.toAddComponent =  page.availableComponents[component?.component] ? page.availableComponents[component?.component][0] : page.availableComponents.default[0]; 
            }
        },
        closeModal(page, action){
            page.openModal = null;
            if(page.newComponent) page.newComponent = null;
            if(page.toAddComponent) page.toAddComponent = null;
            if(page.editComponentActiveLang !== page.activeLang) page.editComponentActiveLang = page.activeLang;
            if(page.selectedComponentParentId && page.selectedComponentId === page.selectedComponentParentId) {
                let { component } = findComponentBySection(page, 'internId', page.selectedComponentId)
                page.selectedComponentParentId = component.internParentId
            }
            if(page.changedNewCompFields.length > 0) page.changedNewCompFields = []; //TO-DO!
        }
    },
    extraReducers: {
		[getPageInBackend.rejected]: (page, action) => {
			console.log(action.error.message);
		},
		[getPageInBackend.fulfilled]: (page, action) => {
            if(action.payload.status && action.payload.status === "error"){
                console.log(action.payload.data.message);
            }

            let data = action.payload.data;
            // console.log("data", data);

            page.id = data.id;
            page.uuid = data.uuid;
            page.parentId = data.parentId;
            page.name = data.name;
            page.slug =  data.slug?.split("/").pop();
            page.roleId = data.roleId;
            page.noIndex = data.noIndex;
            page.published = data.version;
            page.hideHeader = Object.values(data.hideHeader)[0];
            page.hideFooter = Object.values(data.hideFooter)[0]; 
            page.backgroundColor = Object.values(data.backgroundColor)[0]; 

            page.changedFields = [];

            if(data.authProvider)
                page.authProvider =  Object.values(data.authProvider)[0]; 

            let pageSettigsByLang = ["title", "metaTitle", "metaDescription", "metaKeywords", "matNumber"];

            for(let pageSettig of pageSettigsByLang){
                if(data[pageSettig]){
                    for(let lang of page.cmsproperties?.languages){
                        if(!data[pageSettig][lang]) 
                            data[pageSettig][lang] = '';
                    }
                    page[pageSettig] = data[pageSettig];
                }
            }

            const setNewComponent = (component, parentId) => {
                //TO-DO:
                let propsByLang = Object.keys(component)
                                    .filter((prop) => ![
                                                        'id', 'uuid', 'pageId', 'parentId', 'component', 'position', 'children', 
                                                        'created', 'createdBy', 'updatedBy', 'componentReferenceId', 'updated', 'formData'].includes(prop))

                let propsWithLang = page.cmsproperties.properties[component.component]
                                        .filter((prop) => prop.value.includes('_stringByLang_'))
                                        .map((prop) => prop.option);
                
                if(!component.children){
                   let hasChildren = page.cmsproperties.properties[component.component].find(prop => prop.option === 'children');
                   if(hasChildren)
                    component.children = [];

                }

                for(let prop of propsByLang){
                    if(propsWithLang.includes(prop)){
                        for(let lang of page.cmsproperties?.languages)
                            if(!component[prop][lang]) 
                                component[prop][lang] = '';
                    }
                    else {
                       if(component[prop]){
                        let values = Object.values(component[prop])
                        component[prop] = values?.length >= 1 ? values[0] : component[prop];
                       }
                    }
                }
                
                component.internParentId = parentId,
                component.internId = page.nextId++;

                component.children?.forEach((child) => setNewComponent(child, component.internId));
            }
            for(let section of sections){
                if(data[section]?.length >= 1){
                    data[section].forEach((component) => setNewComponent(component, null));
                    page[section] = data[section];
                }else{
                    if(section === 'cmsPageHeader' || section === 'cmsPageFooter'){
                        let component = setComponentObject(section, page.cmsproperties);
                        component.internId = page.nextId++;
                        component.internParentId = null;
                        component.pageId = data.id;
                        page[section].push(component);
                    } 
                }
            }
            page.loading = false;
		},
    }});

export const {
    setInitialData,
    setAvailableLang,
    setValue,
    setNewCompValue,
    addComponent,
    editComponent,
    updateComponent,
    deleteComponent,
    moveComponent,
    selectComponent,
    deselectComponent,
    setValidate,
    updateValidationErrorFields,
    openModal,
    closeModal,
  } = pageSlice.actions;


export default pageSlice.reducer;


//Selectors
export const getPage = page => {
    return page;
}

export const getActiveLang = page => {
    return page.activeLang;
}

export const getEditComponentActiveLang = page => {
    return page.editComponentActiveLang;
}

export const getAvailableLangs = page => {
    return page.availableLangs;

}

export const getNewComponent = page => {
    return page.newComponent;
}

export const getSelectedComponentId = page => {
    return page.selectedComponentId;
}

export const getCmsproperites = page => {
    return page.cmsproperties;
}

export const getForms = page => {
    return page.forms;
}

export const getPDB = page => {
    return page.pdb;
}