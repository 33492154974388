import React from "react";
export default function SucIcon(props) {
	let classes = "material-icons mi-" + props.icon;
	classes = props.classes ? classes + " " + props.classes : classes;
	return (
		<i
			onMouseEnter={props.onMouseEnter}
			onMouseOver={props.onMouseOver}
			className={classes}
			onClick={props.onClick}
			onMouseDown={props.onMouseDown}
			aria-hidden={props.ariaHidden ? props.ariaHidden : false}
			style={props.style}
		>
			{props.icon}
		</i>
	);
}
