import React from "react";
import {TranslateText} from "../../../../libs/LanguageProvider";

export default function Productlist(props) {
    return (
        <div>
            {
                props.products.length > 0 && props.products.map((product, index) => {

                    if (product.quantity <= 0) {
                        return null;
                    }
                    return (
                        <div className="even:bg-sanofi-table-body">
                            {index === 0 &&
                                <h3 className={"text-2xl color-sanofi-dark-lila font-bold pl-4 pt-4 bg-sanofi-white"}>{props.offertitle || ""}</h3>
                            }
                            <div className="flex justify-between items-center p-4">
                                <div className={""}>
                                    <img src={product.picture} className={"max-w-5 max-h-5"}
                                         alt={product.title}/>
                                </div>
                                <div className={"p-1"}>
                                    {product.title}<br/>
                                    <small>{product.pharmaNumber}</small>
                                </div>
                                <div className={"p-1 w-30 text-right"}>
                                    {parseInt(product.quantity)} {TranslateText("offer-cart-label-pieces")}
                                    <br/>

                                    {(product.priceReduction !== undefined && product.type !== "1") &&
                                        <span className={"font-bold line-through"}>

                                        {product.price * product.quantity > 0 &&
                                            <>{(parseFloat(product.price) * parseInt(product.quantity)).toFixed(2)} {TranslateText("offer-cart-label-currency")}</>
                                        }

                                            {product.price * product.quantity <= 0 &&
                                                <>{(0).toFixed(2)} {TranslateText("offer-cart-label-currency")}</>
                                            }

                                    </span>
                                    }

                                    {product.priceReduction === undefined &&
                                        <span className={"font-bold"}>
                                        {(parseFloat(product.price) * parseInt(product.quantity)).toFixed(2)} {TranslateText("offer-cart-label-currency")}
                                    </span>
                                    }
                                </div>
                            </div>
                        </div>)
                })
            }
        </div>
    )
}