import React, {Fragment, useEffect, useState} from "react";
import {getLanguageContent, TranslateHtml, TranslateText} from "../../libs/LanguageProvider";
import Spinner from "../Spinner";
import InfoTile from "../InfoTile";
import Schedule from "./component/Schedule";
import ELearningCard from "./component/eLearningCard";
import SanofiButtonDark from "../SanofiButtonDark";
import axios from "axios";
import {getUserLanguages} from "../../helper/User";
import FormBundle from "../FormDesigner";
import store from "../FormDesigner/store/FormStore";
import {Provider} from "react-redux";
import ProgressBar from "./component/ProgressBar";
import "./style/eLearning.css"
import SanofiButton from "../SanofiButton";
import {useInterval} from "../../helper/Utils";


// PATH => /university/elearnings/{slug}
export default function ELearningComponent(props) {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [eLearningData, setELearningData] = useState({})
    const [videoIsRunning, setVideoIsRunning] = useState(true)
    const [progress, setProgress] = useState(0)
    const [completeDescription, setCompleteDescription] = useState(false)
    const [hasVideoEnded, setHasVideoEnded] = useState(false)

    const [hasGLN, setHasGLN] = useState(false)
    const [withoutGLN, setWithoutGLN] = useState(false)

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getELearning/elearning",
            data: {
                lang: getUserLanguages(),
                uuid: props.slug // uuid
            }
        }).then(data => {
            if (data.data && data.data.status === 'success') {
                setELearningData(data.data.data);
                if (data.data.gln) {
                    setHasGLN(true)
                }
                setHasLoaded(true)
            } else {
                window.location.href = '/404';
            }
        });
    }, [])

    // Make a post request every 5 minutes to the following route to keep the session alive
    const keepSessionAlive = () => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/keepSessionAlive/elearning",
            data: {}
        });
    }

    useInterval(() => {
        keepSessionAlive();
    }, 5 * 60 * 1000);

    const getDuration = (durationAsString) => {
        const [hours, minutes, seconds] = durationAsString.split(':');
        let output = '';
        if (Number(hours) > 0) {
            if (Number(hours) > 1) {
                output += Number(hours) + ' ' + getLanguageContent('hours', 'Stunden');
            } else {
                output += Number(hours) + ' ' + getLanguageContent('hour', 'Stunde');
            }
        }
        if (Number(minutes) > 0) {
            if (Number(hours) > 0) {
                output += ' ' + getLanguageContent('and', 'und') + ' ';
            }
            if (Number(minutes) > 1) {
                output += Number(minutes) + ' ' + getLanguageContent('minutes', 'Minuten');
            } else {
                output += Number(minutes) + ' ' + getLanguageContent('minute', 'Minute');
            }
        }

        return output;
    }

    const finishedVideo = () => {
        setVideoIsRunning(!videoIsRunning)
        setProgress(20)
    }

    const onVideoEnded = () => {
        setHasVideoEnded(true);
    }

    /**
     * Calculate the progress for the Progress bar
     * @param questioncount number of question in the form
     * @param actualquestionnum number of the actual question
     */
    const updateProgressBarForm = (questioncount, actualquestionnum) => {
        //Dreisatz :P
        if (actualquestionnum === 1) { // first question
            setProgress(20)
        } else if (actualquestionnum <= questioncount) {
            const newProgress = 20 + ((Math.floor(80 / questioncount)) * (actualquestionnum - 1))
            setProgress(newProgress)
        } else {
            setProgress(100)
        }
    }


    if (!hasLoaded) {
        return <Spinner/>;
    }

    const chooseWithoutGLN = () => {
        setHasGLN(false)
        setWithoutGLN(true)
    }

    return (
        <Fragment>
            {!hasGLN && !withoutGLN && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center flex-col z-50">
                    <div className="bg-sanofi-white p-5 rounded-2xl max-w-[500px]">
                        <h2 className={"text-4xl color-sanofi-dark-lila font-bold text-center"}>{getLanguageContent('eLearning-popup-gln-title', 'GLN fehlt')}</h2>

                        <div className={"mt-8 text-center w-128"}>

                            <div className={"mt-4 mb-4 block"}>
                                <TranslateHtml name='eLearning-popup-gln-content'/>
                            </div>

                            <div className={"mt-4 mt-8 block"}>
                                <SanofiButton onClick={(e) => {
                                    window.location.href = '/profile';
                                }}>
                                    {getLanguageContent('eLearning-popup-button-setGLN', 'GLN nachtragen')}
                                </SanofiButton>
                            </div>

                            <div className={"mt-4 mb-4 block"}>
                            <span className={"cursor-pointer underline text-sanofi-font-default"}
                                  onClick={() => chooseWithoutGLN()}>
                                {getLanguageContent('eLearning-popup-button-withoutGLN', 'ohne GLN fortfahren')}
                            </span>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            <div className="md:grid md:grid-cols-4">
                <div className="md:col-span-3 md:pr-5">
                    <h2 className="text-xl uppercase md:font-600 mb-5">{getLanguageContent('sub-sub-title-eLearning', 'Zertifizierte Fortbildung')}</h2>
                    <h1 className="text-3xl color-sanofi-dark-lila font-bold mb-5">{eLearningData.title}</h1>

                    <div
                        className='text-lg mb-3'>{getLanguageContent('duration', 'Dauer')}: {getDuration(eLearningData.duration)}
                    </div>

                    {completeDescription ?
                        eLearningData.description &&
                        <div className="md:hidden" dangerouslySetInnerHTML={{__html: eLearningData.description}}></div>
                        :
                        eLearningData.description && <div className="container-description md:hidden">
                            <div className="ellipsis" dangerouslySetInnerHTML={{__html: eLearningData.description}}></div>
                            <button className="underline underline-offset-1"
                                    onClick={() => setCompleteDescription(true)}>{getLanguageContent('text-more', 'Mehr')}</button>
                        </div>
                    }

                    {eLearningData.description && <div className="hidden md:block"
                                                       dangerouslySetInnerHTML={{__html: eLearningData.description}}></div>}

                </div>

                {/* <div className='flex md:flex-col justify-start gap-x-5 md:bg-sanofi-gray rounded-md mt-5 md:mt-0'> */}
                <div
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-x-5 gap-y-5 md:gap-y-0 md:bg-sanofi-gray rounded-md mt-5 md:mt-0">
                    {eLearningData.speaker && <InfoTile className="" rowCount="4" iconType='referent'
                                                        title={getLanguageContent('text-referent', 'Referent')}
                                                        speaker={eLearningData.speaker}/>}

                    {/* do not show infotile, when no gln is given */}
                    {eLearningData.accreditation && hasGLN && <InfoTile className="" rowCount="4"
                                                                        iconType='certificate'
                                                                        title={getLanguageContent('text-accreditation', 'Akkreditierung')}
                                                                        accreditation={eLearningData.accreditation}/>}

                    {eLearningData.accreditation && !hasGLN && withoutGLN &&
                        <InfoTile className="opacity-50" rowCount="4"
                                  iconType='certificate'
                                  title={getLanguageContent('text-accreditation', 'Akkreditierung')}
                                  accreditation={eLearningData.accreditation}/>}


                </div>

            </div>

            <div className="my-5">
                <Schedule/>
            </div>

            <div>
                <ProgressBar progress={progress}/>
            </div>

            <ELearningCard title={getLanguageContent('eLearning-procedure-see-webinar', 'Webinar anschauen')}
                           counter="1"
                           open={videoIsRunning}
                           disabled={!videoIsRunning}
                           svg={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="currentColor"
                                     className="w-6 h-6 color-sanofi-dark-lila mt-1">
                               <path stroke-linecap="round"
                                     d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"/>
                           </svg>}>

                <div>
                    <video
                        id="my-player"
                        className="video-js"
                        controls
                        preload="auto"
                        data-setup='{}'
                        onEnded={onVideoEnded}>
                        <source
                            src={eLearningData.video}
                            type="video/mp4"/>
                        <source
                            src={eLearningData.video}
                            type="video/x-msvideo"/>
                        <p className="vjs-no-js">
                            To view this video please enable JavaScript, and consider upgrading to a
                            web browser that
                            <a href="http://videojs.com/html5-video-support/" target="_blank">
                                supports HTML5 video
                            </a>
                        </p>
                    </video>
                </div>

                {hasVideoEnded && <div>
                    <div className="md:text-right text-center mt-8">
                        <SanofiButtonDark type="button" onClick={finishedVideo}>
                            {getLanguageContent('text-eLearning-open-questionare', 'Zum Fragebogen')}
                        </SanofiButtonDark>
                    </div>
                </div>
                }
            </ELearningCard>

            <div className="mt-5 ecard-outer">
                <ELearningCard title={getLanguageContent('text-see-questionare', 'Fragebogen beantworten')}
                               counter="2"
                               open={!videoIsRunning}
                               disabled={videoIsRunning}
                               svg={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5" stroke="currentColor"
                                         className="w-6 h-6 color-sanofi-dark-lila mt-1">
                                   <path stroke-linecap="round" stroke-linejoin="round"
                                         d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
                               </svg>
                               }>
                    <div>
                        {eLearningData.formData.status === 'success' &&
                            <div>
                                <Provider store={store}>
                                    <FormBundle formdata={eLearningData.formData.data} type='quiz'
                                                parentData={eLearningData}
                                                updateProgress={updateProgressBarForm}
                                                withoutGLN={withoutGLN}
                                    />
                                </Provider>
                            </div>
                        }
                    </div>
                </ELearningCard>
            </div>

            {eLearningData.matnumber && <div className="mt-10 text-sm">{eLearningData.matnumber}</div>}
        </Fragment>
    )
}

