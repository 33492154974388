"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BaseNode", {
  enumerable: true,
  get: function get() {
    return _BaseNode.default;
  }
});
Object.defineProperty(exports, "now", {
  enumerable: true,
  get: function get() {
    return _d3Timer.now;
  }
});
Object.defineProperty(exports, "timer", {
  enumerable: true,
  get: function get() {
    return _d3Timer.timer;
  }
});
Object.defineProperty(exports, "interval", {
  enumerable: true,
  get: function get() {
    return _d3Timer.interval;
  }
});
Object.defineProperty(exports, "timeout", {
  enumerable: true,
  get: function get() {
    return _d3Timer.timeout;
  }
});

var _BaseNode = _interopRequireDefault(require("./BaseNode"));

var _d3Timer = require("d3-timer");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }