import React, {useEffect, useState, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";

import FieldElement from "./components/fieldElement";
import axios from "axios";
import SanofiButton from "../SanofiButton";
import {updateValue, setFields, setConditionals, initFormConditionals} from "./store/slices/formSlice";
import {getUserLanguages} from "../../helper/User";
import {getLanguageAsString} from "../../helper/Language";

import Spinner from "../Spinner";
import {getLanguageContent, TranslateText} from "../../libs/LanguageProvider";

import FormQuiz from "./components/FormQuiz";
import Survey from "./components/Survey";
import Title from "../Form/Title";
import SanofiButtonDark from "../SanofiButtonDark";


// Create Form by given bundle
export default function FormBundle(props) {

    let formRef = React.useRef()

    const [hasLoaded, setHasLoaded] = useState(false)
    //const [formelements, setFormelements] = useState({})
    const [submitButtonLabel, setSubmitButtonLabel] = useState('Submit')
    const [formuuid, setFormuuid] = useState('-1')
    const [returnURL, setReturnURL] = useState('/')
    const [errorReturnURL, setErrorReturnURL] = useState('/')
    const [formtype, setFormType] = useState(null)
    const [lang, setLang] = useState(getUserLanguages());
    const [nolang, setNolang] = useState(false)

    const dispatch = useDispatch();
    const data = useSelector((state) => state.formhandling.formValues);
    const fields = useSelector((state) => state.formhandling.formFields);

    const setValue = (elem) => {

        if ('type' in elem && 'uuid' in elem) {
            // Select - set first option as default
            if (elem.type === 4 && Array.isArray(elem.options)) {
                if ('uuid' in elem.options[0]) {
                    dispatch(updateValue({id: elem.uuid, value: elem.options[0].value}))
                }
            }
        }
    }

    /*Type of the formular - survey, form etc*/
    const setType = (type) => {
        switch (type) {
            case "0": // Form
                setFormType('form')
                break;
            case 1: // Survey
                setFormType('survey')
                break;
            case 2: // questionnaire
                setFormType('quiz')
                break;
            case 3: // questionnaire
                setFormType('webinar')
                break;
        }
    }

    const setData = (formdata) => {
        setFormuuid(formdata.uuid)

        if (formdata.fields) {
            const fields = formdata.fields;
            //setFormelements(fields);
            // const formelements = data.data.data.fields
            if ('returnurl' in formdata) {
                setReturnURL(formdata.returnurl)
            }

            if ('returnurl_error' in formdata) {
                setErrorReturnURL(formdata.returnurl_error)
            }

            if ('conditional_fields' in formdata) {
                dispatch(setConditionals(formdata['conditional_fields']))
            }

            // set fields
            dispatch(setFields(fields))


            fields.forEach((fElem) => { // nur default values einlesen
                if ('defaultValue' in fElem) {
                    let value = fElem.defaultValue
                    if (fElem.type === 5) {
                        value = fElem.defaultValue === "" ? true : false
                    }
                    dispatch(updateValue({id: fElem.uuid, value: value}))
                } else {
                    // if('type' in fElem){
                    setValue(fElem)
                    // }
                }
                if (fElem.type === 10 && 'label' in fElem) {
                    setSubmitButtonLabel(fElem.label)
                }
            })
            // setFormelements(formelements);
            setHasLoaded(true);
        }
    }

    useEffect(() => {
        // slug = get data from database with slug as uuid
        if (props.slug) {
            axios({
                dataType: "json",
                method: "post",
                url: "/module/getFormInFrontend/formdesigner",
                data: {
                    lang: lang,
                    uuid: props.slug // id oder uuid
                }
            }).then(data => {

                if (data.data && data.data.status === 'success') {
                    setData(data.data.data)
                    setType(data.data.data.type)
                    setLang(data.data.data.lang)

                } else if (data.data && data.data.status === 'error' && data.data.code === 'nolang') {
                    setNolang(true);
                    setHasLoaded(true);
                } else {
                    // window.location.href = '/404';
                }

                dispatch(initFormConditionals({}))
            });
        } else if (props.formdata) { // formdata = get data handed over
            setData(props.formdata)
            setType(props.formdata.type)
            setLang(props.formdata.lang)

            dispatch(initFormConditionals({}))
        } else {
            // window.location.href = '/404';
        }

    }, [])

    const getDuration = (from, to) => {
        const timeFromArray = from.split(':');
        const timeToArray = to.split(':');
        const timeFrom = new Date(null, null, null, timeFromArray[0], timeFromArray[1], timeFromArray[2]);
        const timeTo = new Date(null, null, null, timeToArray[0], timeToArray[1], timeToArray[2]);
        const diff = timeTo.getTime() - timeFrom.getTime();
        const diffDate = new Date(diff);
        diffDate.setTime(diffDate.getTime() + diffDate.getTimezoneOffset() * 60 * 1000);
        const hours = diffDate.getHours();
        const minutes = diffDate.getMinutes();
        let output = '';
        if (Number(hours) > 0) {
            if (Number(hours) > 1) {
                output += Number(hours) + ' ' + getLanguageContent('hours', 'Stunden');
            } else {
                output += Number(hours) + ' ' + getLanguageContent('hour', 'Stunde');
            }
        }
        if (Number(minutes) > 0) {
            if (Number(hours) > 0) {
                output += ' ' + getLanguageContent('and', 'und') + ' ';
            }
            if (Number(minutes) > 1) {
                output += Number(minutes) + ' ' + getLanguageContent('minutes', 'Minuten');
            } else {
                output += Number(minutes) + ' ' + getLanguageContent('minute', 'Minute');
            }
        }

        return output;
    }

    const formatTime = (timeAsString) => {
        const [hours, minutes, seconds] = timeAsString.split(':');
        return hours + ':' + minutes;
    }

    const populateShareContent = (_content) => {
        if (_content) {
            const acc_points = props.parentData.accreditation?.map(obj => obj.accreditation_points).join(', ')
            const content = _content
                .replace('-webinar-title-', props.parentData.title ?? 'error')
                .replace('-webinar-date-', props.parentData.time_date ?? 'error')
                .replace('-webinar-time-', props.parentData.time_from ? formatTime(props.parentData.time_from) : 'error')
                .replace('-webinar-duration-', props.parentData.time_from && props.parentData.time_to ? getDuration(props.parentData.time_from, props.parentData.time_to) : 'error')
                .replace('-webinar-accreditation-', acc_points ?? 'error')
                .replace('-webinar-link-', window.location.href ?? 'error')
            return content;
        }
        return '';
    }

    /**
     * Retrieves the field value from the stored form data based on the given fielduuid
     * An optional empty value might be added as a parameter (default -> empty string)
     * @param fielduuid
     * @param emptyOption
     * @returns {string|*}
     */
    const getFieldValue = (fielduuid, emptyOption = '') => {
        if (fielduuid in data) { // uuid in list => give according
            if (Array.isArray(data[fielduuid])) { // is Array => whole array (is a list of values) -> for Multiselects
                return formValues[fielduuid]
            } else { // is Object
                if ('value' in data[fielduuid]) {
                    return data[fielduuid].value
                }
            }
        }
        return emptyOption
    }

    const getFelemByUUID = (uuid) => {
        return fields.find(field => field['uuid'] === uuid)
    }

    const reformatDataForSubmit = (fData) => {
        let resultlist = []

        Object.keys(fData).forEach(fieldUuid => {
            const field = getFelemByUUID(fieldUuid)
            if (!field.hidden) { // only add to submit when the field is not hidden
                if (Array.isArray(fData[fieldUuid])) {
                    resultlist.push({'uuid': fieldUuid, 'value': fData[fieldUuid]})
                } else if (typeof fData[fieldUuid] === 'object' &&
                    !Array.isArray(fData[fieldUuid]) &&
                    fData[fieldUuid] !== null) { // is RatingMultiple
                    let elem = {'uuid': fieldUuid, 'value': []}
                    Object.keys(fData[fieldUuid]).forEach(elemUuid => {
                        elem.value.push({'uuid': elemUuid, 'value': fData[fieldUuid][elemUuid]})
                    })
                    resultlist.push(elem)
                } else {
                    if ('value' in fData[fieldUuid]) {
                        resultlist.push({'uuid': fieldUuid, 'value': fData[fieldUuid].value})
                    }
                }
            }

        })
        return resultlist;
    }

    const getType = () => {
        if (props.parentData.therapyarea) {
            return 'producttraining';
        }
        if (props.parentData.time_date) {
            return 'webinar';
        }
        if (props.parentData.components) {
            return 'cmspage';
        }
        return 'elearning';
    }

    /**
     * Submit completed formular
     * @returns {boolean}
     */
    const onFormSubmit = () => {

        if (!formRef.current) {
            return false;
        }

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return false;
        }

        if (props.embedded) {
            const form = document.createElement('form');
            form.action = props.siteURL + 'module/submitForm/formdesigner';
            form.method = 'POST';

            const uuidInput = document.createElement('input');
            uuidInput.type = 'hidden';
            uuidInput.name = 'uuid';
            uuidInput.value = formuuid;
            form.appendChild(uuidInput);

            const langInput = document.createElement('input');
            langInput.type = 'hidden';
            langInput.name = 'lang';
            langInput.value = lang;
            form.appendChild(langInput);

            const fieldsInput = document.createElement('input');
            fieldsInput.type = 'hidden';
            fieldsInput.name = 'fields';
            fieldsInput.value = encodeURI(JSON.stringify(reformatDataForSubmit(data)));
            form.appendChild(fieldsInput);

            const embeddedInput = document.createElement('input');
            embeddedInput.type = 'hidden';
            embeddedInput.name = 'embedded';
            embeddedInput.value = '1';
            form.appendChild(embeddedInput);

            document.body.appendChild(form);
            form.submit();
            return;
        }

        axios({
            dataType: "json",
            method: "post",
            url: "/module/submitForm/formdesigner",
            data: {
                uuid: formuuid,
                lang: lang,
                parentUUID: props.parentData.uuid ?? null,
                fields: reformatDataForSubmit(data)
            }
        }).then(data => {
            const return_data = {
                uuid: props.parentData.uuid,
                title: props.parentData.title,
                type: getType(),
                lang: getLanguageAsString(props.parentData.lang ?? null)
            }
            if (data.data.status === 'success') {
                window.localStorage.setItem('return_data', JSON.stringify(return_data));
                window.location.href = returnURL;
            } else {
                if (data.data.message) {
                    alert(data.data.message)
                } else {
                    alert('Error while submitting the form - please try again')
                }
            }
        });
        //window.location.href = '/thankyou';
    }


    if (!hasLoaded) {
        if (props.embedded) {
            return null;
        }
        return <Spinner/>;
    }
    if (nolang) return '';

    return (
        <Fragment>
            {(!formtype || formtype === 'form' || formtype === 'webinar') &&
                <form ref={formRef}>
                    <div className='flex flex-wrap'>
                        {
                            fields && fields.length > 0 && fields.map(fElem => {
                                if (fElem.type !== 10) { // submit button only for label
                                    return <FieldElement fElem={fElem}/>
                                }
                            })
                        }
                    </div>
                    <div className="md:text-right text-center mt-8 flex justify-center md:justify-end">
                        {props.share &&
                            <div className=" inline-block align-middle mr-6 mt-3">
                                <a className="flex"
                                   href={"mailto:" + "" + "?subject=" +
                                       TranslateText("formdesigner-share-subject") +
                                       "&body=" +
                                       populateShareContent(TranslateText("formdesigner-share-content"))}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5"
                                         stroke="currentColor" className="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"/>
                                    </svg>
                                    <span className="ml-2"> {getLanguageContent('form-share', 'Teilen')} </span>
                                </a>
                            </div>
                        }
                        <SanofiButtonDark disabled={props.submitDisabled} type="button" onClick={(e) => onFormSubmit()}>
                            {submitButtonLabel}
                        </SanofiButtonDark>
                    </div>
                </form>}
            {formtype === 'survey' &&
                <Survey submitDisabled={props.submitDisabled} submitButtonLabel={submitButtonLabel}
                        returnURL={returnURL}
                        fields={fields}
                        formuuid={formuuid} parentData={props.parentData || null}
                        lang={lang} reformatDataForSubmit={reformatDataForSubmit}
                        updateProgress={props.updateProgress}/>
            }
            {formtype === 'quiz' &&
                <FormQuiz submitDisabled={props.submitDisabled} submitButtonLabel={submitButtonLabel}
                          returnURL={returnURL} errorReturnURL={errorReturnURL}
                          fields={fields}
                          formuuid={formuuid} parentData={props.parentData || null}
                          lang={lang} reformatDataForSubmit={reformatDataForSubmit}
                          updateProgress={props.updateProgress}
                          withoutGLN={props.withoutGLN}
                />
            }
        </Fragment>

    )
}

