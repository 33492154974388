/*
   Calculate the difference between the starting date and the current Date
    */
import {TranslateText} from "../../../libs/LanguageProvider";

export const calculateTimeLeft = (timeOne, timeTwo) => {
    
    let difference = timeOne - timeTwo;

    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }
    return timeLeft;
}

export const renderCountDown = (timer) => {

    let string = "";

    if (timer.days !== undefined && timer.days > 0) {
        string += " " + timer.days + (timer.days > 1 ? " " + TranslateText("offer-box-timer-days") : " " + TranslateText("offer-box-timer-day"));
    }

    if (timer.hours !== undefined && timer.hours > 0) {
        string += " " + timer.hours + (timer.hours > 1 ? " " + TranslateText("offer-box-timer-hours") : " " + TranslateText("offer-box-timer-hour"));
    }

    if (timer.minutes !== undefined && timer.minutes > 0) {
        string += " " + timer.minutes + (timer.minutes > 1 ? " " + TranslateText("offer-box-timer-minutes") : " " + TranslateText("offer-box-timer-minute"));
    }

    if (string.length <= 5) {
        if (timer.seconds !== undefined && timer.seconds > 0) {
            string += " " + timer.seconds + (timer.seconds > 1 ? " " + TranslateText("offer-box-timer-seconds") : " " + TranslateText("offer-box-timer-second"));
        }
    }

    return string;

}