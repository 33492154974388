import React, {useEffect, useState, Fragment, useCallback} from "react";
import {Combobox, Transition} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'
import {TranslateText} from "../../../libs/LanguageProvider";
import axios from "axios";

const defaultEntity = [
    {id: null, name: ''},
]

export default function Typeahead(props) {

    const [results, setResults] = useState(props.selected || defaultEntity)
    const [selected, setSelected] = useState(results[0])
    const [displayValue, setDisplayValue] = useState(props.displayValue || "name")
    const [filter, setFilter] = useState(props.filter || "name")
    const [query, setQuery] = useState('')

    useEffect(() => {

        if (query.length > 0) {
            const filtered =
                query === ''
                    ? results
                    : results.filter((result) =>
                        result[filter]
                            .toLowerCase()
                            .replace(/\s+/g, '')
                            .includes(query.toLowerCase().replace(/\s+/g, ''))
                    )

            if (filtered.length <= 0) {

                axios({
                    dataType: "json",
                    method: "post",
                    url: "/module/frontend/searchLocationCode",
                    data: {
                        query: query
                    }
                }).then(data => {

                    if (data.data.status && data.data.status === "error") {
                        alert(data.data.message);

                        return;
                    }

                    if (data.data.data) {
                        setResults(data.data.data);
                    }

                });

        }
        }
        
        
    }, [query])

    const onSelect = (selectedElement) => {
        if (props.onSelect) {
            props.onSelect(selectedElement)
        }
        setSelected(selectedElement);
    }


    const filtered =
        query === ''
            ? results
            : results.filter((result) =>
                result[filter]
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )


    return (
        <Combobox value={selected} onChange={onSelect}>
            <div className="relative mt-1 z-20" >
                <div
                    className="relative w-full cursor-default overflow-hidden bg-white text-left text-sm text-gray-900 bg-gray-50 font-sans rounded border-gray-300 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila">
                    <Combobox.Input
                        className="w-full border-none p-2.5 text-sm text-gray-900 text-base focus:ring-0"
                        displayValue={(result) => result[displayValue]}
                        onChange={(event) => setQuery(event.target.value)}
                        required={true}
                        name={"locationCode"}
                        autoComplete="off"
                    />
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options
                        className="bg-white absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filtered.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                {TranslateText("general-typeahead-nothing-found")}
                            </div>
                        ) : (
                            filtered.map((result) => (
                                <Combobox.Option
                                    key={result.id}
                                    className={({active}) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-sanofi-dark-lila text-white' : 'text-gray-900'
                                        }`
                                    }
                                    value={result}
                                >
                                    {({selected, active}) => (
                                       <>
                                        <span
                                            className={`block truncate ${
                                                selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {result[displayValue]}
                                        </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                        active ? 'text-white' : 'text-teal-600'
                                                    }`}
                                                >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                              </span>
                                            ) : null}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )

}
