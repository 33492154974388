

import React, {useEffect, useState, Fragment, useCallback} from "react";
import Box from "./Box";

export default function RebateContainer(props) {

    return (

        <div className="flex-none justify-center items-stretch space-x-0 flex-wrap lg:flex lg:space-x-8 lg:flex-nowrap lg:mb-6">
            {
                props.offer.rules.map((rule, index) => {
                    return(
                        <Box
                            type={rule.type == "0" ? "percent" : "price"}
                            value={rule.value}
                            active={props.activeRule == index}
                            description={rule.title || ""}
                        />
                    )
                })
            }
        </div>

    )


}