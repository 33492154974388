import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {getLanguageContent, TranslateText} from "../../../libs/LanguageProvider";
import axios from "axios";
import clsx from "clsx";
import {getUserLanguages} from "../../../helper/User";

/*
props:
    showElem: boolean - whether the detail row ist shoen
    updateShowDetails: method - to update the visible status for the according detail row
    rowindex: int - index of the row
    order: array - data of the order including the order items
    totalAmount: int - total count of articles that were bought in the according order
    setIsAlert: activate alert window
    setIsSuccess: activate success window
 */
export default function OrderRow(props) {
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)


    const generatePDF = () => {
        setIsGeneratingPDF(true)
        axios({
            dataType: "json",
            method: "post",
            url: "/module/generateOrderPDF/order",
            data: {
                order: JSON.stringify(props.order), // order including the order items
                languageshortcut: getUserLanguages()
            }
        }).then(data => {
            if (data.data.status === 'success') {
                downloadPDF(data)
                props.setIsSuccess()
            } else {
                props.setIsAlert()
            }
            setIsGeneratingPDF(false)
        });
    }

    const downloadPDF = (data) => {
        const a = document.createElement("a");
        a.download = data.data.data.file;
        a.href = data.data.data.content;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
    }


    return (
        <tr className={clsx('orders-table-row', (props.rowindex % 2 !== 0) && 'orders-table-general-row')}>
            <td className="px-6 py-3">
                {
                    (!props.showElem) ?
                        <button
                            onClick={() => props.updateShowDetails(props.rowindex)}>
                            <FontAwesomeIcon icon={faChevronDown}
                                             size='1x' color='#2B2B38'/>
                        </button>
                        :
                        <button
                            onClick={() => props.updateShowDetails(props.rowindex)}>
                            <FontAwesomeIcon icon={faChevronUp}
                                             size='1x' color='#2B2B38'/>
                        </button>
                }

            </td>
            <td>{props.order[0]['order_created']}</td>
            <td className="px-6 py-3">{props.order[0]['order_number']}</td>
            <td className="px-6 py-3">{props.order[0]['order_status_name']}</td>
            <td className="px-6 py-3">{props.totalAmount} {TranslateText("order-unit-stk")}</td>
            <td className="px-6 py-3">{props.order[0]['order_rebate']}%</td>
            <td className="px-6 py-3">{props.order[0]['order_sum']} CHF</td>
            <td className="px-6 py-3">
                {
                    (isGeneratingPDF) ?
                        <div className="flex justify-center items-center">
                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full"
                                 role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <button className="orders-pdf-download"
                                onClick={generatePDF}>{TranslateText("order-label-pdfDownload")}
                        </button>
                }
            </td>
        </tr>
    )
}