import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import clsx from "clsx";
import {TranslateText} from "../../../libs/LanguageProvider";


export default function OrderDetailRow(props) {
    return (
        <tr className={clsx('orders-table-row', (!props.showElem) && "btn-hidden")}>
            <td></td>
            <th className="content-center text-center p-5">
                <img className="order-table-img inline-block"
                     alt={'...'}
                     src={props.order['package_picture']}/>
            </th>
            <td colSpan={2} className="px-6 py-3">
                <b>{TranslateText("order-label-article")}:</b> <br/>
                {props.order['product_title']}
            </td>
            <td colSpan={2} className="px-6 py-3">
                <b>{TranslateText("order-label-amount")}:</b> <br/>
                {props.order['quantity']}
            </td>
            <td className="px-6 py-3"> {props.order['price']} CHF</td>
            <td></td>
        </tr>

    )

}