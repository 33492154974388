import React, {Fragment, useEffect} from "react";
import {getLanguageContent} from "../../../../libs/LanguageProvider";
import "./style/progressBar.css"

export default function ProgressBar(props) {

    // inspired by https://stackoverflow.com/questions/56118780/how-to-create-an-horizontal-line-with-dots-over-it
    return (
        <Fragment>
            <div>{getLanguageContent('text-total-progress', 'Gesamtfortschritt')} ({props.progress ? props.progress : '0'}%)</div>

            <div className="wrapper w-100">

                <div className="progress2 progress-moved h-1 rounded-full bg-sanofi-gray">
                    <div id="progressBar-eLearning"
                         className="progress-bar2 h-1 rounded-full bg-sanofi-lila "
                         style={{width: +(props.progress ? props.progress : "0") + "%"}}>
                    </div>
                    <ul className="flex justify-between w-100" style={{listStyle: 'none'}}>
                        <li className="link link-left link-video"><span className="font-bold">1</span></li>
                        <li className="link link-right link-questionnaire"><span
                            className="font-bold">2</span>
                        </li>
                    </ul>
                </div>


            </div>
        </Fragment>
    )
}

