import React, {useState} from "react";

import {
    getAdditionalReduction,
    getRuleAdditional,
    calculateCartSumsForSingleOffer,
    getRebateType,
    calculateReduction,
    calculateAdditionalReduction, getActiveRebateValue, getAdditionalQuantity, getReductionDisplayType
} from "../../../helper/OfferCalculation";

import {TranslateText} from "../../../libs/LanguageProvider";
import Productlist from "./components/productlist";


export default function LinkedOfferCart(props) {

    const [cartExpanded, setCartExpanded] = useState(false);


    /**
     * Calculate sum of both offers -> quantity and price
     * @returns {{quantity: number, price: number}}
     */
    const calculateCartSums = () => {
        const offer = calculateCartSumsForSingleOffer(props.cart)
        const linkedOffer = calculateCartSumsForSingleOffer(props.cartLinkedOffer)

        const quantity = offer.quantity + linkedOffer.quantity
        const price = offer.price + linkedOffer.price

        return {quantity: quantity, price: price};
    }

    const getCartSum = () => {
        let sum = calculateCartSums()
        return sum.price
    }

    const getCartQuantity = () => {
        let sum = calculateCartSums()
        return sum.quantity
    }

    /**
     * Get full Sum of the order
     * @returns {number}
     */
    const getFullSum = () => {
        let offerPrice = 0
        let offerPriceLinkedOffer = 0

        // ----- calculate part for offer
        if (props.cart.length > 0) { // are there selected products from this offer
            const sum = calculateCartSumsForSingleOffer(props.cart)
            let reduction = 0

            if (getRebateType(props.rules, props.activeRule) !== -1) { // a rebate is set
                reduction = calculateReduction(props.cart, props.rules, props.activeRule);
            }

            let additionalReduction = calculateAdditionalReduction(props.rules, props.activeRule)
            offerPrice = sum.price - reduction - additionalReduction;
        }


        // ---- calculate part for linked offer
        if (props.cartLinkedOffer.length > 0) {
            const sumLinkedOffer = calculateCartSumsForSingleOffer(props.cartLinkedOffer)

            let reductionLinkedOffer = 0;

            if (getRebateType(props.rulesLinkedOffer, props.activeRuleLinkedOffer) !== -1) {// a rebate is set
                reductionLinkedOffer = calculateReduction(props.cartLinkedOffer, props.rulesLinkedOffer, props.activeRuleLinkedOffer);
            }

            let additionalReductionLinkedOffer = calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer)

            offerPriceLinkedOffer = sumLinkedOffer.price - reductionLinkedOffer - additionalReductionLinkedOffer;

        }

        return offerPrice + offerPriceLinkedOffer;

    }


    return (
        <div className="fixed right-0 bottom-0 z-20 md:mr-10 w-full bg-sanofi-white rounded-t-lg md:max-w-lg">

            <div
                className="bg-sanofi-dark-lila h-16 rounded-t-lg p-5 cursor-pointer offer-cart-header offer-cart-header-text"
                onClick={(e) => {
                    setCartExpanded(!cartExpanded)
                }}>
                <div className="flex text-sanofi-white justify-between items-center">
                    <div className="animate-pulse flex justify-between items-center">
                        <div className={"pr-2"}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path
                                    d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
                            </svg>
                        </div>
                        {TranslateText("cart-title-cart-lable")}
                    </div>
                    <div className="">
                        {getCartQuantity()} {getAdditionalQuantity() > 0 ? "(+" + getAdditionalQuantity() + ")" : ""} {TranslateText("offer-cart-label-pieces")} / {getFullSum().toFixed(2)} {TranslateText("offer-cart-label-currency")}
                    </div>
                </div>

            </div>

            {cartExpanded &&
                <div>
                    <div className="cart-m-h">
                        <Productlist products={props.cart} offertitle={props.offertitle}/>
                        <Productlist products={props.cartLinkedOffer} offertitle={props.offertitleLinkedOffer}/>

                        <div className={"p-4"}>
                            <div className="flex justify-between items-center">
                                <div>
                                    {TranslateText("offer-cart-label-subtotal")}:
                                </div>
                                <div>
                                    {getCartSum().toFixed(2)} {TranslateText("offer-cart-label-currency")}
                                </div>
                            </div>

                            {(props.activeRule !== -1 || props.activeRuleLinkedOffer !== -1) &&
                                <div>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            {TranslateText("offer-cart-label-you-save")}:
                                        </div>

                                    </div>
                                    {getRebateType(props.rules, props.activeRule) !== -1 &&
                                        <div className="flex justify-between items-center">
                                            <div className="ml-2">
                                                {props.offertitle}:
                                            </div>
                                            <div>
                                                {calculateReduction(props.cart, props.rules, props.activeRule).toFixed(2)} {TranslateText("offer-cart-label-currency")} ({parseInt(getActiveRebateValue("value", props.rules, props.activeRule)).toFixed(2)} {getReductionDisplayType(props.rules, props.activeRule)})
                                            </div>
                                        </div>}

                                    {getRebateType(props.rulesLinkedOffer, props.activeRuleLinkedOffer) !== -1 &&
                                        <div className="flex justify-between items-center">
                                            <div className="ml-2">
                                                {props.offertitleLinkedOffer}:
                                            </div>
                                            <div>
                                                {calculateReduction(props.cartLinkedOffer, props.rulesLinkedOffer, props.activeRuleLinkedOffer).toFixed(2)} {TranslateText("offer-cart-label-currency")} ({parseInt(getActiveRebateValue("value", props.rulesLinkedOffer, props.activeRuleLinkedOffer)).toFixed(2)} {getReductionDisplayType(props.rulesLinkedOffer, props.activeRuleLinkedOffer)})
                                            </div>
                                        </div>}
                                </div>
                            }

                            {(calculateAdditionalReduction(props.rules, props.activeRule) > 0 || calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer) > 0) &&

                                <div>
                                    <div>
                                        {TranslateText("cart-label-additional-rebate-sub")}:
                                    </div>

                                    {calculateAdditionalReduction(props.rules, props.activeRule) > 0 &&
                                        <div className="flex justify-between items-center">
                                            <div className="ml-2">
                                                {props.offertitle}:
                                            </div>
                                            <div>
                                                - {calculateAdditionalReduction(props.rules, props.activeRule).toFixed(2)} {TranslateText("offer-cart-label-currency")}
                                            </div>
                                        </div>
                                    }

                                    {calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer) > 0 &&
                                        <div className="flex justify-between items-center">
                                            <div className="ml-2">
                                                {props.offertitleLinkedOffer}:
                                            </div>
                                            <div>
                                                - {calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer).toFixed(2)} {TranslateText("offer-cart-label-currency")}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }


                            <div className="flex justify-between items-center">
                                <div>
                                    {TranslateText("offer-cart-label-full-sum")}:
                                </div>
                                <div>
                                    {getFullSum().toFixed(2)} {TranslateText("offer-cart-label-currency")}
                                </div>
                            </div>
                        </div>

                        <div className="text-center m-2 mb-4">
                            {props.children}
                        </div>

                    </div>
                </div>
            }

        </div>


    )


}