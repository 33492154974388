import React, { useState, Fragment } from "react";
import { getLanguageContent } from "../../../../../../../../../../../core/react/src/helpers/Language";
import { generateCSSString, getPaddingStyles, getResponsiveStyles } from "../../../../../../helpers/style";
import UniversityCard from "./components/UniversityCard";

export default function UniversityCards(props) {

    const component = props.component ?? props.elem;
    const step = 6;
    const [limit, setLimit] = useState(step);
    const cssValue = [...getPaddingStyles('cardList'), ...getResponsiveStyles('cardList', 'Columns'), 'cardList_gap'];

    const showMore = () => {
        setLimit(old => old + step);
    }

    return (
        <Fragment>
        {
            component.data && component.data.length > 0 ?
            
            <div className={`grid ${generateCSSString(component, cssValue)}`}>

                {
                    component.data.slice(0, limit).map(card =>
                        <UniversityCard component={component} card={card}/>)
                }

                {
                    component.data.length > limit && 
                    <div className="flex justify-center col-span-full">
                        <button className="show-more-button" onClick={showMore}>
                            {getLanguageContent('university-show-more', 'Mehr anzeigen')}
                        </button>
                    </div>
                }

            </div>

        :
            <div className={`w-full text-center ${generateCSSString(props.component, cssValue)}`}>
                {getLanguageContent('university-no-entries', 'Keine Einträge gefunden')}
            </div>
            
        }
        </Fragment>

    )
}