import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import {TranslateHtml, TranslateText, Translate, getLanguageContent} from "../../../libs/LanguageProvider";
import {
    calculateAdditionalReduction, calculateCartSumsForSingleOffer,
    calculateReduction,
    evaluateRebateRule, getActiveRebateValue,
    getAdditionalItems, getAdditionalQuantity,
    getAdditionalReduction,
    getProductOfOptionItem, getRebateType, getReductionDisplayType,
    getRuleAdditional
} from "../../../helper/OfferCalculation";

/*
props:
    rules: rules of offer
    activeRule: rule index of active rule
    cart: cart element
    hasLinkedOffer: boolean -> whether there is a linked offer

 */
export default function Conclusionlist(props) {

    const getCartSum = () => {
        let sum = calculateCartSums()
        return sum.price
    }

    /**
     * Calculate sum of both offers -> quantity and price
     * @returns {{quantity: number, price: number}}
     */
    const calculateCartSums = () => {
        const offer = calculateCartSumsForSingleOffer(props.cart)

        if (props.hasLinkedOffer) {
            const linkedOffer = calculateCartSumsForSingleOffer(props.cartLinkedOffer)
            const quantity = offer.quantity + linkedOffer.quantity
            const price = offer.price + linkedOffer.price
            return {quantity: quantity, price: price};
        } else {
            return offer
        }


    }

    const getCartQuantity = () => {
        let sum = calculateCartSums()
        return sum.quantity
    }

    /**
     * Get full Sum of the order
     * @returns {number}
     */
    const getFullSum = () => {
        let offerPrice = 0
        let offerPriceLinkedOffer = 0

        // ----- calculate part for offer
        if (props.cart.length > 0) { // are there selected products from this offer
            const sum = calculateCartSumsForSingleOffer(props.cart)
            let reduction = 0

            if (getRebateType(props.rules, props.activeRule) !== -1) { // a rebate is set
                reduction = calculateReduction(props.cart, props.rules, props.activeRule);
            }

            let additionalReduction = calculateAdditionalReduction(props.rules, props.activeRule)
            offerPrice = sum.price - reduction - additionalReduction;
        }


        // ---- calculate part for linked offer -> if needed :)
        if (props.hasLinkedOffer && props.cartLinkedOffer.length > 0) {
            const sumLinkedOffer = calculateCartSumsForSingleOffer(props.cartLinkedOffer)

            let reductionLinkedOffer = 0;

            if (getRebateType(props.rulesLinkedOffer, props.activeRuleLinkedOffer) !== -1) {// a rebate is set
                reductionLinkedOffer = calculateReduction(props.cartLinkedOffer, props.rulesLinkedOffer, props.activeRuleLinkedOffer);
            }

            let additionalReductionLinkedOffer = calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer)

            offerPriceLinkedOffer = sumLinkedOffer.price - reductionLinkedOffer - additionalReductionLinkedOffer;

        }
        return offerPrice + offerPriceLinkedOffer;

    }

    /*Source: https://stackoverflow.com/questions/53450248/how-to-round-in-javascript-like-php-do*/
    /**
     * Alternative rounding function because the rounding method (.toFixed) sometimes works strange
     * @param num the float number
     * @param dec the count of numbers after the comma
     * @returns
     */
    function roundFloat(num, dec) {
        const num_sign = num >= 0 ? 1 : -1;
        return (Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec);
    }

    return (

        <div className={"p-4"}>
            <div className="flex justify-between items-center">
                <div>
                    {TranslateText("offer-cart-label-subtotal")}:
                </div>
                <div>
                    {roundFloat(getCartSum(), 2)} {TranslateText("offer-cart-label-currency")}
                </div>
            </div>

            {props.hasLinkedOffer && (props.activeRule !== -1 || props.activeRuleLinkedOffer !== -1) &&
                <div>
                    <div className="flex justify-between items-center">
                        <div>
                            {TranslateText("offer-cart-label-you-save")}:
                        </div>

                    </div>

                    {getRebateType(props.rules, props.activeRule) !== -1 &&
                        <div className="flex justify-between items-center">
                            <div className="ml-2">
                                <span className="mr-2">1. {getLanguageContent("offer-offerte", 'Offerte')}</span>
                            </div>
                            <div>
                                {roundFloat(calculateReduction(props.cart, props.rules, props.activeRule), 2)} {TranslateText("offer-cart-label-currency")} ({parseInt(getActiveRebateValue("value", props.rules, props.activeRule)).toFixed(2)} {getReductionDisplayType(props.rules, props.activeRule)})
                            </div>
                        </div>}

                    {getRebateType(props.rulesLinkedOffer, props.activeRuleLinkedOffer) !== -1 &&
                        <div className="flex justify-between items-center">
                            <div className="ml-2">
                                <span className="mr-2">2. {getLanguageContent("offer-offerte", 'Offerte')}</span>
                            </div>
                            <div>
                                {roundFloat(calculateReduction(props.cartLinkedOffer, props.rulesLinkedOffer, props.activeRuleLinkedOffer), 2)} {TranslateText("offer-cart-label-currency")} ({parseInt(getActiveRebateValue("value", props.rulesLinkedOffer, props.activeRuleLinkedOffer)).toFixed(2)} {getReductionDisplayType(props.rulesLinkedOffer, props.activeRuleLinkedOffer)})
                            </div>
                        </div>}
                </div>
            }

            {!props.hasLinkedOffer && (props.activeRule !== -1) && getRebateType(props.rules, props.activeRule) !== -1 &&

                <div className="flex justify-between items-center">
                    <div>
                        {TranslateText("offer-cart-label-you-save")}:
                    </div>
                    <div>
                        {roundFloat(calculateReduction(props.cart, props.rules, props.activeRule), 2)} {TranslateText("offer-cart-label-currency")} ({parseInt(getActiveRebateValue("value", props.rules, props.activeRule)).toFixed(2)} {getReductionDisplayType(props.rules, props.activeRule)})
                    </div>
                </div>

            }


            {props.hasLinkedOffer && (calculateAdditionalReduction(props.rules, props.activeRule) > 0 || calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer) > 0) &&

                <div>
                    <div>
                        {TranslateText("cart-label-additional-rebate-sub")}:
                    </div>

                    {calculateAdditionalReduction(props.rules, props.activeRule) > 0 &&
                        <div className="flex justify-between items-center">
                            <div className="ml-2">
                                <span>1. {getLanguageContent("offer-offerte", 'Offerte')}</span>
                            </div>
                            <div>
                                - {roundFloat(calculateAdditionalReduction(props.rules, props.activeRule), 2)} {TranslateText("offer-cart-label-currency")}
                            </div>
                        </div>
                    }

                    {calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer) > 0 &&
                        <div className="flex justify-between items-center">
                            <div className="ml-2">
                                <span>2. {getLanguageContent("offer-offerte", 'Offerte')}</span>
                            </div>
                            <div>
                                - {roundFloat(calculateAdditionalReduction(props.rulesLinkedOffer, props.activeRuleLinkedOffer), 2)} {TranslateText("offer-cart-label-currency")}
                            </div>
                        </div>
                    }
                </div>
            }

            {!props.hasLinkedOffer && (calculateAdditionalReduction(props.rules, props.activeRule)) > 0 &&
                <div className="flex justify-between items-center">
                    <div>
                        {TranslateText("cart-label-additional-rebate-sub")}:
                    </div>
                    <div>
                        - {roundFloat(calculateAdditionalReduction(props.rules, props.activeRule), 2)} {TranslateText("offer-cart-label-currency")}
                    </div>
                </div>

            }


            <div className="flex justify-between items-center">
                <div>
                    {TranslateText("offer-cart-label-full-sum")}:
                </div>
                <div>
                    {roundFloat(getFullSum(), 2)} {TranslateText("offer-cart-label-currency")}
                </div>
            </div>
        </div>
    )


}