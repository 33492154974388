import React, {useEffect, useState, Fragment} from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/system";
import {TimelineOppositeContent, timelineOppositeContentClasses} from "@mui/lab";
import {getLanguageContent} from "../../../../libs/LanguageProvider";
import axios from "axios";
import {getUserLanguages} from "../../../../helper/User";


const agendatheme = createTheme({
    typography: {
        fontFamily: [
            'Arial',

        ].join(','),
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FFFFFF',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        // Provide every color token (light, main, dark, and contrastText) when using
        // custom colors for props in Material UI's components.
        // Then you will be able to use it like this: `<Button color="custom">`
        // (For TypeScript, you need to add module augmentation for the `custom` value)
        custom: {
            light: '#ffa726',
            main: '#f57c00',
            dark: '#ef6c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});


// Create Agenda Timeline
export default function WebinarAgenda(props) {
    const [dates, setDates] = useState([])

    useEffect(() => {
        setDates(sortedDates)
    }, [])

    const splitTime = (timestring) => {
        let agendaToSplit = timestring.split(":");
        return agendaToSplit.slice(0, agendaToSplit.length - 1).join(":");
    }

    const sortedDates = Object.keys(props.agendaitems).sort((a, b) => {
        const dateA = new Date(a.split('.').reverse().join('-'));
        const dateB = new Date(b.split('.').reverse().join('-'));
        return dateA - dateB;
    });

    return (
        <ThemeProvider theme={agendatheme}>
            <Timeline style={{
                padding: "0 !important"
            }}
                      sx={{
                          [`& .${timelineOppositeContentClasses.root}`]: {
                              flex: 0.2,
                          },
                      }}>
                {dates.length > 0 && dates.map((datestring) => {
                    return <Fragment>
                        {dates.length > 1 &&
                            <div className="text-white font-bold text-lg mb-2 underline">{datestring}:</div>
                        }
                        {
                            props.agendaitems[datestring] && props.agendaitems[datestring].map((agendaitem, index) => {
                                return <TimelineItem>
                                    <TimelineOppositeContent className="text-white hidden sm:block">
                                        <div className="text-white hidden md:block">
                                            <div
                                                className="font-bold">{splitTime(agendaitem['agenda_from'])} - {splitTime(agendaitem['agenda_to'])} {getLanguageContent('webinarregistration-clock', 'Uhr')}</div>
                                        </div>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" color="primary"/>
                                        {index !== props.agendaitems[datestring].length - 1 &&
                                            <TimelineConnector color="primary"/>
                                        }
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <div className="text-white">
                                            <div className="text-white md:hidden mb-2">
                                                <div
                                                    className="font-bold">{splitTime(agendaitem['agenda_from'])} - {splitTime(agendaitem['agenda_to'])} {getLanguageContent('webinarregistration-clock', 'Uhr')}</div>
                                            </div>
                                            <span className="font-bold">{agendaitem['agenda_title']}</span>
                                            <div
                                                className="font-400">{getLanguageContent('webinarregistration-speaker', 'Referent')}: {agendaitem['agenda_speaker']}</div>
                                        </div>
                                    </TimelineContent>
                                </TimelineItem>
                            })
                        }
                    </Fragment>

                })
                }
            </Timeline>
        </ThemeProvider>
    );
}