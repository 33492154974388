import React, { useState, Fragment } from "react";
import { generateCSSString } from "../../../../../CmsPage/helpers/style";
import { getPaddingStyles } from "../../../../../CmsPage/helpers/style";
import { userComponentAccess } from "../../../../helpers/user";
import ComponentFrontend from "../..";
import ComponentBackend from "../../../../../CmsDesigner/components/Component";


export default function AccordionList(props) {

    const {component, draggable, ...rest} = props;
    
    const backend = props.backend ?? false;

    const [expanedElement, setExpandedElement] = useState(null);

    const cssValue= [...getPaddingStyles('accList'), 'accList_gap'];

    return (
        <Fragment>
            {
                (backend || (component.hidden_ != 1 && userComponentAccess(component.roleId_, props.previewPageRoleId))) &&
                <div
                    id={`${component.anchor_ ? component.anchor_ : 'AccList-' + component.uuid}`} 
                    className={`overflow-hidden w-full flex flex-wrap ${generateCSSString(component, cssValue)}`}
                >
                    {
                        component.children && component.children.map((child) => {

                            let accordion = {...child,
                                initialDefault: component.accList_accListInitialDisplay, 
                                autocollapse: component.accList_autocollapse,
                                expandedElement: expanedElement,
                                handleExpandedElement: setExpandedElement,
                                iconPos: component.accList_iconPosition,
                                textColor: component.accListCollapsedText_color,
                                textColorExpanded: component.accListExpandedText_color,
                                backgroundColor: component.accListCollapsedBackground_color,
                                backgroundColorExpanded: component.accListExpandedBackground_color,
                                iconColor: component.accListCollapsedbtnText_color,
                                iconColorExpanded: component.accListExpandedbtnText_color,
                                border: component.accList_borderWidth,
                                borderRadius: component.accList_borderRadius,
                                borderColor: component.accListCollapsedBorder_color,
                                borderColorExpanded: component.accListExpandedBorder_color,
                                accordion_desktopPaddingTop: component.accordion_desktopPaddingTop,
                                accordion_desktopPaddingBottom: component.accordion_desktopPaddingBottom,
                                accordion_desktopPaddingRight: component.accordion_desktopPaddingRight,
                                accordion_desktopPaddingLeft: component.accordion_desktopPaddingLeft,
                                accordion_tabletPaddingTop: component.accordion_tabletPaddingTop,
                                accordion_tabletPaddingBottom: component.accordion_tabletPaddingBottom,
                                accordion_tabletPaddingRight: component.accordion_tabletPaddingRight,
                                accordion_tabletPaddingLeft: component.accordion_zabletPaddingLeft,
                                accordion_mobilePaddingTop: component.accordion_mobilePaddingTop,
                                accordion_mobilePaddingBottom: component.accordion_mobilePaddingBottom,
                                accordion_mobilePaddingRight: component.rdion_mobilePaddingRight,
                                accordion_mobilePaddingLeft: component.accordion_mobilePaddingLeft,
                            }
                    
                            if(backend) 
                                return <ComponentBackend component={accordion} {...rest} />
                            return <ComponentFrontend component={accordion}  {...rest}/>

                        })
                    }
                </div>
            }
        </Fragment>
    )
}