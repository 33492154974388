import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Footer from "../../components/Footer";

import {
    NavLink,
    useParams
} from "react-router-dom";
import {TranslateHtml, TranslateText} from "../../libs/LanguageProvider";
import OfferSelection from "../../components/Home";
import axios from "axios";
import SanofiButton from "../../components/SanofiButton";


export default function PosMaterial(props) {

    const [brands, setBrands] = useState({})
    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorState, setErrorState] = useState(false)

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/fetchPos",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setErrorState(true);
                return;
            }

            if (data.data.data.brands) {
                setBrands(data.data.data.brands);
                setHasLoaded(true);
            }

        });


    }, [])

    return (

        <Fragment>

            <Page>
                <TopBar/>
                <Hero/>
                <ContentArea>

                    <h1 className="text-4xl color-sanofi-dark-lila font-bold">{TranslateText("pos-title")}</h1>

                    <div className="mb-8">
                        <TranslateHtml name="pos-content-top"/>
                    </div>

                    {hasLoaded &&
                        <div className={"flex flex-row items-stretch flex-wrap mb-12"}>
                            {brands.map((brand, index) => {
                                return (

                                    <div className={"rounded-xl bg-sanofi-offer-item flex flex-col p-8 mb-4 mr-8"}>

                                        <div className={"w-64 h-48 flex flex-col justify-center"}>
                                            <img className={"w-64"} src={brand.picture} alt={brand.name}/>
                                        </div>

                                        <div className={"mt-8 mb-4 text-center"}>
                                            <NavLink to={"/marketplace/pos/" + brand.id}>
                                                <SanofiButton>
                                                    {TranslateText("pos-button-to-material")}
                                                </SanofiButton>
                                            </NavLink>
                                        </div>

                                    </div>


                                )
                            })}
                        </div>
                    }

                    {!hasLoaded &&
                        <div className="flex items-center justify-center h-screen">
                            <div
                                className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                        </div>
                    }

                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>

    )
}