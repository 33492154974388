import React, {useEffect, useState} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import {getLanguageContent} from "../../libs/LanguageProvider";


export default function ELearningFail(props) {
    const [eLearning, setELearning] = useState({})


    useEffect(() => {
        if (localStorage.getItem("return_data")) {
            setELearning(JSON.parse(localStorage.getItem("return_data")))
            localStorage.removeItem("return_data");
        } else {
            window.location = '/university/home'
        }
    }, [])

    const getBackURL = () => {
        if (eLearning.uuid) {
            return '/university/elearnings/' + eLearning.uuid
        } else {
            return '/university/home'
        }

    }

    return (
        <Page className="flex flex-column">
            <TopBar logo={'Sanofi University'}/>
            <Hero menuType={-1}/>
            <ContentArea>
                <div className="md:mt-28 md:pb-28 text-center">
                    <h1 className="text-3xl text-sanofi-error font-bold mb-5"
                    >{getLanguageContent('elearning-fail-title', 'Quiz wiederholen!')}</h1>
                    <div className="flex items-center justify-center mb-8">
                        <div className=" min-w-5/6">
                            <div className="max-w-md">{getLanguageContent('elearning-fail-description',
                                'Ups - Sie haben die Fortbildung leider nicht erfolgreich absolviert. Hierfür müssen 80% der Fragen korrekt beantwortet werden.')}</div>
                        </div>
                    </div>
                    <a className={
                        "mt-4 rounded-3xl hover:bg-sanofi-button-default border-sanofi-dark-lila bg-sanofi-dark-lila text-white py-3 px-14 rounded"}
                       href={getBackURL()}>
                        {getLanguageContent('text-eLearning-fail-back', 'Quiz wiederholen')}
                    </a>
                </div>
            </ContentArea>
            <Footer className="mt-auto"/>
        </Page>
    )
}