import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import Page from "../../../components/Page";
import TopBar from "../../../components/Header/TopBar";
import Hero from "../../../components/Header/Hero";
import ContentArea from "../../../components/ContentArea";
import Footer from "../../../components/Footer";

import {
    NavLink, useNavigate,
    useParams
} from "react-router-dom";
import {TranslateHtml, TranslateText} from "../../../libs/LanguageProvider";
import axios from "axios";
import SanofiButton from "../../../components/SanofiButton";
import Input from "../../../components/Form/Input";
import Typeahead from "../../../components/Form/Typeahead";
import Select from "../../../components/Form/Select";
import Multiselect from "multiselect-react-dropdown";
import ProductTable from "../../../components/ProductTable";
import {useModal} from "../../../libs/ModalProvider";
import MessagePrompt from "../../Modals/MessagePrompt";


export default function PosBrand(props) {

    let { slug } = useParams();
    let formRef = React.useRef();
    let navigate = useNavigate();

    const { setModal, closeModal } = useModal()

    const [products, setProducts] = useState([])
    const [cart, setCart] = useState([])
    const [profile, setProfile] = useState({})
    const [labels, setLabels] = useState({})

    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorState, setErrorState] = useState(false)

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/"+slug+"/fetchMaterial/frontend/",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setErrorState(true);
                return;
            }

            if (data.data.data) {
                setProducts(data.data.data.products);

                if (data.data.data.products.length <= 0) {
                    navigate("/marketplace/home", false);
                }

                setProfile(data.data.data.profile);
                setLabels(data.data.data.labels);
                setHasLoaded(true);
            }

        });


    }, [])

    const onTypeaheadSelect = (value) => {
        onUpdateProfileValue("locationCode", value);
    }

    const onUpdateProfileValue = (key,value) => {
        const buff = {...profile}
        buff[key] = value;
        setProfile(buff);
    }

    const onOrderSubmit = () => {

        if (!formRef.current) {
            return;
        }

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        if (cart.length <= 0) {
            setModal(
                <MessagePrompt
                    content={<TranslateHtml name="posm-error-please-select-product"/>}
                />
            )
            return;
        }

        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/placePOSOrder/",
            data: {
                cart: cart,
                profile: profile,
                type: "posm"
            }
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                //setErrorState(true);
                return;
            }

            navigate("/marketplace/thankyou")

        });


    }

    const onProductTableChange = (index, key, value) => {

        const productsBuff = [...products];
        const cartBuff = [];
        productsBuff[index][key] = value;

        productsBuff.forEach(product => {
            if (product.quantity > 0) {
                cartBuff.push(product)
            }
        })

        setProducts(productsBuff);
        setCart(cartBuff);

    }

    const updateInterest = (interest) => {
        const profileBuff = {...profile};
        profileBuff["interests"] = interest;
        setProfile(profileBuff);
    }


    return (

        <Fragment>

            <Page>
                <TopBar/>
                <Hero/>
                <ContentArea>


                    {hasLoaded &&

                        <div>

                            <TranslateHtml name="pos-brand-content-top"/>

                            <h1 className={"text-4xl color-sanofi-dark-lila font-bold"}>{TranslateText("pos-brand-cart")}</h1>

                            <ProductTable products={products} hidePrice={true} onChange={(index, key, value) => {onProductTableChange(index, key, value)}}/>

                            <h1 className={"text-4xl color-sanofi-dark-lila font-bold mt-8"}>{TranslateText("pos-brand-title")}</h1>

                            <form ref={formRef} className={"mt-8"}>
                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-name-apotheke")}*</label>
                                        </div>
                                        <Input required value={profile.companyName} onChange={(e) => {
                                            onUpdateProfileValue("companyName", e.target.value)
                                        }} type="text"/>
                                    </div>
                                </div>

                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-locationcode")}*</label>
                                        </div>
                                        <Typeahead
                                            selected={profile.locationCode}
                                            onSelect={(value) => {
                                                onTypeaheadSelect(value);
                                            }}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-1">
                                            <div className={"mb-1.5"}>
                                                <label>{TranslateText("cart-label-street")}*</label>
                                            </div>
                                            <Input value={profile.street} onChange={(e) => {
                                                onUpdateProfileValue("street", e.target.value)
                                            }} required type="text"/>
                                        </div>
                                    </div>
                                </div>


                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-note-wish")}</label>
                                        </div>
                                        <textarea
                                            onChange={(e) => {
                                                onUpdateProfileValue("note", e.target.value)
                                            }}
                                            className="form-control block p-2.5 w-full text-sm text-gray-900 bg-white font-sans rounded-lg border-gray-100 border-l-2 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila "/>
                                    </div>
                                </div>

                                <div>
                                    <TranslateHtml name="cart-after-user-data"/>
                                </div>

                            </form>


                            <div className={"mt-8 mb-8 text-center lg:text-right"}>
                                <SanofiButton onClick={(e) => {onOrderSubmit()}}>
                                    {TranslateText("posm-label-button-order-material")}
                                </SanofiButton>
                            </div>


                        </div>
                    }

                    {!hasLoaded &&
                        <div className="flex items-center justify-center h-screen">
                            <div className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                        </div>
                    }



                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>

    )
}