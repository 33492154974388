import React, { Fragment } from "react";
import { generateCSSString } from "../../../../CmsPage/helpers/style";
import { getPaddingStyles, getResponsiveStyles } from "../../../../CmsPage/helpers/style";
import ComponentFrontend from "..";
import ComponentBackend from "../../../../CmsDesigner/components/Component";

export default function MainContainer(props) {

    const {component, draggable, ...rest} = props;

    const backend = props.backend ?? false;

    const cssValue = [  ...getPaddingStyles('mainContainer'),
                        ...getResponsiveStyles('mainContainer', 'FlexDirection'),
                        ...getResponsiveStyles('mainContainer', 'JustifyContent'), 
                        ...getResponsiveStyles('mainContainer', 'AlignItems')
                    ];
    

    return (
        <Fragment>
            <div className={`pl-2 pr-2 w-full grow flex ${component.mainContainerFullWidth_ == 1 && backend ? 'pl-2 pr-2' : ''}`}>
                <div
                    id={`'MainContainer-${component.uuid}`} 
                    className={`grow flex content-start ${generateCSSString(component, cssValue)} ${component.mainContainerFullWidth_ == 1? 'w-full' : 'container'}`}
                    style={{ backgroundColor: `${component.mainContainerBackground_color ? component.mainContainerBackground_color : 'none'}`}}
                >
                        {
                            component.children && component.children.map((child) => {
                                if(backend) 
                                    return <ComponentBackend component={child} {...rest} />
                                return <ComponentFrontend component={child} {...rest}/>
                            })
                        }
                    {
                        props.matNumber && <div className='w-full text-right text-sm mt-3'>{props.matNumber}</div>
                    }

                </div>
            </div>
        </Fragment>
    ) 
}