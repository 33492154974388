import React, {useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';

export default function Title(props) {

    return (
        <div className='text-4xl color-sanofi-dark-lila font-bold'
             dangerouslySetInnerHTML={{__html: props.title}}/>
    )


}