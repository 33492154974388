import React, {
    useState,
    createContext,
    useContext,
    useCallback,
    useEffect,
    useRef,
} from 'react';

import SucHelper from "./General.js";
import axios from "axios";
export const LanguageContext = createContext({
    language: 'de',
    entities: {},
    updateLanguage: () => { },
});

export const LanguageProvider = ({ children, fetchTranslations }) => {
    const [{ language, entities }, setLanguage] = useState({
        language: 'de',
        entities: {},
    });

    const initialStringsLoaded = useRef(false);

    const updateLanguage = useCallback(
        async (newLang) => {
            if (initialStringsLoaded.current && newLang === language) return;
            const newEntities = await fetchTranslations({ language: newLang });
            initialStringsLoaded.current = true;
            setLanguage({
                language: newLang,
                entities: newEntities,
            });
        },
        [language, fetchTranslations]
    );

    useEffect(() => {
        updateLanguage(language);
    }, [language, updateLanguage]);

    const context = {
        language,
        entities,
        updateLanguage: updateLanguage,
    };

    return (
        <LanguageContext.Provider value={context}>
            {children}
        </LanguageContext.Provider>
    );
};

export const fetchTranslations = ({ language = "de" }) => {
    return axios
        .get(
            "/core/react/language/" +
            SucHelper.getUserLanguages() +
            ".json?nocache=" +
            Math.random()
        )
        .then((data) => {
            return data.data;
        });
};

export const TranslateText = (text) => {
    let divContext = false;
    if (text.text != undefined) {
        text = text.text;
    } else {
        text = text;
    }
    const { entities } = useContext(LanguageContext);
    const lEntity = entities[text] || text;
    if (lEntity === undefined) {
        return text;
    }
    if (lEntity.content !== undefined) {
        if (divContext) {
            return <div>{lEntity.content}</div>;
        } else {
            return lEntity.content;
        }
    }
    return text;
};

export const getLanguageContent = (children, defaultValue) => {
    const { entities } = useContext(LanguageContext);
    const lEntity = entities[children];
    if (lEntity === undefined) {
        return defaultValue;
    }
    return lEntity.content;
};

export const TranslateHtml = ({ name }) => {
    const { entities } = useContext(LanguageContext);
    const lEntity = entities[name] || name;
    if (lEntity === undefined) {
        return name;
    }
    return <div dangerouslySetInnerHTML={{ __html: lEntity.content }} />;
};

export const Translate = ({ children }) => {
    const { entities } = useContext(LanguageContext);
    const lEntity = entities[children] || children;
    if (lEntity === undefined) {
        return children;
    }
    return <div dangerouslySetInnerHTML={{ __html: lEntity.content }} />;
};
