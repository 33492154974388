import React from "react";
import BoxWrapper from "./BoxWrapper";
import AccountVerify from "./accountverify/AccountVerify"
import clsx from "clsx";

export default function PasswordResetBox(props) {

    return (
        <BoxWrapper id={"password-reset-box"}>
            <div className="box-basic">
                <div
                    className={clsx("loginscreen-header", 'login-mb-1', 'fontRalewayRegular')}>
                    <a>
                        <img class="h-16 mx-auto" src="/design/frontend/img/sanoficonnect_logo_x2.png" alt="sanofi Connect"/>
                    </a>
                </div>
                <AccountVerify
                    lang={props.lang}
                    verificationToken={props.verificationToken}
                    runTokenCheck={props.runTokenCheck}
                />
            </div>
        </BoxWrapper>
    );
}