import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Footer from "../../components/Footer";
import {getLanguageContent, TranslateText} from "../../libs/LanguageProvider";
import FieldElement from "../../components/FormDesigner/components/fieldElement";
import axios from "axios";
import {getUserLanguages} from "../../helper/User";


export default function UniversityHome(props) {
    const [path, setPath] = useState('/home')

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getWebinarsAndELearnings/formdesigner",
            data: {
                lang: getUserLanguages(),
            }
        }).then(data => {
            const alldata = data.data.data
            if (alldata.length > 0) {
                setPath(alldata[0].path)
            }

        });

    }, [])

    const picture = '/react/frontend/src/views/UniversityHome/img/img_2.png';
    const picture1 = '/react/frontend/src/views/UniversityHome/img/img_3.png';
    const picture2 = '/react/frontend/src/views/UniversityHome/img/img.png';


    return (

        <Fragment>
            <Page>
                <TopBar logo={'Sanofi University'}/>
                <Hero/>
                <ContentArea>
                    <h1 className={"text-4xl color-sanofi-dark-lila font-bold mb-5"}>{getLanguageContent('home-university-title', 'Alle Weiterbildungsangebote')}</h1>
                    <div className='grid grid-cols-3'>
                        <a className="w-100 h-100" href={path}>
                            <div className="rounded-md bg-sanofi-gray m-3 h-100">
                                <div className="h-40">
                                    <div className="rounded-t-lg bg-no-repeat bg-cover bg-center h-100 w-100 "
                                         style={{
                                             backgroundImage: `url(${picture})`,
                                         }}>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div className="text-sm uppercase font-600 mb-2 color-sanofi-lila">Live Webinar
                                    </div>
                                    <div className='text-xl color-sanofi-dark-lila font-bold mb-3'>Allergiemanagement in
                                        Apotheke und Drogerie
                                    </div>
                                    <div className='grid grid-rows-2'>
                                        <div className='flex'>
                                            <svg className="w-5"
                                                 color="#7A00E6"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24"
                                                 stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
                                            </svg>

                                            <div className="ml-2">Am 22. Mai 2023, 19.30 Uhr bis 21.00 Uhr</div>
                                        </div>
                                        <div className='mt-2 flex'>
                                            <img className="w-5"
                                                 src="/react/frontend/src/components/InfoTile/svg/certificate.svg"
                                                 alt="Certificate-Icon"/>

                                            <div className="ml-2">12.5 FPH-Punkte und 2 SDV-Punkte</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </a>

                        <a className="w-100 h-100" href={path}>
                            <div className="m-3 rounded-md bg-sanofi-gray h-100">
                                <div className="h-40">
                                    <div className="rounded-t-lg bg-no-repeat bg-cover bg-center h-100 w-100 "
                                         style={{
                                             backgroundImage: `url(${picture1})`,
                                         }}>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div className="text-sm uppercase font-600 mb-2 color-sanofi-lila">Produkttraining
                                    </div>
                                    <div className='text-xl color-sanofi-dark-lila font-bold mb-3'>Mikrobiom im
                                        Gleichgewicht
                                    </div>
                                    <div className='grid grid-rows-2'>
                                        <div className='flex'>
                                            <svg className="w-5"
                                                 color="#7A00E6"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                            </svg>


                                            <div className="ml-2">50 Minuten</div>
                                        </div>
                                        <div className='mt-2 flex'>
                                            <img className="w-5"
                                                 src="/react/frontend/src/components/InfoTile/svg/certificate.svg"
                                                 alt="Certificate-Icon"/>

                                            <div className="ml-2">6.25 FPH-Punkte</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </a>

                        <a className="w-100 h-100" href={path}>
                            <div className="m-3 rounded-md bg-sanofi-gray h-100">
                                <div className="h-40">
                                    <div className="rounded-t-lg bg-no-repeat bg-cover bg-center h-100 w-100 "
                                         style={{
                                             backgroundImage: `url(${picture2})`,
                                         }}>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div className="text-sm uppercase font-600 mb-2 color-sanofi-lila">Zertifizierte
                                        Weiterbildung
                                    </div>
                                    <div className='text-xl color-sanofi-dark-lila font-bold mb-3'>Aktuelle Impulse für
                                        die Wintersaison
                                    </div>
                                    <div className='grid grid-rows-2'>
                                        <div className='flex'>
                                            <svg className="w-5"
                                                 color="#7A00E6"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 24 24"
                                                 stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
                                            </svg>

                                            <div className="ml-2">50 Minuten</div>
                                        </div>
                                        <div className='mt-2 flex'>
                                            <img className="w-5"
                                                 src="/react/frontend/src/components/InfoTile/svg/certificate.svg"
                                                 alt="Certificate-Icon"/>

                                            <div className="ml-2">6.25 FPH-Punkte</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <h2 className={"text-4xl color-sanofi-dark-lila font-bold mt-10 mb-5"}>{getLanguageContent('home-university-more-topic', 'Weitere Interessante Themen für Sie')}</h2>

                    <div className='flex flex-col md:flex-row flex-wrap'>
                        <div className="w-1/2 h-max">
                            <img className='rounded-md'
                                 src='/react/frontend/src/views/UniversityHome/img/Gruppe_1040.png'/>
                            <div className='mt-2'>
                                <h3 className='text-2xl color-sanofi-dark-lila'>Bisolvon.ch</h3>
                                <div>Kennen Sie schon unsere Hustenwebseite?</div>
                            </div>
                        </div>

                        <div className="w-1/2 h-max">
                            <img className='rounded-md'
                                 src='/react/frontend/src/views/UniversityHome/img/Gruppe_1039.png'/>
                            <div className='mt-2'>
                                <h3 className='text-2xl color-sanofi-dark-lila'>MARKETPLACE</h3>
                                <p>Unsere Offertenplattform für unabhängige Apotheken und Drogerien</p>
                            </div>
                        </div>

                    </div>

                </ContentArea>
                <Footer/>
            </Page>
        </Fragment>

    )
}