import React, { Fragment } from "react";
import { generateCSSString } from "../../../../CmsPage/helpers/style";
import { getPaddingStyles, getResponsiveStyles } from "../../../../CmsPage/helpers/style";
import ComponentFrontend from "..";
import ComponentBackend from "../../../../CmsDesigner/components/Component";


export default function Header(props) {

    const {component, draggable, ...rest} = props;

    const backend = props.backend ?? false;

    const cssValue = [...getPaddingStyles('header'),
                            ...getResponsiveStyles('header', 'FlexDirection'), 
                            ...getResponsiveStyles('header', 'JustifyContent'), 
                            ...getResponsiveStyles('header', 'AlignItems')
                        ];

    return (
        <Fragment>
                <div
                    id={`'Header-${component.uuid}`} 
                    className={`header ${generateCSSString(component, cssValue)} w-full flex`}
                    style={{
                            backgroundColor: `${component.headerBackground_color ? component.headerBackground_color : 'none'}`
                        }}
                    >
                        { (!component.children || component.children.length === 0) && component.image_ &&
                            <div className='py-3'>
                                <a href={component.imageLink_ ?? '/'}>
                                    <img class="h-12" src={component.image_} alt="Logo"></img>
                                </a>
                            </div>
                        }

                        {
                            component.children && component.children.map((child) => {
                                if(backend) 
                                    return <ComponentBackend component={{...child, image_: component.image_ , imageLink_: component.imageLink_}} {...rest}/>
                                return <ComponentFrontend component={{...child, image_: component.image_ , imageLink_: component.imageLink_}} {...rest}/>
                            })
                        }

                    </div>
        </Fragment>
    )
}