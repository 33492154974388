import React from "react";
import { generateCSSString, getPaddingStyles } from "../../../../../helpers/style";
import Image from "../../Image";
import Title from "../../Title";
import Text from "../../Text";
import Button from "../../Button";



export default function DefaultCard(props) {

    const component = props.component;

    const cssValueContainer = ['card_borderRadius'];

    const cssValue = [...getPaddingStyles('card')];


    return (
            <div 
                id={`DefaultCard-${component.uuid}`} 
                className={`h-full ${generateCSSString(component, cssValueContainer)} card-shadow flex flex-col grow overflow-hidden`} 
                style={{
                    backgroundColor: component.cardBackground_color,
                    border: `${component.cardBorder_color ? '1px solid' + component.cardBorder_color : 'none'}`
                    }}
            >
   
                {
                    component.image_ && 
                    <Image 
                        component={
                            {
                                ...component, 
                                image_desktopWidth: 'lg:w-full',
                                image_tabletWidth: 'sm:w-full',
                                image_mobileWidth: 'w-full',
                            }
                        }
                        className={'cardImageHeight'}   
                    />
                }
                
                <div className={`${generateCSSString(component, cssValue)} grow flex flex-col justify-between`}>
                    <div>
                        {   
                            component.titleText_ && 
                            <Title component={component}/>
                        }

                        {   
                            component.textContent_ && 
                            <Text component={component}/>
                        }
                    </div>

                    {
                        component.btnText_ &&
                        <Button 
                            component={component}
                            target={props.target}
                        />
                    }
                </div>

            </div>
    )
}