export const getDefaultActiveLangs = (allLangs, defaultLangs = []) => {
    let activeLangs = {};
    for(let i = 0; i < allLangs.length; i++){
        let lang = allLangs[i];
        if(defaultLangs.length === 0)
            activeLangs[lang] = i === 0 ? true : false;
        else{
            if (defaultLangs.includes(lang))
                activeLangs[lang] = true;
            else
                activeLangs[lang] = false;
        }
    }
    return activeLangs
}


export const mapLangstoInitString = (allLangs, value='') => {
    let stringsByLang = {}
    for(let lang of allLangs){
        stringsByLang[lang] = value;
    }
    return stringsByLang;
}