import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Footer from "../../components/Footer";

import {
    useNavigate,
    useParams
} from "react-router-dom";
import axios from "axios";
import {TranslateHtml, TranslateText} from "../../libs/LanguageProvider";
import Select from "../../components/Form/Select";
import Input from "../../components/Form/Input";
import Typeahead from "../../components/Form/Typeahead";
import {
    evaluateRebateRule,
    getAdditionalItems, getAdditionalReduction,
    getProductOfOptionItem,
    getRuleAdditional
} from "../../helper/OfferCalculation";
import Multiselect from "multiselect-react-dropdown";
import SanofiButton from "../../components/SanofiButton";
import ProductTable from "../../components/ProductTable";
import {parse, format} from 'date-fns';
import SimpleCart from "../../components/Cart/SimpleCart";
import Conclusionlist from "../../components/Cart/components/conclusionlist";
import GLN from "../../components/GLN";

export default function Cart(props) {

    let {slug} = useParams();
    let formRef = React.useRef();
    let navigate = useNavigate();

    const [data, setData] = useState({
        cart: [],
        offer: {},
        profile: {},
        labels: {},
    });

    const [hasLoaded, setHasLoaded] = useState(false)

    // include whole card item in localstorage (ordered items of both offers or just one
    const [activeOrder, setActiveOrder] = useState({})

    const [profile, setProfile] = useState({})
    const [cart, setCart] = useState([])
    const [offer, setOffer] = useState({})
    const [labels, setLabels] = useState({})
    const [activeRule, setActiveRule] = useState(-1)

    const [linkedOfferExist, setLinkedOfferExist] = useState(false)
    const [linkedOffer, setLinkedOffer] = useState({})
    const [cartLinkedOffer, setCartLinkedOffer] = useState([])
    // the products that has been ordered by customer from this offer
    //const [activeOrderLinkedOffer, setActiveOrderLinkedOffer] = useState({})
    const [activeRuleLinkedOffer, setActiveRuleLinkedOffer] = useState(-1)
    const [onSubmit, setOnSubmit] = useState(false)

    useEffect(() => {

        const aOrderString = window.localStorage.getItem("cart")
        const aOrder = JSON.parse(aOrderString);

        if (aOrder === null || !aOrder.hasOwnProperty("slug")) {
            navigate("/marketplace/home");
            return;
        }

        axios({
            dataType: "json",
            method: "post",
            url: "/module/" + aOrder.slug + "/fetchCart/frontend/",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                navigate("/marketplace/home");
                return;
            }

            if (data.data.data) {
                setData(data.data.data);
                setProfile(data.data.data.profile);
                setOffer(data.data.data.offer);
                setLabels(data.data.data.labels);
                setActiveOrder(aOrder);

                if (aOrder.hasOwnProperty("cartLinkedOffer")) {
                    setLinkedOffer(data.data.data.offer.linkedOffer)
                    setLinkedOfferExist(true)
                }


                setHasLoaded(true);
            }

        });


    }, [])

    useEffect(() => {

        if (!hasLoaded) {
            return;
        }


        const cardValues = setValuesForProducts(activeOrder.cart, offer.products, offer)
        if (cardValues.cart.length > 0) {
            setCart(cardValues.cart);
            setActiveRule(cardValues.activeRule)
        }

        if (linkedOfferExist) {
            const cardLinkedValues = setValuesForProducts(activeOrder.cartLinkedOffer, linkedOffer.products, linkedOffer)
            setCartLinkedOffer(cardLinkedValues.cart);
            setActiveRuleLinkedOffer(cardLinkedValues.activeRule)
        }

    }, [hasLoaded])

    /**
     *
     * @param cartBuff ordered products of one offer
     * @param oProducts all propducts of the offer
     * @param offerelem
     * @returns {{activeRule: number, cart}}
     */
    const setValuesForProducts = (cartBuff, oProducts, offerelem) => {
        cartBuff = cartBuff.filter(item => item.checkFor === undefined);


        cartBuff.forEach((cartItem, index) => {
            let found = false;
            let dateStart = undefined;
            let dateEnd = undefined;

            oProducts.forEach((product) => {
                if (product.id === cartItem.id && !found) {
                    found = true;
                    dateStart = product.availabilityStart;
                    dateEnd = product.availabilityEnd;
                }
            })

            if (!found) {
                cartBuff.splice(index, 1);
                return;
            }

            cartBuff[index] = {
                ...cartBuff[index],
                deliveryDate: format(getSelectedDate(cartBuff[index]), "dd.MM.yyyy"),

                wholesaler: profile.wholesaler || null,
                availabilityEnd: (dateEnd || cartBuff[index].availabilityEnd),
                availabilityStart: (dateStart || cartBuff[index].availabilityStart),
                merged: false,
                display: true,
                hidePrice: false,
                selectable: true,

                quantitySelectable: false,
                canSelectWholesaler: true,
                canSelectDeliveryDate: true,
                canPickQuantity: false,
                wholesalers: labels.wholesaler,
            }

        })

        let activeRule = -1
        if (cartBuff.length > 0) {

            let filtered = [];
            for (let ruleIndex = 0; ruleIndex < offerelem.rules.length; ruleIndex++) {
                let value = evaluateRebateRule(offerelem.rules[ruleIndex], cartBuff)
                if (value !== false) {
                    let items = getRuleAdditional(offerelem.rules[ruleIndex]);
                    filtered = getAdditionalItems(items);
                    activeRule = ruleIndex;
                }
            }


            if (Array.isArray(filtered) && filtered.length > 0) {
                filtered.forEach(optionItem => {
                    const product = getProductOfOptionItem(optionItem, oProducts);
                    if (product === undefined) {
                        return null;
                    }

                    const mergeProduct = {
                        ...optionItem,
                        ...product,

                        priceReduction: 0,
                        quantity: optionItem.value,

                        deliveryDate: null,
                        wholesaler: profile.wholesaler || null,
                        merged: true,
                        display: true,
                        selectable: true,

                        quantitySelectable: false,
                        canSelectWholesaler: true,
                        canSelectDeliveryDate: true,
                        canPickQuantity: false,
                        wholesalers: labels.wholesaler,

                    };

                    cartBuff.push(mergeProduct);

                })


            }
        }
        return {cart: cartBuff, activeRule: activeRule}
    }

    const onTypeaheadSelect = (value) => {
        onUpdateProfileValue("locationCode", value);
    }

    const getStartDate = (product) => {

        if (product.availabilityStart === undefined || product.availabilityStart === null) {
            return new Date();
        }

        try {
            let date = parse(product.availabilityStart, "dd.MM.yyyy", new Date());
            let now = new Date();
            if (date < new Date()) {
                return now.setDate(now.getDate() + 5);
            }
            return date;
        } catch (e) {
            return new Date();
        }

    }

    const getSelectedDate = (product) => {

        if (product.deliveryDate === undefined || product.deliveryDate === null) {
            return getStartDate(product);
        }

        try {
            return parse(product.deliveryDate, "dd.MM.yyyy", new Date());
        } catch (e) {
            return getStartDate(product)
        }
    }

    const onTableEntityChange = (index, key, value, isLinkedOffer = false) => {
        if (isLinkedOffer) {
            const cartBuff = [...cartLinkedOffer];
            cartBuff[index][key] = value;
            setCartLinkedOffer(cartBuff);
        } else {
            const cartBuff = [...cart];
            cartBuff[index][key] = value;
            setCart(cartBuff);
        }

    }

    const onUpdateProfileValue = (key, value) => {
        const buff = {...profile}
        buff[key] = value;

        if (key === "wholesaler") {
            const cartBuff = [...cart];
            cartBuff.forEach(product => {
                if (product.type !== "1") {
                    product['wholesaler'] = value;
                }
            })
            setCart(cartBuff);
        }
        setProfile(buff);
    }


    const onOrderSubmit = () => {

        if (!formRef.current) {
            return;
        }

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        let data = {
            type: "prod",
            slug: activeOrder.slug,
            profile: profile
        }

        if (linkedOfferExist && cartLinkedOffer.length > 0) {
            data['linkedOfferCart'] = cartLinkedOffer
        }

        if (cart.length > 0) {
            data['cart'] = cart
        }

        setOnSubmit(true) // disable button and play loading svg

        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/placeOrder/",
            data: data
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setOnSubmit(false)
                return;
            }

            window.localStorage.removeItem("cart")
            setOnSubmit(false)
            navigate("/marketplace/thankyou")


        });


    }

    const customMultiselectDropdownCss = {
        chips: {
            background: '#22004C',
        },
    }

    const onInterestSelect = (selectedItem, selectedList) => {
        let tmpProfile = {...profile}
        tmpProfile['interests'] = selectedList
        setProfile(tmpProfile)
    }

    const updateCartForTable = (cartForTable) => {
        cartForTable.forEach((product) => {
            if (product.type === '1') { // is POSM
                product.canSelectDeliveryDate = false //get delivered with other products
            }
        })

        return cartForTable;
    }

    return (
        <Fragment>

            <Page>
                <TopBar/>
                <Hero heroImage={data.offer.heroImage}/>
                <ContentArea>

                    {hasLoaded &&
                        <div>
                            <h1 className={"text-4xl color-sanofi-dark-lila font-bold mt-8"}>{TranslateText("pos-brand-title")}</h1>
                            <form ref={formRef} className={"mt-8"}>
                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-name-apotheke")}*</label>
                                        </div>
                                        <Input required value={profile.companyName} onChange={(e) => {
                                            onUpdateProfileValue("companyName", e.target.value)
                                        }} type="text"/>
                                    </div>
                                </div>

                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-locationcode")}*</label>
                                        </div>
                                        <Typeahead
                                            selected={profile.locationCode}
                                            onSelect={(value) => {
                                                onTypeaheadSelect(value);
                                            }}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex-1">
                                            <div className={"mb-1.5"}>
                                                <label>{TranslateText("cart-label-street")}*</label>
                                            </div>
                                            <Input value={profile.street} onChange={(e) => {
                                                onUpdateProfileValue("street", e.target.value)
                                            }} required type="text"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-wholesaler")} *</label>
                                        </div>
                                        <Select name={"wholesaler"} onChange={(e) => {
                                            onUpdateProfileValue("wholesaler", e.target.value)
                                        }} value={profile.wholesaler} required>
                                            <option value="" selected>{TranslateText('profile-label-please-select')}</option>
                                            {labels.wholesaler.map((label) => (
                                                <option value={label.id}>{label.name}</option>))}
                                        </Select>
                                    </div>
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-wholesaler-number")}</label>
                                        </div>
                                        <Input value={profile.wholesalerNumber} onChange={(e) => {
                                            onUpdateProfileValue("wholesalerNumber", e.target.value)
                                        }} type="text"/>
                                    </div>
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-kette")}*</label>
                                        </div>
                                        <Select value={profile.chain} onChange={(e) => {
                                            onUpdateProfileValue("chain", e.target.value)
                                        }} required>
                                            <option
                                                value="1">{TranslateText("profile-label-kette-option-none")}</option>
                                            {labels.chain.map((label) => (
                                                <option value={label.id}>{label.name}</option>))}
                                        </Select>
                                    </div>
                                </div>

                                <GLN loginType={profile.loginType} gln={profile.gln}
                                     onValueChange={onUpdateProfileValue}
                                     proPharmaActive={profile.proPharmaActive} proPharmaGLN={profile.proPharmaGLN}
                                     proPharmaToken={profile.proPharmaToken}/>

                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("cart-label-note-wish")}</label>
                                        </div>
                                        <textarea
                                            onChange={(e) => {
                                                onUpdateProfileValue("note", e.target.value)
                                            }}
                                            className="form-control block p-2.5 w-full text-sm text-gray-900 bg-white font-sans rounded-lg border-gray-100 border-l-2 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila "/>
                                    </div>
                                </div>

                                <div>
                                    <TranslateHtml name="cart-after-user-data"/>
                                </div>

                            </form>

                            {linkedOfferExist && cart && cart.length > 0 &&
                                <h2 className={"text-4xl color-sanofi-dark-lila font-bold mb-4 mt-4"}>{offer.title || ""}</h2>
                            }

                            {cart && cart.length > 0 &&
                                <ProductTable
                                    products={updateCartForTable(cart)}
                                    onChange={(index, key, value) => {
                                        onTableEntityChange(index, key, value)
                                    }}
                                />
                            }

                            {linkedOfferExist && cartLinkedOffer && cartLinkedOffer.length > 0 &&
                                <div>
                                    <h2 className={"text-4xl color-sanofi-dark-lila font-bold mb-4 mt-4"}>{linkedOffer.title || ""}</h2>
                                    <ProductTable
                                        products={updateCartForTable(cartLinkedOffer)}
                                        onChange={(index, key, value) => {
                                            onTableEntityChange(index, key, value, true)
                                        }}
                                    />
                                </div>
                            }

                            <div className="w-full justify-center flex lg:justify-end ">

                                <div className="w-full lg:w-2/5">
                                    {(linkedOfferExist) ?
                                        <Conclusionlist rules={offer.rules} activeRule={activeRule} cart={cart}
                                                        offertitle={offer.title}
                                                        hasLinkedOffer={linkedOfferExist}
                                                        rulesLinkedOffer={linkedOffer.rules}
                                                        cartLinkedOffer={cartLinkedOffer}
                                                        offertitleLinkedOffer={linkedOffer.title}
                                                        activeRuleLinkedOffer={activeRuleLinkedOffer}
                                        />
                                        :
                                        <Conclusionlist rules={offer.rules} activeRule={activeRule} cart={cart}
                                                        hasLinkedOffer={linkedOfferExist}
                                        />
                                    }
                                </div>
                            </div>

                            <div className={"mt-8 mb-8 text-center lg:text-right"}>
                                <SanofiButton disabled={(cart.length <= 0 && cartLinkedOffer.length <= 0) || onSubmit}
                                              onClick={(e) => {
                                                  onOrderSubmit()
                                              }}>
                                    {(onSubmit) ?
                                        <div
                                            className="w-5 h-5 border-t-4 border-b-4 border-violet-500 rounded-full animate-spin"></div>
                                        :
                                        <>{TranslateText("pos-cart-button-order")}</>
                                    }

                                </SanofiButton>
                            </div>

                        </div>
                    }

                    {!hasLoaded &&
                        <div className="flex p-8 h-screen items-center justify-center">
                            <div
                                className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                        </div>
                    }

                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>

    )
}