import React, {
    useState,
    createContext,
    useContext,
    useCallback,
    useEffect,
    useRef, Fragment
} from "react"

export const LanguageContext = createContext({
    language: "de",
    entities: {},
    updateLanguage: () => {}
})

export const LanguageProvider = ({ children, fetchTranslations }) => {

    const [{ language, entities }, setLanguage] = useState({
        language: "de",
        entities: {}
    })

	const initialStringsLoaded = useRef(false);

	const updateLanguage = useCallback(
		async (newLang) => {
			if (initialStringsLoaded.current && newLang === language) return;
			const newEntities = await fetchTranslations({ language: newLang });
			initialStringsLoaded.current = true;
			setLanguage({
				language: newLang,
				entities: newEntities,
			});
		},
		[language, fetchTranslations]
	);

	useEffect(() => {
		updateLanguage(language);
	}, [language, updateLanguage]);

	const context = {
		language,
		entities,
		updateLanguage: updateLanguage,
	};

	return (
		<LanguageContext.Provider value={context}>
			{children}
		</LanguageContext.Provider>
	);
};

// function to be called each time
// will handle html and simple text, please be aware that html will be rendered inside a fragment
export const TranslateText = (children) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[children] || children;
    if (lEntity === undefined) { return children; }
    return lEntity.content;
}

export const getLanguageContent = (children, defaultValue) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[children];// || children;
    if (lEntity === undefined) { return defaultValue; }
    return lEntity.content;
}

export const TranslateHtml = ({name}) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[name] || name;
    if (lEntity === undefined) { return name; }
    return (<div dangerouslySetInnerHTML={{__html: lEntity.content}}/>)

}

export const Translate = ({children}) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[children] || children;
    if (lEntity === undefined) { return children; }
    return (<div dangerouslySetInnerHTML={{__html: lEntity.content}}/>)

}
