import React, {useEffect, useState, Fragment, useCallback} from "react";
import {TranslateText} from "../../libs/LanguageProvider";
import SucHelper from "../../../../../core/react/src/helpers/General";
import Select from "../Form/Select";
import DatePicker, {registerLocale} from "react-datepicker";
import de from "date-fns/locale/de"; // the locale you want
import it from "date-fns/locale/it"; // the locale you want
import fr from "date-fns/locale/fr"; // the locale you want
import {parse, format} from 'date-fns';
import {getUserLanguages} from "../../helper/User";
import OfferButton from "../OfferTable/OfferButton";


registerLocale("de", de);
registerLocale("it", it); // register it with the name you want
registerLocale("fr", fr); // register it with the name you want

export default function ProductTable(props) {

    const renderQuantity = (maxQuantity) => {

        if (SucHelper.isNullUndefinedOrEmpty(maxQuantity)) {
            maxQuantity = 10;
        }

        let quantities = [];

        for (let i = 0; i < maxQuantity; i++) {
            quantities.push({
                id: i,
                value: i
            })
        }

        return quantities;

    }

    const renderInfo = (product) => {

        if (SucHelper.isNullUndefinedOrEmpty(product.dosage) && SucHelper.isNullUndefinedOrEmpty(product.amount)) {
            return "";
        }

        if (!SucHelper.isNullUndefinedOrEmpty(product.dosage) && !SucHelper.isNullUndefinedOrEmpty(product.amount)) {
            return product.dosage + " / " + product.amount || ""
        }

        return (product.dosage || "") + (product.amount || "")

    }

    const renderPrice = (product) => {

        if (product.price == 0 || product.price === undefined) {
            return null;
        }


        if (product.priceReduction !== undefined) {
            return (
                <>
                    <span
                        className={"line-through"}>{parseFloat(product.price).toFixed(2)} {TranslateText("offer-label-currency")}
                    </span>
                    <br/>
                    <span
                        className={"font-bold"}>{parseFloat(product.priceReduction).toFixed(2)} {TranslateText("offer-label-currency")}
                    </span>
                </>
            )
        }

        return <span>{parseFloat(product.price).toFixed(2)} {TranslateText("offer-label-currency")}</span>;

    }

    const propagateUpdate = (index, key, value) => {
        if (props.onChange) {
            props.onChange(index, key, value)
        }
    }

    const getStartDate = (product) => {

        if (product.availabilityStart === undefined || product.availabilityStart === null) {
            return new Date();
        }

        try {
            let date = parse(product.availabilityStart, "dd.MM.yyyy", new Date());
            let now = new Date();
            if (date < new Date()) {
                return now.setDate(now.getDate() + 5);
            }
            return date;
        } catch (e) {
            return new Date();
        }

    }

    const getEndDate = (product) => {

        if (product.availabilityEnd === undefined || product.availabilityEnd === null) {
            let date = getStartDate(product);
            if (date < new Date()) {
                return date.setDate(date.getDate() + 5);
            }
        }
        try {
            let date = parse(product.availabilityEnd, "dd.MM.yyyy", new Date());
            let now = new Date();
            if (date < new Date()) {
                return now.setDate(now.getDate() + 5);
            }
            return date;
        } catch (e) {
            return new Date();
        }
    }

    const onDateChange = (index, key, date) => {

        let value = null;

        if (date instanceof Date) {
            value = format(date, "dd.MM.yyyy")
        }

        propagateUpdate(index, key, value);

    }

    const getSelectedDate = (product) => {

        if (product.deliveryDate === undefined || product.deliveryDate === null) {
            return getStartDate(product);
        }

        try {
            return parse(product.deliveryDate, "dd.MM.yyyy", new Date());
        } catch (e) {
            return getStartDate(product)
        }
    }

    //console.log('Producttable - Cart', props.products)

    return (

        <Fragment>
            <div className="hidden lg:block">
                <div className="border-b-2 sanofi-table-border-radius">
                    <table className="w-full text-left text-gray-500 dark:text-gray-400 table-auto">
                        <thead
                            className="text-white rounded-lg bg-sanofi-table-head-bg-default offer-product-table-header">
                        <tr>
                            <th scope="col" className="px-6 py-3"></th>
                            <th scope="col" className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                {TranslateText("offer-table-header-lable-article")}
                            </th>
                            {(props.hidePrice === undefined || props.hidePrice === "false") &&
                                <th scope="col"
                                    className="px-6 py-3 text-center text-sanofi-white offer-product-table-text">
                                    {TranslateText("offer-table-header-lable-price")}
                                </th>
                            }
                            <th scope="col"
                                className="px-6 py-3 text-center text-sanofi-white offer-product-table-text">
                                {TranslateText("offer-table-header-lable-quantity")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.products.map((product, index) => {

                            if (product.selectable !== true) {
                                return null
                            }

                            return (
                                <Fragment key={product.id + "_" + index}>
                                    <tr className={"border-l-sanofi-table-body border-x-2 " + ((index % 2) == 0 ? "bg-white" : "bg-sanofi-table-body")}>
                                        <th className="content-center text-center p-10">
                                            <img className="offer-table-img inline-block" alt={product.title_de}
                                                 src={product.picture}/>
                                        </th>
                                        <td className="px-6 py-4">
                                            <strong>{product.title}</strong>
                                            <br/>
                                            {product.pharmaNumber} <br/>
                                            {renderInfo(product)}
                                        </td>

                                        {(props.hidePrice === undefined || props.hidePrice === "false") &&
                                            <td className="px-6 py-4 text-center ">
                                                {renderPrice(product)}
                                            </td>
                                        }

                                        <td className=" text-center">

                                            {(product.quantitySelectable === true && product.quantity !== undefined) &&
                                                <div className="mr-4">
                                                    <Select value={parseInt(product.quantity, 10).toString()}
                                                            onChange={(e) => propagateUpdate(index, "quantity", e.target.value)}>
                                                        <option value={""}></option>
                                                        {renderQuantity(product.maxOrderQuantity).map(q => {
                                                            return <option value={q.id}>{q.value}</option>
                                                        })}
                                                    </Select>
                                                </div>
                                            }

                                            {((product.quantitySelectable === false && product.canPickQuantity === false) && product.quantity !== undefined) &&
                                                <div>
                                                    {parseInt(product.quantity, 10)} {TranslateText("offer-cart-label-pieces")}
                                                </div>
                                            }

                                            {product.canPickQuantity === true &&
                                                <div>
                                                    <OfferButton
                                                        maxOrderQuantity={product.maxOrderQuantity}
                                                        quantity={product.quantity}
                                                        onValueChange={(value) => propagateUpdate(index, "quantity", value)}
                                                    />
                                                </div>
                                            }

                                        </td>
                                    </tr>

                                    {(product.canSelectWholesaler === true || product.canSelectDeliveryDate === true) &&
                                        <tr className={"border-l-sanofi-table-body border-x-2 " + ((index % 2) == 0 ? "bg-white" : "bg-sanofi-table-body")}>

                                            <td colSpan={4} className="">

                                                <div className="flex items-center justify-evenly mb-4">

                                                    {(product.canSelectWholesaler === true && product.type !== "1") &&
                                                        <div>
                                                            <label
                                                                className="mb-2 inline-block">{TranslateText("cart-label-wholesaler")}: </label>
                                                            <Select value={product.wholesaler}
                                                                    onChange={(e) => propagateUpdate(index, "wholesaler", e.target.value)}>
                                                                <option value="" selected>{TranslateText('profile-label-please-select')}</option>
                                                                {product.wholesalers.map((entity) => (
                                                                    <option value={entity.id}>{entity.name}</option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    }

                                                    {product.canSelectDeliveryDate === true &&
                                                        <div>
                                                            <label
                                                                className="mb-2 inline-block">{TranslateText("cart-label-wish-delivery-date")}:</label>
                                                            <DatePicker
                                                                className={"form-block p-2.5 w-full text-sm text-gray-900 bg-white font-sans rounded-lg border-gray-100 border-l-2 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila"}
                                                                minDate={getStartDate(product)}
                                                                locale={getUserLanguages()}
                                                                maxDate={getEndDate(product)}
                                                                dateFormat="dd.MM.yyyy"
                                                                selected={getSelectedDate(product)}
                                                                onChange={(date) => onDateChange(index, "deliveryDate", date)}
                                                            />

                                                        </div>
                                                    }

                                                </div>

                                            </td>

                                        </tr>
                                    }

                                </Fragment>

                            )

                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="block lg:hidden border-2 border-sanofi-table-body rounded-lg">

                {props.products.map((product, index) => {

                    if (product.selectable !== true) {
                        return null
                    }

                    return (

                        <div key={product.id + "_" + index}
                             className="p-10 border-b odd:bg-white even:bg-sanofi-table-body">

                            <div>
                                <h2 className="text-2xl font-bold p-2 mb-4 text-center">{product.title}</h2>
                            </div>

                            <div className="flex justify-around items-center space-x-5">
                                <div>
                                    <img className="offer-table-img inline-block" alt={product.title_de}
                                         src={product.picture}/>
                                </div>
                                <div className={" text-center"}>
                                    {product.pharmaNumber}
                                    <p>
                                        {renderInfo(product)}
                                    </p>

                                    <br/>

                                    {(props.hidePrice === undefined || props.hidePrice === "false") &&
                                        <p>
                                            {renderPrice(product)}
                                        </p>
                                    }

                                    {(product.quantitySelectable === true && product.quantity !== undefined) &&
                                        <Select defaultValue={parseInt(product.quantity, 10).toString()}
                                                onChange={(e) => propagateUpdate(index, "quantity", e.target.value)}>
                                            <option value={""}></option>
                                            {renderQuantity(product.maxOrderQuantity).map(q => {
                                                return <option value={q.id}>{q.value}</option>
                                            })}
                                        </Select>
                                    }

                                    {((product.quantitySelectable === false && product.canPickQuantity == false) && product.quantity !== undefined) &&
                                        <div>
                                            {parseInt(product.quantity, 10)} {TranslateText("offer-cart-label-pieces")}
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className="flex-col items-center justify-evenly mb-4">


                                {(product.canSelectWholesaler === true || product.canSelectDeliveryDate === true) &&

                                    <>

                                        {(product.canSelectWholesaler === true && product.type !== "1") &&
                                            <div>
                                                <label
                                                    className="mb-2 inline-block">{TranslateText("cart-label-wholesaler")}: </label>
                                                <Select value={product.wholesaler}
                                                        onChange={(e) => propagateUpdate(index, "wholesaler", e.target.value)}>
                                                     <option value="" selected>{TranslateText('profile-label-please-select')}</option>
                                                    {product.wholesalers.map((entity) => (
                                                        <option value={entity.id}>{entity.name}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        }

                                        {product.canSelectDeliveryDate === true &&

                                            <div>
                                                <label
                                                    className="mb-2 inline-block">{TranslateText("cart-label-wish-delivery-date")}:</label>
                                                <DatePicker
                                                    className={"form-block p-2.5 w-full text-sm text-gray-900 bg-white font-sans rounded-lg border-gray-100 border-l-2 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila"}
                                                    minDate={getStartDate(product)}
                                                    locale={getUserLanguages()}
                                                    maxDate={getEndDate(product)}
                                                    dateFormat="dd.MM.yyyy"
                                                    selected={getSelectedDate(product)}
                                                    onChange={(date) => onDateChange(index, "deliveryDate", date)}
                                                />
                                            </div>
                                        }

                                    </>

                                }


                                {product.canPickQuantity === true &&
                                    <div className="flex flex-col items-center mt-4">
                                        <OfferButton
                                            maxOrderQuantity={product.maxOrderQuantity}
                                            quantity={product.quantity}
                                            onValueChange={(value) => propagateUpdate(index, "quantity", value)}
                                        />
                                    </div>
                                }


                            </div>

                        </div>

                    )

                })}


            </div>
        </Fragment>

    )


}