import React from "react";
import BoxWrapper from "./BoxWrapper";
import RegistrationForm from "./registration/RegistrationForm";
import clsx from "clsx";

export default function RegistrationBox(props) {
    return(
        <BoxWrapper id={"registration-box"}>
            <div className="box-basic">
                <div
                    className={clsx("loginscreen-header", 'login-mb-1', 'fontRalewayRegular')}>
                    <a>
                        <img class="h-16 mx-auto" src="/design/frontend/img/sanoficonnect_logo_x2.png" alt="sanofi Connect"/>
                    </a>
                </div>

                <RegistrationForm
                    language={props.language}
                    swissRx={props.swissRx}
                    showLoginForm={props.showLoginForm}
                />
            </div>
        </BoxWrapper>
    );
}