import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import axios from "axios";
import {useModal} from "../../../libs/ModalProvider";
import SanofiButton from "../../../components/SanofiButton";
import {TranslateText} from "../../../libs/LanguageProvider";


export default function OfferNotice(props) {

    const [data, setData] = useState(props.data)
    const [hasLoaded, setHasLoaded] = useState(true)
    const { setModal, closeModal } = useModal()

    const doConfirm = () => {
        if (data.doConfirm) {
            closeModal();
            data.doConfirm();
        }
    }

    return (

        <Fragment>

            {hasLoaded &&
                <div className={"flex items-center justify-center flex-col "}>
                    <h2 className={"text-4xl color-sanofi-dark-lila font-bold text-center"}>{data.modalTitle || ""}</h2>

                    <div className={"mt-8 text-center w-128"}>

                        <div className={"mt-4 mb-4 block"}>
                            {data.content}
                        </div>

                        <div className={"mt-4 mt-8 block"}>
                            <SanofiButton onClick={(e) => {doConfirm()}}>
                                {data.buttonLabel }
                            </SanofiButton>
                        </div>

                        <dic className={"mt-4 mb-4 block"} onClick={(e) => {closeModal()}}>
                            <span className={"cursor-pointer underline text-sanofi-font-default"}> {data.buttonCancelLabel}</span>
                        </dic>


                    </div>

                </div>
            }

            {!hasLoaded &&
                <div className="flex p-8 items-center justify-center">
                    <div className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                </div>
            }

        </Fragment>

    )
}