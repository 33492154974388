import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import axios from "axios";
import {TranslateHtml, TranslateText} from "../../libs/LanguageProvider";
import OfferItem from "./OfferItem";
import UpcomingOfferItem from "./UpcomingOffers";


export default function OfferOverview(props) {


    const [offers, setOffers] = useState([])
    const [upcomingOffers, setUpcomingOffers] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [labels, setLabels] = useState(false)

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/fetchOffers",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setErrorState(true);
                return;
            }

            const offerData = data.data.data
            if (offerData && offerData.hasOwnProperty('offers')) {
                // offerlist
                const buff = [...data.data.data.offers];
                setOffers(buff);
            }

            if (offerData.hasOwnProperty('upcomingOffers')) {

                // upcoming offerlist
                const buffUpcoming = [...data.data.data.upcomingOffers];
                setUpcomingOffers(buffUpcoming)


            }

            setHasLoaded(true);

        });


    }, [])
    
    return (

        <Fragment>

            <div className="px-4">
                <h1 className={"text-4xl color-sanofi-dark-lila font-bold"}>{TranslateText("home-title")}</h1>
                <div>
                    <TranslateHtml name={"home-content-top"}/>
                </div>
            </div>

            {hasLoaded &&
                <div className={"flex flex-col items-stretch lg:flex-row flex-wrap mb-12"}>

                    {offers.map((offer) => {

                        return (
                            <OfferItem offer={offer}/>
                        )

                    })}

                </div>
            }

            {!hasLoaded &&
                <div className="flex h-screen items-center justify-center">
                    <div className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                </div>
            }

            {hasLoaded && upcomingOffers.length > 0 && props.showUpcomingOffer &&
                <div className="px-4">
                    <h2 className={"text-4xl color-sanofi-dark-lila font-bold"}>{TranslateText("home-title-upcoming-offer")}</h2>
                    <div>
                        <TranslateHtml name={"home-content-upcoming-offer"}/>
                    </div>
                </div>
            }

            {hasLoaded && upcomingOffers.length > 0 && props.showUpcomingOffer &&
                <div className={"flex flex-col items-stretch lg:flex-row flex-wrap mb-12"}>

                    {upcomingOffers.map((upcomiupcomingOffer) => {

                        return (
                            <UpcomingOfferItem offer={upcomiupcomingOffer}/>
                        )

                    })}

                </div>
            }

            <div className="px-4">
                <div>
                    <TranslateHtml name={"home-content-bottom"}/>
                </div>
            </div>


        </Fragment>

    )
}