import React, { Fragment, useEffect, useState } from 'react'
import {getPaddingStyles, generateCSSString } from '../../../../../helpers/style';
import { userComponentAccess } from '../../../../../helpers/user';

function getRelativePath(url){
    url = url?.toLowerCase() ?? '';
    let locationOrigin = window.location.origin.toLowerCase();
    if(url.includes(locationOrigin))
        return url.split(locationOrigin).pop();
    return url;
}

function comparePaths(navLink, pageLink){
    navLink = navLink.split("/");
    pageLink = pageLink.split("/");

    for(let i=0;  i < navLink.length; i++)
        if(navLink[i] !== pageLink[i]) return false;
    
    return true;
}


export default function MenuLink(props){
    const component = props.component;
    const link = getRelativePath(component.link_); 
    const isActive =  comparePaths(link,  getRelativePath(props.pageURL) ?? window.location.pathname);

    const [backgroundColor, setBackgroundColor] = useState(isActive? component.linkHoverBackground_color: component.linkBackground_color);
    const [textColor, setTextColor] = useState(isActive? component.linkHoverText_color : component.linkText_color);

    const setHoverCssStyles = () => {
        setBackgroundColor(component.linkHoverBackground_color);
        setTextColor(component.linkHoverText_color);
    }

    const resetCssStyles = () => {
        setBackgroundColor(component.linkBackground_color);
        setTextColor(component.linkText_color);
    }

    useEffect(() => {
            if(isActive){
                setBackgroundColor(component.linkHoverBackground_color);
                setTextColor(component.linkHoverText_color);
            }else{
                setBackgroundColor(component.linkBackground_color);
                setTextColor(component.linkText_color);
            } 
    }, [component.linkBackground_color, component.linkText_color, component.linkHoverBackground_color, component.linkHoverText_color])

    const cssValue = [ ...getPaddingStyles('link'), 'link_fontSizeText',  'link_textTransform', 'link_borderRadius'];

    return (
        <Fragment>
            {   
                userComponentAccess(component.roleId_, props.previewPageRoleId) &&
                <li className='list-none'>
                    {   component.link_linkType === 'button' ?
                        <a 
                            className={`block ${generateCSSString(component,cssValue)}`}
                            href={component.link_}
                            target={component.link_target}
                            style={{ 
                                    backgroundColor: backgroundColor ,
                                    color: textColor
                                }}
                            onMouseEnter={() => setHoverCssStyles()}
                            onMouseLeave={() => !isActive? resetCssStyles() : null}
                            dangerouslySetInnerHTML={{__html: component.label_}}
                        />
                        :
                        <a 
                            className={`block ${generateCSSString(component,cssValue)} ${isActive ? 'nav-link-active' : 'nav-link'}`}
                            href={component.link_}
                            target={component.link_target}
                            style={{color: textColor}}
                            onMouseEnter={() => setTextColor(component.linkHoverText_color)}
                            onMouseLeave={() => !isActive? setTextColor(component.linkText_color) : null} 
                            dangerouslySetInnerHTML={{__html: component.label_}}
                        />
                    }
                </li>
            }
        </Fragment>
    )

}