import React, {useEffect, useState, Fragment, useCallback} from "react";
import Menu from "../Menu";
import {getSubURL} from "../../../helper/User";

export default function Hero(props) {

    const pagetype = props.type ? props.type : getSubURL();

    const getHeaderImage = () => {
        if (props.headerType === 0) {
            return "";

        }
        if (props.headerType === 2) {
            return props.heroImage;
        }

        switch (pagetype) {
            case 'marketplace':
                return "/design/heros/marketplace-header.jpg";
            case 'university':
                return "/design/heros/university/university-home-header.jpg";
            case 'sanoficonnect':
                return "";
            default:
                return "/design/heros/marketplace-header.jpg"

        }
    }

    return (

        <section className="hero">

            {getHeaderImage() && <img src={getHeaderImage()} alt={getSubURL()} className="w-full"/>}

            {props.menuType !== 0 &&
                <div className="w-hero-container hidden md:block"
                     style={{marginTop: `${getHeaderImage() === "" ? '0px' : ''}`}}>
                    {props.menuType !== -1 &&
                        <Menu className="" back={props.back} backTitle={props.backTitle}
                              menueItems={props.menuType === 2 ? props.menuItems : null}
                              isCustom={props.menuType === 2}
                              type={pagetype}
                        />}
                </div>}

        </section>

    )


}