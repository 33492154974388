import React, { Fragment, useState, useEffect } from 'react';
import SucIcon from '../../../../../../../../../core/react/src/components/icons/SucIcon';
import { screens } from "../../../../../../helpers/responsive";
import { TranslateText } from '../../../../../../helpers/language';
import SucButton from '../../../../../../components/SucButton';
import { getPaddingStyles, getResponsiveStyles, generateCSSString } from '../../../../helpers/style';
import { userComponentAccess } from '../../../../helpers/user';
import MenuLink from './components/MenuLink';


export default function Menu(props){

    const {component, draggable, ...rest} = props;

    const backend = props.backend ?? false;

    const mobileSize = screens.sm;
    const tabletSize = screens.lg;

    const [device, setDevice] = useState(window.innerWidth > tabletSize ? 'desktop' : 'mobile');
    const [menuOpen, setMenuOpen] = useState(false);

    const cssValue = [  ...getPaddingStyles('menu'), 
                        ...getResponsiveStyles('menu', 'JustifyContent'), 
                        ...getResponsiveStyles('menu', 'AlignItems')
                    ];

    useEffect(() => {

        const handleResize = () => {
            
            if(window.innerWidth <= tabletSize) return setDevice('mobile');
            return setDevice('desktop');
        }
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    
    return(
        <Fragment>
        {
            (backend || (component.hidden_ != 1 && (component.menu_menuDisplay === 'all' || component.menu_menuDisplay == device))) &&  

              ( device === 'desktop' ? //( component.menuStyle === 'desktop'  || (component.menuStyle === 'responsive' && device === 'desktop')
                    
                    <div className='flex items-center'>

                        {
                            component.image_ &&
                            <div className='py-3 shrink-0'>
                                <a href={component.imageLink_ ?? '/'}>
                                    <img class="h-12" src={component.image_} alt="Logo"></img>
                                </a>
                            </div>
                        }

                        {
                            userComponentAccess(component.roleId_, props.previewPageRoleId) && 
                            <ul className={`cms_nav overflow-hidden w-full flex flex-wrap ${generateCSSString(component, cssValue)}`}
                                style={{ backgroundColor: component.menuBackground_color }}>
                                {
                                    component.children && component.children.map((child) => {
                                        if(child.deleted) 
                                            return <></>
                                    else 
                                            return(
                                                <MenuLink component={{
                                                    ...child,
                                                    target: backend ? '_blank' : child.target,
                                                    link_linkType: component.link_linkType,
                                                    link_textTransform: component.link_textTransform,
                                                    link_fontSizeText: component.link_fontSizeText,
                                                    linkText_color: component.linkText_color,
                                                    linkHoverText_color: component.linkHoverText_color,
                                                    linkBackground_color: component.linkBackground_color,
                                                    linkHoverBackground_color: component.linkHoverBackground_color,
                                                    link_borderRadius: component.link_borderRadius,
                                                    link_desktopPaddingTop: component.link_desktopPaddingTop,
                                                    link_desktopPaddingBottom: component.link_desktopPaddingBottom,
                                                    link_desktopPaddingRight: component.link_desktopPaddingRight,
                                                    link_desktopPaddingLeft: component.link_desktopPaddingLeft,
                                                    link_tabletPaddingTop: component.link_tabletPaddingTop,
                                                    link_tabletPaddingBottom: component.link_tabletPaddingBottom,
                                                    link_tabletPaddingRight: component.link_tabletPaddingRight,
                                                    link_tabletPaddingLeft: component.link_tabletPaddingLeft,
                                                    link_mobilePaddingTop: component.link_mobilePaddingTop,
                                                    link_mobilePaddingBottom: component.link_mobilePaddingBottom,
                                                    link_mobilePaddingRight: component.link_mobilePaddingRight,
                                                    link_mobilePaddingLeft: component.link_mobilePaddingLeft,
                                                    }}
                                                    {...rest}
                                                />
                                        )
                                    }

                                    )
                                }
                            </ul>
                        }

                    </div>
        
                    :
                    <Fragment>
                        <div className='flex items-center'>
                            
                            {
                                component.image_ &&
                                <div className='py-3 shrink-0'>
                                    <a href={component.imageLink_ ?? '/'}>
                                        <img class="h-12" src={component.image_} alt="Logo"></img>
                                    </a>
                                </div>
                            }
                            {
                            userComponentAccess(component.roleId_, props.previewPageRoleId) && 
                            <div className={`cms_nav grow overflow-hidden flex justify-end ${generateCSSString(component, cssValue)}`}> 
                                <SucButton
                                    title={TranslateText('menuEntries')}
                                    className='btn w-fit'
                                    children={menuOpen ? <SucIcon icon='close' classes='md-18 not-italic' /> : <SucIcon icon='menu' classes='md-18 not-italic' />}
                                    onClick={() =>  setMenuOpen(!menuOpen)}
                                    />
                            </div>
                            }
                        </div>
            
                        {
                            userComponentAccess(component.roleId_, props.previewPageRoleId) && menuOpen && 
                            <div className={`w-full justify-self-center pb-3 mt-2`}>
                                <ul className="justify-center items-center pl-2" style={{ backgroundColor: component.menuBackgroundColor }}>
                                    {
                                            component.children && component.children.map((child) => 
                                                <MenuLink component={{
                                                    ...child,
                                                    target: backend ? '_blank' : child.target,
                                                    link_linkType: component.link_linkType,
                                                    link_textTransform: component.link_textTransform,
                                                    link_fontSizeText: component.link_fontSizeText,
                                                    linkText_color: component.linkText_color,
                                                    linkHoverText_color: component.linkHoverText_color,
                                                    linkBackground_color: component.linkBackground_color,
                                                    linkHoverBackground_color: component.linkHoverBackground_color,
                                                    link_borderRadius: component.link_borderRadius,
                                                    link_desktopPaddingTop: component.link_desktopPaddingTop,
                                                    link_desktopPaddingBottom: component.link_desktopPaddingBottom,
                                                    link_desktopPaddingRight: component.link_desktopPaddingRight,
                                                    link_desktopPaddingLeft: component.link_desktopPaddingLeft,
                                                    link_tabletPaddingTop: component.link_tabletPaddingTop,
                                                    link_tabletPaddingBottom: component.link_tabletPaddingBottom,
                                                    link_tabletPaddingRight: component.link_tabletPaddingRight,
                                                    link_tabletPaddingLeft: component.link_tabletPaddingLeft,
                                                    link_mobilePaddingTop: component.link_mobilePaddingTop,
                                                    link_mobilePaddingBottom: component.link_mobilePaddingBottom,
                                                    link_mobilePaddingRight: component.link_mobilePaddingRight,
                                                    link_mobilePaddingLeft: component.link_mobilePaddingLeft,
                                                    }}
                                                    {...rest}
                                                />

                                            )
                                        }
                                </ul>
                            </div>
                        }
                    </Fragment>
        

        )}   
        </Fragment>
        )

}