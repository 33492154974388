import React, {useState, useEffect, Fragment} from 'react';
import {useSpring, a} from '@react-spring/web'
import LoginBox from './views/LoginBox'
import RegistrationBox from "./views/RegistrationBox";
import PasswordResetBox from "./views/PasswordResetBox";
import PasswordNewBox from "./views/PasswordNewBox";
import AccountVerifyBox from "./views/AccountVerifyBox";
// import LoginForm from './views/LoginForm'
import SecondDefaultButton from "../../elements/Button/SecondDefaultButton";
import './Logindesign.css'
import Collapse from 'react-bootstrap/Collapse'
import clsx from "clsx";
// import LoginSlider from "./views/LoginSlider"
import DefaultButton from "../../elements/Button/DefaultButton";
import CustomInput from "../../elements/Input/CustomInput";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import {LanguageProvider, TranslateText, getLanguageContent} from "../../libs/LanguageProvider";
import {getUserLanguages} from "../../helper/User";
import {BrowserRouter} from "react-router-dom";
import {ModalProvider} from "../../libs/ModalProvider";
import App from "../../App";

const ActiveView = {
    LOGIN: "login",
    REGISTRATION: 'register',
    PASSWORDRESET: 'PASSWORDRESET',
    PASSWORDNEW: "NEWPASSWORD",
    ACCOUNTVERIFY: "ACCOUNTVERIFY",
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const view = urlParams.get('view'); // for initial view
const verificationToken = urlParams.get('verificationToken'); // for password reset
const token = urlParams.get('token'); // for password reset
const swissRx = urlParams.get('swissRx'); // for swissRx registration

const prepareInitialView = () => {
    if (verificationToken) {
        return ActiveView.ACCOUNTVERIFY;
    } else if (token) {
        return ActiveView.PASSWORDNEW;
    } else if (view == 'register' || swissRx) {
        return ActiveView.REGISTRATION;
    } else if (view == 'PASSWORDRESET' && token) {
        return ActiveView.PASSWORDRESET;
    } else {
        return ActiveView.LOGIN;
    }
}

export const fetchTranslations = ({language = "de"}) =>
    axios.get("/design/frontend/language/" + getUserLanguages() + ".json")
        .then((data) => {
            return data.data;
        });
/*
    The intital Login Screen - bundle all components for the Login Page
    props:
        lang: language shortcut of the Browser
        loginform: path for loginform action
        sliderdata: JSON Object with Object of each Slider (as String)
 */
const Loginscreen = (props) => {
    const [loginlanguagecomponent, setLoginlanguagecomponent] = useState({})
    const [nutzungsbedingungUrl, setnutzungsbedingungUrl] = useState('')

    const [currentView, setCurrentView] = useState(prepareInitialView());

    const {
        transform,
        loginOpacity,
        registerOpacity,
        passwordResetOpacity,
        passwordNewOpacity,
        accountVerifyOpacity
    } = useSpring({
        loginOpacity: currentView == ActiveView.LOGIN ? 1 : 0,
        registerOpacity: currentView == ActiveView.REGISTRATION ? 1 : 0,
        passwordResetOpacity: currentView == ActiveView.PASSWORDRESET ? 1 : 0,
        passwordNewOpacity: currentView == ActiveView.PASSWORDNEW ? 1 : 0,
        accountVerifyOpacity: currentView == ActiveView.ACCOUNTVERIFY ? 1 : 0,
        transform: `perspective(3000px) rotateY(${currentView == ActiveView.LOGIN ? 0 : -180}deg)`,
        config: {mass: 5, tension: 500, friction: 80},
    })

    /*Called by initialization*/
    useEffect(() => {
        const langobj = [];
        let langmap = {};
        langobj.forEach((obj) => {
            langmap[obj.description] = obj.content;
        })

        setLoginlanguagecomponent(langmap);

        switch (props.lang) {
            case 'fr':
                setnutzungsbedingungUrl('https://marketplace.sanoficonnect.ch/fr/mentions-legales/')
                break;
            case 'it':
                setnutzungsbedingungUrl('https://marketplace.sanoficonnect.ch/it/menzioni-legali/')
                break;
            // German is default
            default:
                setnutzungsbedingungUrl('https://marketplace.sanoficonnect.ch/de/allgemeine-nutzungsbedingungen/')
        }
    }, []);

    const showLoginForm = () => {
        setCurrentView(ActiveView.LOGIN);
    }

    const showRegistrationForm = () => {
        setCurrentView(ActiveView.REGISTRATION);
    }

    const showPasswordResetForm = () => {
        setCurrentView(ActiveView.PASSWORDRESET);
    }

    return (
        <div className="login-content-container">
            <div className="loginbg ">
                <div className=''>
                    <a href="/home"><img className='sanofi-logo' src='design/svg/sanofi-logo-2022.svg'/></a>

                    <a.div
                        className="c"
                        style={{
                            opacity: loginOpacity.to(o => (o > 0.5) ? o / 0.5 : 0),
                            display: loginOpacity.to(o => (o > 0.5) ? 'block' : 'none'),
                            transform,
                        }}
                    >
                        <LoginBox
                            loginForm={props.loginform}
                            sliderData={props.sliderdata}
                            supportSwissRx={props.supportSwissRx}
                            swissRxPath={props.swissRxPath}
                            frontendAllowRegistration={props.frontendAllowRegistration}
                            showRegistrationForm={showRegistrationForm}
                            showPasswortResetForm={showPasswordResetForm}
                        />
                    </a.div>

                    {props.frontendAllowRegistration &&
                        <a.div
                            className="c register-component"
                            style={{
                                transform,
                                rotateY: '-180deg',
                            }}
                        >
                            <a.div
                                style={{
                                    opacity: registerOpacity.to(o => (o > 0.5) ? o / 0.5 : 0),
                                    display: registerOpacity.to(o => (o > 0.5) ? 'block' : 'none'),
                                }}>
                                <RegistrationBox
                                    language={props.lang}
                                    swissRx={swissRx}
                                    showLoginForm={showLoginForm}
                                />
                            </a.div>
                        </a.div>
                    }

                    <a.div
                        className="c pw-reset-component"
                        style={{
                            transform,
                            rotateY: '-180deg',
                        }}
                    >
                        <a.div
                            style={{
                                opacity: passwordResetOpacity.to(o => (o > 0.5) ? o / 0.5 : 0),
                                display: passwordResetOpacity.to(o => (o > 0.5) ? 'block' : 'none'),
                            }}>
                            <PasswordResetBox
                                lang={props.lang}
                                showLoginForm={showLoginForm}
                            />
                        </a.div>
                    </a.div>

                    <a.div
                        className="c pw-new-component"
                        style={{
                            transform,
                            rotateY: '-180deg',
                        }}
                    >
                        <a.div
                            style={{
                                opacity: passwordNewOpacity.to(o => (o > 0.5) ? o / 0.5 : 0),
                                display: passwordNewOpacity.to(o => (o > 0.5) ? 'block' : 'none'),
                            }}>
                            <PasswordNewBox
                                token={token}
                                lang={props.lang}
                                showLoginForm={showLoginForm}
                                runTokenCheck={currentView == ActiveView.PASSWORDNEW}
                            />
                        </a.div>
                    </a.div>

                    <a.div
                        className="c account-verify-component"
                        style={{
                            transform,
                            rotateY: '-180deg',
                        }}
                    >
                        <a.div
                            style={{
                                opacity: accountVerifyOpacity.to(o => (o > 0.5) ? o / 0.5 : 0),
                                display: accountVerifyOpacity.to(o => (o > 0.5) ? 'block' : 'none'),
                            }}>
                            <AccountVerifyBox
                                verificationToken={verificationToken}
                                lang={props.lang}
                                runTokenCheck={currentView == ActiveView.ACCOUNTVERIFY}
                            />
                        </a.div>
                    </a.div>
                </div>
            </div>
            <footer className="w-full pb-1 pr-3 login-footer">
                <a className="nutzungsbedingungen mr-5" href={nutzungsbedingungUrl}>
                    {getLanguageContent("nutzungsbedingungen", "Nutzungsbedingungen")}
                </a>
                <a className="datenschutz"
                   href={getLanguageContent("link-href-dataPrivacy", "https://marketplace.sanoficonnect.ch/datenschutzhinweise")}>
                    {getLanguageContent("menu-footer-dataPrivacy", "Datenschutz")}
                </a>
            </footer>
        </div>
    )
}

export default Loginscreen;