import React, {useState, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faTimes} from "@fortawesome/free-solid-svg-icons";
import CustomSlider from "../Slider/custom.slider";
import PDBProductDetailsVariant from "./PDBProductDetailsVariant";
import {getLanguageContent} from "../../../libs/LanguageProvider";

export default function PDBProductDetails(props) {

    const [selectedProduct, setSelectedProduct] = useState(props.product);

    const overlayRef = useRef();
    const overlayImgRef = useRef();

    const showPreview = (imgSrc) => {
        if (imgSrc != false) {
            overlayImgRef.current.src = imgSrc;
            overlayRef.current?.classList.add('active');
        }
    }

    const closePreview = () => {
        overlayRef.current?.classList.remove('active');
    }

    // const goBack = () => {
        // navigate('/pdb/product/' + (preview ? 'preview' : 'live') + '/' + selectedProduct.brand);
    // }

    return (
        <>
            {selectedProduct &&
                <>
                    <div ref={overlayRef} id="preview-overlay" onClick={() => {closePreview()}}>
                        <div className="image-wrapper">
                            <img ref={overlayImgRef} style={{maxWidth: "100%", maxHeight: "100%"}} className="preview-copy highlight"/>
                        </div>
                        <i className="fa fa-times preview-close"></i>

                        <FontAwesomeIcon style={{pointer: 'cursor'}} className={"fa fa-times preview-close"} icon={faTimes} onClick={closePreview} />
                    </div>

                    <style dangerouslySetInnerHTML={{__html:
                        "    :root {\n" +
                        "        --product-color-base: " + (selectedProduct.colorMain ?? '--theme_sanofi_black') + ";\n" +
                        "        --product-color-highlight: " + (selectedProduct.colorSub ?? '--theme_sanofi_purple_miracle') + ";\n" +
                        "    }\n" +
                        "\n" +
                        "    .page-section .page-section-top {\n" +
                        "        background: var(--product-color-base);\n" +
                        "    }\n" +
                        "    .product-details-section-title,\n" +
                        "    .product-details-section-title {\n" +
                        "        color: var(--product-color-base);\n" +
                        "    }\n" +
                        "\n" +
                        "    footer .footer-menu-wrapper #footer-menu {\n" +
                        "        /*background: var(--product-color-base);*/\n" +
                        "    }\n" +
                        "    .product-details-section p {\n" +
                        "        margin-bottom: 0.8rem;\n" +
                        "    }\n" +
                        "    .product-details-section ul {\n" +
                        "        padding-left: 20px;\n" +
                        "    }\n" +
                        "    .product-details-section ul li {\n" +
                        "        list-style: disc;\n" +
                        "    }\n" +
                        "    .slider__btn-next, .slider__btn-prev {\n" +
                        "        background: var(--product-color-base) !important;\n" +
                        "        color: white !important;\n" +
                        "    }\n" +
                        "    .slider__magnifier {\n" +
                        "        color: var(--product-color-base) !important;\n" +
                        "    }\n" +
                        "    .clickable:hover {\n" +
                        "        background-color: var(--product-color-highlight) !important;\n" +
                        "    }\n" +
                        "\n"}}>
                    </style>

                    <div className="page-section-top">
                        <h1 className="product-details-title" style={{color: "white"}}
                            dangerouslySetInnerHTML={{__html: selectedProduct.name?.replace('®', '<sup>®</sup>')}}></h1>
                    </div>

                    <div className="page-section-content product-information">
                        <div className="product-details-text">
                            <div className="product-details-section">
                                <h2 className="product-details-section-title">{getLanguageContent("pdb-productdetails-header-area", "Anwendungsbereich")}</h2>
                                <p>{selectedProduct.area ?? ''}</p>
                            </div>
                            <div className="product-details-section">
                                <h2 className="product-details-section-title">
                                    {selectedProduct.ingredientType == 3 &&
                                        <span>{getLanguageContent("pdb-productdetails-header-indication", "Indikation")}</span>}
                                    {selectedProduct.ingredientType == 2 &&
                                        <span>{getLanguageContent("pdb-productdetails-header-active-ingredients", "Wirkstoffe")}</span>}
                                    {selectedProduct.ingredientType < 2 && selectedProduct.ingredientType > 3 &&
                                        <span>{getLanguageContent("pdb-productdetails-header-ingredients", "Inhaltstoffe")}</span>}
                                </h2>
                                <ul className="product-details-list">
                                    {selectedProduct.ingredients.map((ingredient, index) => <li>{ingredient}</li>)}
                                </ul>
                            </div>

                            {selectedProduct.description &&
                                <div className="product-details-section">
                                    <h2 className="product-details-section-title">{getLanguageContent("pdb-productdetails-header-general-info", "Allgemeine Informationen")}</h2>
                                    <div dangerouslySetInnerHTML={{__html:selectedProduct.description}}></div>
                                </div>
                            }
                        </div>

                        {selectedProduct.product_images && selectedProduct.product_images.length > 0 &&
                            <div className={"slider-space"}>
                                <CustomSlider showPreview={showPreview}>
                                    {selectedProduct.product_images.map((image, index) => {
                                        return <img key={index} src={image.image} title={image.imageAlt ?? ''} alt={image.imageAlt ?? ''}/>;
                                    })}
                                </CustomSlider>
                            </div>
                        }
                    </div>

                    {selectedProduct.productVariants.length > 0 &&
                        <div className="page-section-content product-variants">
                            <h2 className="product-details-section-title head">{getLanguageContent("pdb-productdetails-header-products-and-variants", "Produkte und Darreichungsformen")}</h2>

                            {selectedProduct.productVariants.map((variant, index) => {
                                return (
                                    <PDBProductDetailsVariant product={selectedProduct} variant={variant} showPreview={showPreview}/>
                                );
                            })}
                        </div>
                    }

                    {selectedProduct.footernotes && selectedProduct.footernotes.length > 0 &&
                        <div class="page-section-content foot-notes" style={{minHeight: 'auto'}}>
                            <div class="content" dangerouslySetInnerHTML={{__html: selectedProduct.footernotes}}/>
                        </div>
                    }
                </>
            }
        </>
    )
}