import React, {useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf, faMagnifyingGlass, faTimes} from "@fortawesome/free-solid-svg-icons";
import {getLanguageContent} from "../../../libs/LanguageProvider";

export default function PDBProductDetailsVariant({product, variant, showPreview}) {

    return (
        <>
            <h3 class="product-details-variant-title">{ variant.name?.replace('®','<sup>®</sup>') }</h3>
            {(variant.isNutritionalSupplement == 1 || variant.isCosmetic == 1 || variant.isMedicine == 1) &&
                <>
                {variant.isNutritionalSupplement == 1 &&
                    <p>{getLanguageContent("pdb-productdetails-nutrition-supplement","Nahrungsergänzungsmittel")}</p>
                }
                {variant.isCosmetic == 1 &&
                    <p>{getLanguageContent("pdb-productdetails-cosmetica","Kosmetikum")}</p>
                }
                {variant.isMedicine == 1 &&
                    <p>{getLanguageContent("pdb-productdetails-medical-product","Medizinprodukt")}</p>
                }
                </>
            }
            
            {!(variant.isNutritionalSupplement == 1 || variant.isCosmetic == 1 || variant.isMedicine == 1) &&
                <>
                    {(variant.isPharmacyRequired == 1 && variant.isPrescriptionRequired == 1) ?
                        <p>{getLanguageContent("pdb-productdetails-medical-drug-prescription-required","Arzneimittel, rezeptpflichtig")}</p>
                        :
                        <>
                            {variant.isPrescriptionRequired == 1 ?
                                <p>{getLanguageContent("pdb-productdetails-medical-drug-prescription-required","Arzneimittel, rezeptpflichtig")}</p>
                                :
                                <p>{getLanguageContent("pdb-productdetails-medical-drug-without-prescription","Arzneimittel, rezeptfrei")}</p>
                            }
                        </>
                    }
                </>
            }

            <div className="product-details-variant-files">
                {variant.pdfOne != false &&
                    <div className="pdf-file-ref">
                        <a href={'/pdb/ext/data/1/' + variant.id + '/' + variant.pdfOneTitle + '.pdf'}
                           target="_blank">
                            <FontAwesomeIcon style={{paddingRight: "10px"}} className={"fa fa-file-pdf-o"} icon={faFilePdf} />
                            <span>
                                <span className="part-name">{variant.pdfOneTitle}&nbsp;</span>
                                {variant.pdfOneDate.length > 0 &&
                                    <span className="part-date">{variant.pdfOneDate}</span>
                                }
                            </span>
                        </a>
                    </div>
                }
                {variant.pdfTwo != false &&
                    <div className="pdf-file-ref">
                        <a href={'/pdb/ext/data/2/' + variant.id + '/' + variant.pdfTwoTitle + '.pdf'}
                           target="_blank">
                            <FontAwesomeIcon style={{paddingRight: "10px"}} className={"fa fa-file-pdf-o"} icon={faFilePdf} />
                            <span>
                                <span className="part-name">{variant.pdfTwoTitle}&nbsp;</span>
                                {variant.pdfTwoDate.length > 0 &&
                                    <span className="part-date">{variant.pdfTwoDate}</span>
                                }
                            </span>
                        </a>
                    </div>
                }
            </div>

            <div className="product-details-variant-details">
                <div className="placeholder-image">
                    <img src={ variant.image } title={ variant.imageAlt ?? '' } alt={ variant.imageAlt ?? '' } />

                    {variant.image &&
                        <button
                            className="slider__magnifier"
                            onClick={(e) => {
                                showPreview(variant.image ?? false);
                            }}
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                    }
                </div>
                <div className="variant-details-wrapper">
                    {variant.description &&
                        <div className="product-details-variant-information">
                            <h4>{getLanguageContent("pdb-productdetails-further-info", "Weitere Informationen")}</h4>
                            <div className="product-details-variants-ingredients" dangerouslySetInnerHTML={{__html: variant.description}}></div>
                        </div>
                    }
                    <div className="product-details-variant-lists">
                        <div className="variant-list-wrapper">
                            <h4>{getLanguageContent("pdb-productdetails-amount", "Menge")}</h4>
                            <ul className="product-details-variant-list">
                                {variant.variant_types.map((type, index) => {
                                    return (
                                        <li>{type.amount}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="variant-list-wrapper">
                            <h4>{getLanguageContent("pdb-productdetails-pzn", "PZN")}</h4>
                            <ul className="product-details-variant-list">
                                {variant.variant_types.map((type, index) => {
                                    return (
                                        <li>{type.pzn}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}