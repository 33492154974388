export const getPaddingStyles = (key) => {
    key = key + '_';
    let devices = ['mobile', 'tablet', 'desktop'];
    let styles = ['PaddingTop', 'PaddingRight', 'PaddingLeft', 'PaddingBottom'];

    let result = [];
    for(let style of styles)
        for(let device of devices)
            result.push(key + device + style);
    
    return result;
}

export const getMarginStyles = (key) => {
    key = key + '_';
    let devices = ['mobile', 'tablet', 'desktop'];
    let styles = ['MarginTop', 'MarginRight', 'MarginLeft', 'MarginBottom'];

    let result = [];
    for(let style of styles)
        for(let device of devices)
            result.push(key + device + style);
    
    return result;
}


export const getResponsiveStyles = (key, property) => [
    key + '_mobile' + property,
    key + '_tablet' + property,
    key + '_desktop' + property,

];


/**
 * Generate the string with the CSS elements
 * @param elem object - with the css values
 * @param keys array - keys that should get included in css
 */
export const generateCSSString = (elem, keys) => {
    let cssString = ""
    keys.forEach((key) => {
        if (elem[key]) {
            cssString += elem[key] + ' '
        }
    })
    return cssString
}


