import React, {useEffect, useState, Fragment, useCallback} from "react";

export default function SanofiButton(props) {

    return (

        <button {...props}
                className={
            "rounded-3xl bg-sanofi-button-default hover:border-sanofi-dark-lila hover:bg-sanofi-dark-lila text-white py-3 px-14 rounded "
                    + (props.className || "")
                    + (props.disabled === true ? " disabled:opacity-50 cursor-not-allowed" : "")
        }>
            {props.children}
        </button>

    )


}