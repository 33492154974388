import React, {useEffect, useState, Fragment, useCallback} from "react";
import SanofiButton from "../SanofiButton";
import OfferButton from "./OfferButton";
import {TranslateText} from "../../libs/LanguageProvider";

export default function OfferTable(props) {


    const onProductValueChange = (productIndex, value) => {
        if (props.onProductValueChange) {
            props.onProductValueChange(productIndex, value);
        }
    }

    return (

        <Fragment>
            <div className="hidden lg:block rounded-lg">
                <div className="rounded-lg border-b-2 border-b-sanofi-table-body">
                    <table className="rounded-lg w-full text-left text-gray-500 dark:text-gray-400">
                        <thead
                            className="text-white rounded-lg bg-sanofi-table-head-bg-default offer-product-table-header">
                        <tr>
                            <th scope="col" className="px-6 py-3"></th>
                            <th scope="col" className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                {TranslateText("offer-table-header-lable-article")}
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-center text-sanofi-white offer-product-table-text">
                                {TranslateText("offer-table-header-lable-price")}
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-center text-sanofi-white offer-product-table-text">
                                {TranslateText("offer-table-header-lable-quantity")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.offer.products.map((product, index) => {

                            if (!product.selectable) {
                                return null
                            }

                            return (
                                <tr className="border-l-sanofi-table-body border-x-2 odd:bg-white even:bg-sanofi-table-body">
                                    <th className="content-center text-center p-10">
                                        <img className="offer-table-img inline-block" alt={product.title_de}
                                             src={product.picture}/>
                                    </th>
                                    <td className="px-6 py-4">
                                        <strong>{product.title}</strong>
                                        <br/>
                                        {product.pharmaNumber} <br/>
                                        {product.dosage} / {product.amount}
                                    </td>
                                    <td className="px-6 py-4 text-center ">
                                        {product.price} {TranslateText("offer-label-currency")}
                                    </td>
                                    <td className=" text-center">
                                        <OfferButton
                                            cart={props.cart}
                                            productId={product.id}
                                            maxQuantity={product.maxOrderQuantity || 1000}
                                            onValueChange={(value) => {
                                                onProductValueChange(product.id, value)
                                            }}
                                        />
                                    </td>
                                </tr>
                            )

                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="block lg:hidden border-2 border-sanofi-table-body rounded-lg">

                {props.offer.products.map((product, index) => {

                    if (!product.selectable) {
                        return null
                    }

                    return (

                        <div className="p-10 border-b odd:bg-white even:bg-sanofi-table-body">
                            <div className="flex justify-center space-x-5">
                                <div>
                                    <img className="offer-table-img inline-block" alt={product.title_de}
                                         src={product.picture}/>
                                </div>
                                <div className={" text-center"}>
                                    <h2 className="text-2xl font-bold">{product.title}</h2>
                                    <br/>
                                    {product.pharmaNumber}
                                    <p>
                                        {product.dosage} / {product.amount}
                                    </p>
                                    <p>
                                        {product.price} {TranslateText("offer-label-currency")}
                                    </p>
                                    <div className={"m-2 text-center"}>
                                        <OfferButton
                                            cart={props.cart}
                                            productId={product.id}
                                            maxQuantity={product.maxOrderQuantity || 1000}
                                            onValueChange={(value) => {
                                                onProductValueChange(product.id, value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    )

                })}


            </div>
        </Fragment>

    )


}