import React from "react";

export default function SanofiButtonDark(props) {

    return (

        <button {...props}
                className={
                    "rounded-3xl hover:bg-sanofi-button-default border-sanofi-dark-lila bg-sanofi-dark-lila text-white py-3 px-14 rounded "
                    + (props.className || "")
                    + (props.disabled === true ? " disabled:opacity-50 cursor-not-allowed" : "")
                }>
            {props.children}
        </button>

    )


}