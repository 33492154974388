import React, { Fragment } from "react";
import { generateCSSString, getPaddingStyles, getResponsiveStyles } from "../../../../helpers/style";

export default function Image(props){

    const component = props.component;
    const className = props.className  ?? '';
    
    const cssValueContainer = ['image_alignment', ...getPaddingStyles('image')];
    const cssValue = [...getResponsiveStyles('image', 'Width'), 'image_borderRadius','image_objectFit', 'image_objectPosition'];

    return(

        <div  className={`relative w-full flex ${generateCSSString(component, cssValueContainer)} ${className}`}>
            {
                component.imageLink_?
                <a href={component.imageLink_} target={props.target?? component.image_target?? '_self'} className={`block ${generateCSSString(component, cssValue)}`}>
                    <img src={component.image_} alt={component.imageAlt_} className={`${generateCSSString(component, ['image_borderRadius'])}`}/>
                    
                    {
                        component.image_overlay &&
                        <div className={`absolute w-full inset-0`}
                            style={{ background: component.image_overlay }}
                        />
                    }

                </a>
                :
                <Fragment>

                <img src={component.image_}  alt={component.imageAlt_} className={`block ${generateCSSString(component, cssValue)}`}/>
                
                {
                    component.image_overlay &&
                    <div className='absolute w-full inset-0'
                        style={{ background: component.image_overlay }}
                    />
                }

                </Fragment>
            }
        </div>
        
    )
}