export const userLevel = () => {
    const selector = document.querySelector("#app");
    if (selector !== null) {
        return selector.getAttribute("ulr");
    }
    return false;
}

export const userComponentAccess = (componentRole, backendPageRole = null) => {

    const roleToInt = (val) => parseInt(val.toString().replace(/[^0-9]/g, ''))


    if(componentRole === 'default') return true;

    let userLevl = backendPageRole ? backendPageRole : userLevel();

    if(userLevl){
        if(componentRole.toString().includes('==')) 
            return roleToInt(userLevl) == roleToInt(componentRole);
        return roleToInt(userLevl) <= roleToInt(componentRole);
    }
    

   return true; //Backend
}


export const getUserLanguages = () => {
    const [html] = document.getElementsByTagName("html")
    const lang = html.getAttribute("lang");
    return (lang === null ? "de" : lang);
}


