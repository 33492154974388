import { TranslateText } from "../../../helpers/language";

export function getStyleOptions(cmsproperties, prop){
    let properties = cmsproperties.properties;
    if(properties[prop])
        return properties[prop].map(property => ({text: TranslateText(property.option), value: property.value}));
    return ([{text: TranslateText('cmsNone'), value: null}])
}

export function getStyleValue(cmsproperties, prop, option){
    let properties = cmsproperties.properties;
    return properties[prop].find((prop) => prop.option == option).value;
}

export const getColorOptions = (cmsproperties) => {
    let properties = cmsproperties.properties;
    return properties.color.map(color => ({color: color.value, title: color.option}));
}

export const getCkEditorColors = (cmsproperties) => {
    let properties = cmsproperties.properties;
    return properties.color.map(color => ({color: color.value, label: color.option, hasBorder: true}));
}

export function getRoleOptions(cmsproperties, defaultOption = false, loggedOutOption = false){
    let options = [];

    if(defaultOption)
        options.push({text: TranslateText('Default'), value: 'default'})

    for(let i = 0; i < cmsproperties.roles.length; i++){

        let role = cmsproperties.roles[i];

        if(i === cmsproperties.roles.length - 1){
            options.push({text: role.name, value: role.id})
            if(loggedOutOption) options.push({text: `${role.name} - ${TranslateText('cmsNotLoggedIn')}` , value: '==' + role.id})
        }
        else
            options.push({text: role.name, value: role.id})

    }

    return options;
}

export function getRoleValue(cmsproperties, roleId){
    let role = cmsproperties.roles.find((role) => role.id === roleId)
    return role?.name;
}

export function getAuthProvider(cmsproperties){
    let options = [];
    options.push({text: TranslateText('cmsNone'), value: 'none'})
    for(let provider of cmsproperties.authproviders){
        options.push({text: provider, value: provider})
    }
    return options;

}
