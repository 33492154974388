import React, { Fragment } from "react";
import ComponentFrontend from "..";
import ComponentBackend from "../../../../CmsDesigner/components/Component";


export default function Hero(props) {
    
    const {component, draggable, ...rest} = props;
    
    const backend = props.backend ?? false;


    return (
        <Fragment>
            <div
                id={`Hero-${component.uuid}`} 
                className={`flex grow ${component.hero_negativeMargin == 1 && !backend? 'cms-mb-0 sm:-mb-20' : ''}`}
                style={{
                    backgroundImage: `${component.image_ ? 'url("' + component.image_ + '")': 'unset'}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: `${component.heroBackground_color ? component.heroBackground_color : 'unset'}`,
                    backgroundPosition: component.image_backgroundPosition,
                    border: `${component.heroBorder_color ? '1px solid' + component.heroBorder_color : 'unset'}`
                }}>

                <div className={`overflow-hidden w-full flex flex-wrap`}
                    style={{
                        background: `${component.image_overlay ?? 'unset'}`,
                    }}>
                    {
                        component.children && component.children.map((child) => {
                                if(backend) 
                                    return <ComponentBackend component={child} {...rest} />
                                return <ComponentFrontend component={child} {...rest}/>
                        })
                    }
                </div>
            </div>     
        </Fragment>
    )
}