import React, { Fragment } from "react";
import { generateCSSString, getPaddingStyles } from "../../../../helpers/style";


export default function Title(props) {

    const component = props.component;
    const cssValue = ['title_textTransform', ...getPaddingStyles('title')];

    return (
        <div 
            id={`Title-${component.uuid}`}
            className={`w-full ${generateCSSString(component, cssValue)} break-words`} 
            style={{textShadow: component.titleBoxShadow_color? `0px 3px 6px ${component.titleBoxShadow_color}`: 'none'}} 
            dangerouslySetInnerHTML={{__html: component.titleText_}}>
        </div>
    )
}