import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import { sections } from "../CmsDesigner/helpers/components";

import Component from "./components/Component";

import { getUserLanguages } from "./helpers/user";

export default function CmsPage(props) {

    const {previewPage, ...rest} = props;

    const [pageData, setPageData] = useState(previewPage);

    useEffect(() => setPageData(previewPage), 
        [previewPage]
    )
    const setMetaData = (page) => {

        if (page.metaKeywords){
            let metaKeywords = $('meta[name="keywords"]');
            if(metaKeywords.length)
                metaKeywords.attr('content', page.metaKeywords);
            else 
                $(`<meta name="keywords" content="${page.metaKeywords}"/>`).insertAfter('title');
        }

        if (page.metaDescription){
            let metaDescription = $('meta[name="description"]');
            if(metaDescription.length) 
                metaDescription.attr('content', page.metaDescription);
            else 
            $(`<meta name="description" content="${page.metaDescription}"/>`).insertAfter('title');
        }

        if (page.metaTitle){
            let metaTitle = $('meta[name="title"]');
            if(metaTitle.length) 
                metaTitle.attr('content', page.metaTitle);
            else 
            $(`<meta name="title" content="${page.metaTitle}"/>`).insertAfter('title');
        }

        if(page.noIndex == 1)
            $('<meta name="robots" content="noindex, nofollow"/>').insertAfter('title');
        
    }

    useEffect(() => {
        if(!pageData){
            axios
                .post('/module/getPageInFrontend/cmspage', {lang: getUserLanguages(), slug: window.location.pathname ?? '/'})
                .then(data => {
                    if (data.data && data.data.status === 'success') {

                        if(data.data.message == 'doccheck') {
                            return window.location.href = data.data.data.redirect;
                        }

                        let page = data.data.data;
                        document.title = `${page.title ?? document.title}`; 
                        
                        setPageData(page);
                        setMetaData(page);
                        
                    } else {
                        window.location.href = '/404';
                    }
                });
        }
    }, [])

    // console.log(pageData);

    return (
        <Fragment>
            { 
                pageData &&
                <div className='frontend-text cms-page' style={{backgroundColor: pageData.backgroundColor}}>
                    {
                        sections.map(section =>
                            pageData[section]?.length > 0 && 
                            ((section !== 'cmsPageFooter' || pageData[`hideFooter`] != 1)  && (section !== 'cmsPageHeader' || pageData[`hideHeader`] != 1)) &&
                            <Component component={pageData[section][0]} matNumber={pageData.matNumber} {...rest}/>)
                    }
                </div>
            }
        </Fragment>
    )
}