import React, {Fragment} from 'react';
import axios from "axios";
import {getUserLanguages} from "../../helper/User";
import {LanguageProvider} from "../../libs/LanguageProvider";
import Loginscreen from "../../components/Login/Loginscreen";

export const fetchTranslations = ({language = "de"}) =>
    axios.get("/design/frontend/language/" + getUserLanguages() + ".json")
        .then((data) => {
            return data.data;
        });

export default function Login(props) {
    return (
        <LanguageProvider fetchTranslations={fetchTranslations}>
            <Fragment>
                <Loginscreen
                    lang={props.lang}
                    languageitems={props.languageitems}
                    loginform={props.loginform}
                    sliderdata={props.sliderdata}
                    swissRxPath={props.swissRxPath}
                    supportSwissRx={props.supportSwissRx}
                    frontendAllowRegistration={props.frontendAllowRegistration}
                />
            </Fragment>
        </LanguageProvider>
    )
}

