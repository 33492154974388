import React, {
    useState,
    createContext,
    useContext,
    useCallback,
    useEffect,
    useRef, Fragment
} from "react"

export const LanguageContext = createContext({
    language: "de",
    entities: {},
    updateLanguage: () => {}
})

export const LanguageProvider = ({ children, fetchTranslations }) => {

    const [{ language, entities }, setLanguage] = useState({
        language: "de",
        entities: {}
    })

	const initialStringsLoaded = useRef(false);

	const updateLanguage = useCallback(
		async (newLang) => {
			if (initialStringsLoaded.current && newLang === language) return;
			const newEntities = await fetchTranslations({ language: newLang });
			initialStringsLoaded.current = true;
			setLanguage({
				language: newLang,
				entities: newEntities,
			});
		},
		[language, fetchTranslations]
	);

	useEffect(() => {
		updateLanguage(language);
	}, [language, updateLanguage]);

	const context = {
		language,
		entities,
		updateLanguage: updateLanguage,
	};

	return (
		<LanguageContext.Provider value={context}>
			{children}
		</LanguageContext.Provider>
	);
};


export const TranslateText = (text) => {
    if (text.text != undefined)
        text = text.text;
    else text = text;

    const { entities } = useContext(LanguageContext);
    const lEntity = entities[text] || text;
    
    if (lEntity === undefined) 
        return text;

    if (lEntity.content !== undefined)
        return lEntity.content;
    
    return text;
};


export const getLanguageContent = (children, defaultValue) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[children];// || children;
    if (lEntity === undefined) { return defaultValue; }
    return lEntity.content;
};

export const TranslateHtml = ({name}) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[name] || name;
    if (lEntity === undefined) { return name; }
    return (<div dangerouslySetInnerHTML={{__html: lEntity.content}}/>)

};

export const Translate = ({children}) => {
    const { entities } = useContext(LanguageContext)
    const lEntity = entities[children] || children;
    if (lEntity === undefined) { return children; }
    return (<div dangerouslySetInnerHTML={{__html: lEntity.content}}/>)

};
