import React from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import { TranslateText } from '../../../../../helpers/language';
import SucIcon from '../../../../../../../../core/react/src/components/icons/SucIcon';
import SucButton from '../../../../../components/SucButton';

import { getPage, selectComponent, openModal, setValue, addComponent } from '../../../store/page';
import { findComponentBySection } from '../../../helpers/components';
import { generateCSSString, getResponsiveStyles } from '../../../../CmsPage/helpers/style';
import { getRoleValue } from '../../../helpers/style';


export default function ComponentWrapper(props) {

    const dispatch = useDispatch();
    const page = useSelector(getPage);

    const component = props.component;
    const handleDragStart = props.handleDragStart;
    const handleDragEnter = props.handleDragEnter;
    const onDragEnd = props.onDragEnd;
    const draggable = props.draggable ?? true;
    const editable = props.editable ?? true;

    const isSelected = component.internId == page.selectedComponentId;

    const getCssValueContainer = (componentName) => {
        switch (componentName){
            case `cmsComponentContainer`:
                return `${generateCSSString(component, [...getResponsiveStyles('container', 'Width'),])} flex flex-column`
            case 'cmsComponentCard':
                return `flex flex-col grow w-full`
            case 'cmsPageMainContainer':
                return `grow flex relative`;
            default:
                return 'w-full'
        }
    }

    const cssValueContainer = getCssValueContainer(component.component);

    const addComponentInsideBtn = (e) => {
        e.stopPropagation(); 
        dispatch(setValue({key: 'selectedComponentParentId', value: component.internId}))
        dispatch(openModal({modal: 'selectComponent'}))
    }

    // const addComponentBelowBtn = (e) => {
    //     e.stopPropagation(); 
    //     dispatch(openModal({modal: 'selectComponent'}))
    // }

    
    return(
        <div 
            className={`${cssValueContainer}`}
            draggable={draggable}
            onDragStart={(e) => handleDragStart(e, component.internId)}
            onDragEnter={(e) => handleDragEnter(e, component.internId)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={onDragEnd}
        >
            <div 
                className={`${isSelected && editable? 'component-wrapper-container-selected ': 'component-wrapper-container'} flex flex-column grow overflow-scroll`}
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(selectComponent({id: component.internId, parentId: component.internParentId}))
                }} 
            >
                {
                    editable &&
                     <div className={`w-full flex flex-wrap justify-between items-center component-wrapper-up ${isSelected? 'component-wrapper-selected': 'component-wrapper'}`}>
                        <span className='suc-gray text-sm break-all'>
                        {`${TranslateText(component.component)} ${component.anchor_ ? ('| #' + component.anchor_ + ' ') : ''} ${(component.roleId && component.roleId !== 'default') ? '| ' + getRoleValue(page.cmsproperties, component.roleId) + ' ': '' } ${(component.hidden_ == 1) ? '| Hidden ' : '' }`}
                        </span>

                        {
                            isSelected && 
                            <div className={`flex`}>
                                {/* {   
                                    component.internParentId !== null &&
                                    <SucButton
                                        title={TranslateText('cmsAddComponentBelow')}å
                                        className={`component-wrapper-btn suc-gray`}
                                        children={<SucIcon icon='add' classes='md-18 not-italic'/>}
                                        onClick={(e) => addComponentBelowBtn(e)}
                                />
                                } */}
                                {
                                    component.internParentId !== null &&
                                    <SucButton
                                        title={TranslateText('cmsDuplicateComponent')}
                                        className={`component-wrapper-btn suc-gray`}
                                        children={<SucIcon icon='content_copy' classes='md-18 not-italic' />}
                                        onClick={(e) => {e.stopPropagation();
                                            let componentBySection = findComponentBySection(page, 'internId', component.internId);
                                            dispatch(addComponent({newComponent: componentBySection.component}));
                                        }}
                                />
                                }
                                <SucButton
                                    title={TranslateText('cmsEditComponent')}
                                    className={`component-wrapper-btn suc-gray`}
                                    children={<SucIcon icon='edit' classes='md-18 not-italic' />}
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        let componentBySection = findComponentBySection(page, 'internId', component.internId)
                                        dispatch(openModal({modal: 'editComponent', component: componentBySection.component}))}}
                                />
                            </div>
                        }
                    </div>
                }
                

                <div id={`CMS-${component.uuid}`} className={`w-full frontend-text grow flex flex-column`}>
                    {props.children}
                </div>

                {
                    editable && isSelected && component.children && component.component !== 'cmsComponentMenu' &&
                    <SucButton
                        title={TranslateText('cmsAddComponent')}
                        className='btn btn-default btn-icon w-fit m-auto'
                        children={<SucIcon icon='add' classes='md-18 not-italic'/>}
                        onClick={(e) => addComponentInsideBtn(e)}
                    />
                }
                
                {
                    editable &&
                    <div className={`w-full component-wrapper-down ${isSelected? 'component-wrapper-selected': 'component-wrapper'}`}>
                    </div>
                }
                

            </div>
        </div>
    )
}