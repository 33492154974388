import {getLanguageContent, TranslateText} from "../../libs/LanguageProvider";
import Input from "../Form/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleInfo, faCopy, faRotateRight} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import axios from "axios";
import SanofiButton from "../SanofiButton";
import Checkbox from "../Form/Checkbox";

/*
Handle GLN input - including proPharma

props:
loginType: type of login
onValueChange
gln: gln of user
getProPharmaActiveValue: method or value value that return whether user has proPharma
proPharmaGLN: propharmagln of user
 */
export default function GLN(props) {
    const [tokenCopied, setTokenCopied] = useState(false);

    const generateToken = () => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/generateTokenProPharma",
        }).then(tokendata => {

            if (tokendata.data.status && tokendata.data.status === "success") {
                props.onValueChange('proPharmaToken', tokendata.data.proPharmaToken)

            } else {
                alert(tokendata.data.message);
                props.setErrorState(true);
            }

        });

    }

    /*https://stackoverflow.com/questions/68007900/how-to-check-if-the-value-of-an-input-is-number-or-string-in-react-js*/
    const isNumber = (num) => {
        return !isNaN(+num)
    }

    // More Information: https://blog.logrocket.com/implementing-copy-clipboard-react-clipboard-api/
    async function tokenInClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }


    const copyToken = (text) => {
        tokenInClipboard(text)
            .then(() => {
                setTokenCopied(true);
                setTimeout(() => {
                    setTokenCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <React.Fragment>
            {(props.loginType === "0" || props.loginType === "null") &&
                <div>
                    <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-2">
                        <div className="flex-1">
                            <div className={"mb-1.5"}>
                                <label>{TranslateText("profile-label-gln")}</label>
                            </div>
                            <Input value={props.gln} onChange={(e) => {
                                props.onValueChange("gln", e.target.value)
                            }} type="text"/>
                        </div>
                    </div>
                    <div>
                        <Checkbox
                            type="checkbox"
                            id="flexCheckChecked"
                            value={props.proPharmaActive}
                            checked={props.proPharmaActive === 1}
                            onClick={() => props.onValueChange("proPharmaActive", props.proPharmaActive === 1 ? 0 : 1)}
                        />
                        <label htmlFor="flexCheckChecked" className="cursor-pointer">
                            {getLanguageContent("profile-label-checkbox-proPharma-customer", 'ProPharma Kunde')}
                        </label>
                    </div>
                </div>
            }

            {(props.loginType === "0" || props.loginType === "null") && props.proPharmaActive === 1 &&
                <div>
                    <div
                        className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-4">
                        <div className="flex-1">
                            <div className={"mb-1.5"}>
                                <label>{getLanguageContent("profile-label-gln-proPharma", 'GLN für ProPharma')}*</label>
                            </div>

                            <Input className="bg-gray-100 cursor-not-allowed text-gray-700"
                                   value={isNumber(props.gln) ? props.gln : props.proPharmaGLN}
                                   onChange={(e) => {
                                       props.onValueChange("proPharmaGLN", e.target.value)
                                   }}
                                   type="text" readOnly={true} required/>
                        </div>
                        <div className="flex-1">
                            <div className={"mb-1.5"}>
                                <label>{getLanguageContent("profile-label-token-proPharma", 'Token für ProPharma')}*</label>
                            </div>
                            <div
                                className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 ">
                                <Input className="bg-gray-100 cursor-not-allowed text-gray-700"
                                       value={(props.proPharmaToken) ? props.proPharmaToken : generateToken()}
                                       type="text" readOnly={true} required/>
                                <button
                                    className="rounded-2xl text-sanofi-dark-lila hover:text-white hover:bg-sanofi-button-default py-2 px-2 rounded"
                                    title={getLanguageContent("generate-token-proPharma", 'Generiere neues Token')}
                                    onClick={(e) => generateToken()}
                                    type="button"
                                >
                                    <FontAwesomeIcon icon={faRotateRight} size='2x'/>
                                </button>
                                <button
                                    className="rounded-2xl text-sanofi-dark-lila hover:text-white hover:bg-sanofi-button-default py-2 px-2 rounded"
                                    title={getLanguageContent("token-proPharma-copy", 'Kopiere Token')}
                                    onClick={(e) => {
                                        copyToken(props.proPharmaToken)
                                    }}
                                    type="button"
                                >
                                    <span>
                                        {tokenCopied ?
                                            <FontAwesomeIcon icon={faCheck} size='1x'/> :
                                            <FontAwesomeIcon icon={faCopy} size='1x'/>
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <button
                            className={
                                "rounded-3xl bg-sanofi-button-default hover:border-sanofi-dark-lila hover:bg-sanofi-dark-lila text-white py-1 px-4 rounded "
                            }>
                            <a target="_blank"
                               href={getLanguageContent("profile-link-proPharma-manual", "/home")}>{getLanguageContent("profile-button-proPharma-manual", "Bedienungsanleitung")}</a>
                        </button>
                    </div>
                </div>
            }
        </React.Fragment>
    )


}