import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import axios from "axios";
import {TranslateHtml, TranslateText, Translate} from "../../libs/LanguageProvider";
import {useModal} from "../../libs/ModalProvider";
import RebateContainer from "../../components/RebateContainer";
import OfferTable from "../../components/OfferTable";
import SanofiButton from "../../components/SanofiButton";
import {
    evaluateRebateRule,
    getAdditionalItems,
    getAdditionalReduction,
    getProductOfOptionItem,
    getRuleAdditional
} from "../../helper/OfferCalculation";
import {useNavigate} from "react-router-dom";

import OfferNotice from "../../views/Modals/OfferNotice";
import Hero from "../Header/Hero";
import ContentArea from "../ContentArea";
import ProductTable from "../ProductTable";
import SucHelper from "../../../../../core/react/src/helpers/General";
import Cart from "../Cart";
import LinkedOfferCart from "../Cart/LinkedOfferCart";


export default function OfferScreen(props) {

    let designEntity = null;

    const {setModal, closeModal} = useModal()
    let navigate = useNavigate();

    // Additional Variables
    const [hasLoaded, setHasLoaded] = useState(false)
    const [allowRender, setAllowRender] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorState, setErrorState] = useState(false)

    const [activeRule, setActiveRule] = useState(-1)

    const [cartExpanded, setCartExpanded] = useState(false);
    const [heroImage, setHeroImage] = useState("")

    // Value of main Offer
    const [offer, setOffer] = useState({})
    const [activeOrder, setActiveOrder] = useState({})
    const [products, setProducts] = useState([])

    const [cart, setCart] = useState([])
    //console.log('cart', cart)


    // Values for linked Offer (optional Feature)
    const [isLinkedOffer, setIsLinkedOffer] = useState(false)
    const [linkedOffer, setLinkedOffer] = useState({})
    const [activeLinkedOrder, setActiveLinkedOrder] = useState({})

    const [activeLinkedOfferRule, setActiveLinkedOfferRule] = useState(-1)

    const [linkedOfferProducts, setLinkedOfferProducts] = useState([])

    const [cartLinkedOffer, setCartLinkedOffer] = useState([])

    //console.log('cartLinkedOffer', cartLinkedOffer)

    useEffect(() => {

        const aOrderString = window.localStorage.getItem("cart")
        if (!SucHelper.isNullUndefinedOrEmpty(aOrderString)) {
            try {
                const aOrder = JSON.parse(aOrderString);
                if (aOrder.slug === props.slug) {
                    setActiveOrder(aOrder);
                }
                if (aOrder.hasOwnProperty("cartLinkedOffer")) {
                    setActiveLinkedOrder(aOrder.cartLinkedOffer)
                }
            } catch (e) {
                console.error(e);
            }

        }

        axios({
            dataType: "json",
            method: "post",
            url: "/module/" + props.slug + "/fetchOffer/frontend/",
            data: {}
        }).then(data => {
            if (data.data.status && data.data.status === "error") {
                setErrorState(true);
                navigate("/marketplace", true);
                return;
            }
            if (data.data.data) {
                const buff = {...data.data.data.offer};
                appendStyleCSS(buff.design)
                setHeroImage(buff.hero)
                setOffer(buff);

                if ('linkedOffer' in buff && typeof buff.linkedOffer === 'object' && Object.keys(buff.linkedOffer).length > 0) {
                    setIsLinkedOffer(true)
                    setLinkedOffer(buff.linkedOffer)
                    setLinkedOfferProducts(buff.linkedOffer.products)
                }

                setProducts(buff.products);
                setHasLoaded(true);
            }

        });

        // will remove the style if required
        return () => {
            if (designEntity !== null) {
                document.head.removeChild(designEntity);
            }
        };

    }, [])

    useEffect(() => {

        if (!hasLoaded) {
            return;
        }

        // we may have active order in store, so set the data if required
        if (!activeOrder.hasOwnProperty("cart")) {
            setAllowRender(true);
            return;
        }

        const cardValues = setValuesForProducts(activeOrder.cart, products, offer)
        setCart(cardValues.cartBuff);
        setProducts(cardValues.productBuff);
        setActiveRule(cardValues.activeRuleIndex)

        if (isLinkedOffer) {
            const cardLinkedValues = setValuesForProducts(activeOrder.cartLinkedOffer, linkedOfferProducts, linkedOffer)
            setCartLinkedOffer(cardLinkedValues.cartBuff);
            setLinkedOfferProducts(cardLinkedValues.productBuff);
            setActiveLinkedOfferRule(cardValues.activeRuleIndex)
        }

        setAllowRender(true);


    }, [hasLoaded]);

    const setValuesForProducts = (cartBuff, oProducts, offerelem) => {
        cartBuff = cartBuff.filter(item => item.checkFor === undefined);
        const productsBuff = [...oProducts];
        let activeRuleIndex = -1;

        cartBuff.forEach((cartItem, index) => {
            let found = false;

            productsBuff.forEach((product) => {
                if (product.id === cartItem.id) {
                    found = true;
                }
            })

            if (!found || cartItem.merged === true) {
                cartBuff.splice(index, 1);
            }

            cartBuff[index] = {
                ...cartBuff[index],
                merged: false,
                display: true,
                hidePrice: false,
                selectable: true,
                quantitySelectable: false,
                canSelectWholesaler: false,
                canSelectDeliveryDate: false,
                canPickQuantity: true,
            }

        })

        if (cartBuff.length > 0) {

            // update the products as well
            if (Array.isArray(productsBuff)) {
                productsBuff.forEach((offerProduct, index) => {
                    for (let i = 0; i < cartBuff.length; i++) {
                        if (cartBuff[i].id === offerProduct.id && offerProduct.display === false) {
                            productsBuff[index]['quantity'] = cartBuff[i].quantity;
                        }
                    }
                })
            }

            let filtered = [];
            for (let ruleIndex = 0; ruleIndex < offerelem.rules.length; ruleIndex++) {
                let value = evaluateRebateRule(offerelem.rules[ruleIndex], cartBuff)
                if (value !== false) {
                    let items = getRuleAdditional(offerelem.rules[ruleIndex]);
                    filtered = getAdditionalItems(items);

                    activeRuleIndex = ruleIndex;
                }
            }

            if (Array.isArray(filtered) && filtered.length > 0) {
                filtered.forEach(optionItem => {
                    const product = getProductOfOptionItem(optionItem, oProducts);
                    if (product === undefined) {
                        return null;
                    }

                    const mergeProduct = {
                        ...optionItem,
                        ...product,

                        priceReduction: 0,
                        quantity: optionItem.value,

                        merged: true,
                        display: false,
                        selectable: true,

                        quantitySelectable: false,
                        canSelectWholesaler: false,
                        canSelectDeliveryDate: false,
                        canPickQuantity: false,

                    };

                    cartBuff.push(mergeProduct);

                })


            }
        }

        return {cartBuff: cartBuff, productBuff: productsBuff, activeRuleIndex: activeRuleIndex}
    }

    const appendStyleCSS = (id) => {
        designEntity = document.createElement("link");
        designEntity.href = "/styles/" + id + "/design.css";
        designEntity.rel = "stylesheet";
        document.head.appendChild(designEntity);
    }


    /**
     * Update Function for the Product list
     * @param index
     * @param key
     * @param value
     * @param isLinkedOffer - whether the product is from the linked offer or not
     */
    const onProductQuantityChange = (index, key, value, isLinkedOffer = false) => {

        if (key !== "quantity") {
            return;
        }

        let offerToUpdate;
        let productlist;
        let productBuff;

        if (isLinkedOffer) {
            offerToUpdate = linkedOffer
            productlist = linkedOfferProducts

            productBuff = [...productlist]
            productBuff[index][key] = value;

            setActiveLinkedOfferRule(-1)
            setLinkedOfferProducts(productBuff)

        } else {
            offerToUpdate = offer
            productlist = products

            productBuff = [...productlist]
            productBuff[index][key] = value;

            setActiveRule(-1);
            setProducts(productBuff);
        }


        // we build a new cart
        let cartBuff = [];


        productBuff.forEach(product => {
            if (product.quantity > 0 && product.selectable === true) {
                cartBuff.push(product);
            }
        })

        if (cartBuff.length > 0) {
            let filtered = [];
            for (let ruleIndex = 0; ruleIndex < offerToUpdate.rules.length; ruleIndex++) {
                let value = evaluateRebateRule(offerToUpdate.rules[ruleIndex], cartBuff)
                if (value !== false) {
                    if (isLinkedOffer) {
                        setActiveLinkedOfferRule(ruleIndex)
                    } else {
                        setActiveRule(ruleIndex);
                    }

                    let items = getRuleAdditional(offerToUpdate.rules[ruleIndex]);
                    filtered = getAdditionalItems(items);
                }
            }

            if (Array.isArray(filtered) && filtered.length > 0) {
                filtered.forEach(optionItem => {
                    const product = getProductOfOptionItem(optionItem, productBuff);
                    if (product === undefined) {
                        return null;
                    }

                    const mergeProduct = {
                        ...optionItem,
                        ...product,

                        priceReduction: 0,
                        quantity: optionItem.value,
                        deliveryDate: null,
                        merged: true,
                        display: true,
                        selectable: true,

                        quantitySelectable: false,
                        canSelectWholesaler: false,
                        canSelectDeliveryDate: false,
                        canPickQuantity: false,

                    };


                    cartBuff.push(mergeProduct);

                })


            }


        }

        if (isLinkedOffer) {
            setCartLinkedOffer(cartBuff)
        } else {
            setCart(cartBuff);
        }

    }

    const getCartToStore = () => {
        if (isLinkedOffer) {
            return (
                {
                    cart: cart,
                    cartLinkedOffer: cartLinkedOffer,
                    slug: props.slug,
                }
            )
        } else {
            return (
                {
                    cart: cart,
                    slug: props.slug,
                }
            )
        }
    }

    const displayPopup = () => {
        if (Array.isArray(offer.popups)) {

            let popup = undefined;

            if (activeRule > -1) { // there is a rule
                for (let i = 0; i < offer.popups.length; i++) {
                    let activeRuleId = offer.rules[activeRule].id
                    if (offer.popups[i].offerRule === activeRuleId) {
                        popup = {...offer.popups[i]};
                        break;
                    }
                }
            }
            if (activeRule === -1 && cart.length > 0) { // so selected rule
                for (let i = 0; i < offer.popups.length; i++) {
                    if (parseInt(offer.popups[i].offerRule) === parseInt(activeRule)) { // search for popup for 'no rebate' case
                        popup = {...offer.popups[i]};
                        break;
                    }
                }
            }


            if (popup !== undefined) {
                popup['doConfirm'] = submitOrder;
                setModal(<div><OfferNotice data={popup}/></div>);
                return;
            }

            submitOrder()

        }

    }

    const submitOrder = () => {
        if (cart.length > 0 || cartLinkedOffer.length > 0) {
            window.localStorage.setItem('cart', JSON.stringify(getCartToStore()));
            navigate("/marketplace/cart", true);
        }
    }

    return (
        <>
            <Hero heroImage={heroImage}/>
            <ContentArea>
                <Fragment>

                    {allowRender &&
                        <>

                            <h1 className={"text-4xl color-sanofi-dark-lila font-bold mb-4"}>{offer.title || ""}</h1>

                            <div className="mb-8">
                                <div dangerouslySetInnerHTML={{__html: offer.descriptionTop || ""}}/>
                            </div>

                            <RebateContainer offer={offer} activeRule={activeRule}/>


                            <ProductTable products={products} onChange={(index, key, value) => {
                                onProductQuantityChange(index, key, value)
                            }}/>

                            <div className={"mt-8"}>
                                <div dangerouslySetInnerHTML={{__html: offer.descriptionBottom || ""}}/>
                            </div>


                            {isLinkedOffer &&
                                <div>
                                    <h2 className={"text-4xl color-sanofi-dark-lila font-bold mb-4"}>{linkedOffer.title || ""}</h2>

                                    <div className="mb-8">
                                        <div dangerouslySetInnerHTML={{__html: linkedOffer.descriptionTop || ""}}/>
                                    </div>

                                    <RebateContainer offer={linkedOffer} activeRule={activeLinkedOfferRule}/>


                                    <ProductTable products={linkedOfferProducts} onChange={(index, key, value) => {
                                        onProductQuantityChange(index, key, value, true)
                                    }}/>

                                    <div className={"mt-8"}>
                                        <div dangerouslySetInnerHTML={{__html: linkedOffer.descriptionBottom || ""}}/>
                                    </div>
                                </div>
                            }

                            {(cart.length > 0 && !isLinkedOffer) &&
                                <Cart rules={offer.rules} activeRule={activeRule} cart={cart} displayProducts={true}
                                      hasLinkedOffer={isLinkedOffer}
                                >
                                    <SanofiButton
                                        className="offer-product-button hover:text-sanofi-white"
                                        onClick={(e) => {
                                            displayPopup()
                                        }}>
                                        {TranslateText("offer-cart-label-order-button")}
                                    </SanofiButton>
                                </Cart>
                            }

                            {isLinkedOffer && (cart.length > 0 || cartLinkedOffer.length > 0) &&
                                <Cart rules={offer.rules} activeRule={activeRule} cart={cart}
                                      offertitle={offer.title}
                                      displayProducts={true}
                                      hasLinkedOffer={isLinkedOffer}
                                      rulesLinkedOffer={linkedOffer.rules} cartLinkedOffer={cartLinkedOffer}
                                      offertitleLinkedOffer={linkedOffer.title}
                                      activeRuleLinkedOffer={activeLinkedOfferRule}
                                >
                                    <SanofiButton
                                        className="offer-product-button hover:text-sanofi-white"
                                        onClick={(e) => {
                                            displayPopup()
                                        }}>
                                        {TranslateText("offer-cart-label-order-button")}
                                    </SanofiButton>
                                </Cart>
                            }


                        </>
                    }


                    {!allowRender &&
                        <div className="flex p-20 items-center justify-center">
                            <div
                                className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin">
                            </div>
                        </div>
                    }


                </Fragment>
            </ContentArea>
        </>
    )
}