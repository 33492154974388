import React, {useEffect, useState, useRef, Fragment} from "react";
import 'tw-elements';
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import {getLanguageContent, TranslateText} from "../../../libs/LanguageProvider";
import {
    updateMultipleSelects,
    updateMultipleRating,
    updateSelect,
    updateSingleSelects,
    updateValue,
    setFields
} from "../store/slices/formSlice";
import {useDispatch, useSelector} from "react-redux";
import SanofiButton from "../../SanofiButton";
import Datepicker from "../../Form/Datepicker";
import Textarea from "../../Form/Textarea";
import Radio from "../../Form/Radio";
import MultipleChoice from "../../Form/MultipleChoice";
import Checkbox from "../../Form/Checkbox";
import {parse} from "date-fns";
import {fValidation} from "../helper/Validation/validation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import Popover from "../../Popover";
import RatingSingle from "../../Form/RatingSingle";
import RatingMultiple from "../../Form/RatingMultiple";
import Title from "../../Form/Title";
import {PropaneSharp} from "@mui/icons-material";

const addRequiredToLabel = (label) => {
    let index = label.lastIndexOf('<');
    if (index > -1)
        return label.substring(0, index) + '*' + label.substring(index, label.length);
    return label + '*';
}

export default function FieldElement(props) {

    // validation
    const [validValue, setValidValue] = useState(true);
    const [initialized, setIntialized] = useState(false);

    const ref = useRef(props.fElem.hidden);

    useEffect(() => {

        if (!initialized && ref.current !== undefined)
            setIntialized(true);

        ref.current = props.fElem.hidden;

    }, [props.fElem.hidden])


    const dispatch = useDispatch();
    const formValues = useSelector((state) => state.formhandling.formValues);

    const getFieldValue = (fielduuid, emptyOption = '') => {

        if (fielduuid in formValues) { // uuid in list => give according
            if (Array.isArray(formValues[fielduuid])) { // is Array => whole array (is a list of values) -> for Multiselects
                return formValues[fielduuid]
            } else { // is Object
                if ('value' in formValues[fielduuid]) {
                    return formValues[fielduuid].value
                }
            }
        }
        return emptyOption
    }

    const validateValue = (newValue) => {
        setValidValue(fValidation(newValue, props.fElem.type));
        dispatch(updateValue({id: props.fElem.uuid, value: newValue}))
    }

    // Testing Path: http://localhost:3000/form/{uuid}
    const getFormElementByTypeId = (isDisabled = false) => {
        switch (props.fElem.type) {
            case 0: // Title
                return <Title title={props.fElem.label}/>
            case 1: // Text
                return <div dangerouslySetInnerHTML={{__html: props.fElem.label}}/>
            case 2: // Input
                return <Input
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    disabled={isDisabled}
                    value={getFieldValue(props.fElem.uuid)}
                    type="text"
                    onChange={(e) => dispatch(updateValue({id: props.fElem.uuid, value: e.target.value}))}/>;
            case 3: //Textarea (Multiple Line Answer)
                return <Textarea
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    disabled={isDisabled}
                    value={getFieldValue(props.fElem.uuid)}
                    onChange={(e) => dispatch(updateValue({
                        id: props.fElem.uuid,
                        value: e.target.value
                    }))}/>;
            case 4: // Select
                return <Select
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    disabled={isDisabled}
                    value={getFieldValue(props.fElem.uuid)}
                    onChange={(e) => {
                        dispatch(updateValue({
                            id: props.fElem.uuid,
                            value: e.target.value,
                            options: props.fElem.options
                        }))
                    }}>
                    {props.fElem.options.map((option) => (<option value={option.value}>{option.value}</option>))}
                </Select>;
            case 5: // Checkbox
                return <div className='flex'>
                    <Checkbox
                        required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                        disabled={isDisabled}
                        checked={getFieldValue(props.fElem.uuid, false)}
                        type="checkbox"
                        onChange={(e) => {
                            dispatch(updateValue({
                                id: props.fElem.uuid,
                                value: !getFieldValue(props.fElem.uuid, false)
                            }))
                        }}
                    />
                    {getLabel()}
                    {props.fElem.info && <Popover info={props.fElem.info}/>}
                </div>

            case 6: //Checkboxen (Multiple Select)
                return <MultipleChoice
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    name={props.fElem.uuid}
                    uuid={props.fElem.uuid}
                    options={props.fElem.options}
                    optionWidth={props.fElem['options_width'] ? props.fElem['options_width'] : 'basis-full'}
                    disabled={isDisabled}
                    value={formValues[props.fElem.uuid] ? formValues[props.fElem.uuid] : {}}
                    onValueChange={(e, optionUUID) => {
                        dispatch(updateMultipleSelects({
                            id: props.fElem.uuid,
                            optionUUID: optionUUID,
                            value: e.target.value
                        }))
                    }}
                />
            case 15:
            case 7: // Multiple Choices (Single Select) --> Radio
                return <Radio
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    name={props.fElem.uuid}
                    uuid={props.fElem.uuid}
                    options={props.fElem.options}
                    optionWidth={props.fElem['options_width'] ? props.fElem['options_width'] : 'basis-full'}
                    disabled={isDisabled}
                    value={formValues[props.fElem.uuid] ? formValues[props.fElem.uuid] : {}}
                    onValueChange={(e, optionUUID) => {
                        dispatch(updateSingleSelects({
                            id: props.fElem.uuid,
                            optionUUID: optionUUID,
                            value: e.target.value
                        }))
                    }}
                />
            case 8: // date
                return <Datepicker
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    disabled={isDisabled}
                    selected={getFieldValue(props.fElem.uuid) ? parse(getFieldValue(props.fElem.uuid), "dd.MM.yyyy", new Date()) : null}
                    onChangeHandler={(e) => dispatch(updateValue({id: props.fElem.uuid, value: e}))
                    }
                />;
            case 9: // Divider
                return <div className='h-2'></div>
            // case 10 is Submit Button -> only the label is shown
            case 11: //Input (Mail)
                return <Input
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    disabled={isDisabled}
                    value={getFieldValue(props.fElem.uuid)}
                    type="email"
                    className={!validValue && 'border-red-600'}
                    onChange={(e) => validateValue(e.target.value)}/>
            case 12: // Input number
                return <Input
                    required={!initialized && !props.fElem.initial_visible || (!props.fElem.hidden ? props.fElem.required : false)}
                    disabled={isDisabled}
                    value={getFieldValue(props.fElem.uuid)}
                    className={!validValue && 'border-red-600'}
                    type="number"
                    onChange={(e) => validateValue(e.target.value)}/>;
            case 13: //Ranking
                return <RatingSingle
                    disabled={isDisabled}
                    value={getFieldValue(props.fElem.uuid)}
                    options={props.fElem.options}
                    changeEvent={(e) => dispatch(updateValue({
                        id: props.fElem.uuid,
                        value: e
                    }))}/>;
            case 14: //Multiple Ranking
                return <RatingMultiple
                    disabled={isDisabled}
                    value={formValues[props.fElem.uuid] ? formValues[props.fElem.uuid] : {}}
                    options={props.fElem.options}
                    onValueChange={(option, value) => {
                        dispatch(updateMultipleRating({
                            fieldId: props.fElem.uuid,
                            optionId: option,
                            optionValue: value
                        }))
                    }}/>;
            default:
                return 'Error'
        }

    }
    
    const getLabel = () => {

        if (props.fElem.label) {

            let label = props.fElem.label;

            if (props.fElem.required == 1) {
                label = addRequiredToLabel(label);
            }

            if (props.fElem.type !== 0 && props.fElem.type !== 1 && props.fElem.type !== 9) {
                return <label class="mb-1.5" dangerouslySetInnerHTML={{__html: label}}/>
            }
        }

        return ''
    }

    return (
        <div className={`basis-full md:${props.fElem.width} overflow-x-auto h-max`}
             hidden={!initialized && !props.fElem.initial_visible || (props.fElem.hidden ? props.fElem.hidden : false)}>
            {(!('type' in props) || props.type === 'form') &&
                <div className='mx-2.5 mb-4'>
                    <div className="flex inline">
                        <span
                            className='flex'>{props.fElem.type !== 5 && getLabel()}</span>
                        {props.fElem.info && props.fElem.type !== 5 && <Popover info={props.fElem.info}/>}
                    </div>
                    {getFormElementByTypeId(props.fElem.hidden)}
                </div>
            }
            {
                props.type === 'quiz' &&
                <div className='mx-2.5 mb-4'>
                    <div className="flex inline">
                        <span
                            className='flex'>{props.fElem.type !== 5 && getLabel()}</span>
                        {props.fElem.info && props.fElem.type !== 5 && <Popover info={props.fElem.info}/>}
                    </div>
                    {getFormElementByTypeId(props.fElem.hidden)}
                </div>
            }
            {
                props.type === 'survey' &&
                <div className='mx-2.5 mb-4 md:mb-10'>
                    <div className="flex inline md:mb-5">
                        <span
                            className='flex'><div
                            className="avg font-semibold text-sanofi-dark-lila text-xl">{props.fElem.type !== 5 && getLabel()}</div>
                         </span>
                        {props.fElem.info && props.fElem.type !== 5 && <Popover info={props.fElem.info}/>}
                    </div>
                    {getFormElementByTypeId(props.fElem.hidden)}
                </div>
            }
        </div>
    )
}
