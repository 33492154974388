import React from "react";
import "./style/infotile.css"

// Create Form by given bundle
export default function InfoTile(props) {
    //https://heroicons.com/
    const getSVGByName = () => {
        switch (props.iconType) {
            case 'time':
                return <img className="lg:w-1/3 w-1/2 infotile-svg-max-width"
                            src="/react/frontend/src/components/InfoTile/svg/time.svg"
                            alt="Referent-Icon"/>

            case 'place':
                return <img className="lg:w-1/3 w-1/2 infotile-svg-max-width"
                            src="/react/frontend/src/components/InfoTile/svg/location.svg"
                            alt="Referent-Icon"/>
            case 'referent':
                return <img className="lg:w-1/3 w-1/2 infotile-svg-max-width"
                            src="/react/frontend/src/components/InfoTile/svg/referent.svg"
                            alt="Referent-Icon"/>
            case 'certificate':
                return <img className="lg:w-1/3 w-1/2 infotile-svg-max-width"
                            src="/react/frontend/src/components/InfoTile/svg/certificate.svg"
                            alt="Certificate-Icon"/>
            case 'therapyarea':
                return <img className="lg:w-1/3 w-1/2 infotile-svg-max-width"
                            src="/react/frontend/src/components/InfoTile/svg/therapyarea.svg"
                            alt="Therapy-Area-Icon"/>
        }
    }
    return (
        <div
            className={"flex flex-col justify-start gap-4 rounded-md bg-sanofi-gray p-5 md:p-10 text-center w-100 " + props.className}>
            <div className="center-content color-sanofi-lila ">
                {getSVGByName()}
            </div>
            <div className="center-content text-xl color-sanofi-dark-lila font-bold ">{props.title}</div>
            {props.speaker &&
                <div className="center-content color-sanofi-dark-lila gap-2 " style={{flexDirection: "column"}}>{
                    props.speaker.map(obj => <p
                        className="center-content color-sanofi-dark-lila ">{obj.speaker_name}</p>)
                }</div>}
            {props.accreditation &&
                <div className="center-content color-sanofi-dark-lila gap-2 " style={{flexDirection: "column"}}>{
                    props.accreditation.map(obj => <p
                        className="center-content color-sanofi-dark-lila ">{obj.accreditation_points}</p>)
                }</div>}
            {props.description && <div className="center-content color-sanofi-dark-lila ">{props.description}</div>}
        </div>
    )
}

