import React, {useEffect, useState, Fragment, useCallback} from "react";
import {TranslateText} from "../../../libs/LanguageProvider";

export default function Box(props) {

    let defaultClass = "h-auto sm:h-32 grow self-auto rounded-xl p-4 mb-4 ";
    let defaultStyleColor = " bg-sanofi-rebate-box-default offer-rebate-box-inactive";
    let activeStyleColor = " bg-sanofi-rebate-box-active offer-rebate-box-active";

    return (

        <div className={defaultClass + (props.active === true ? activeStyleColor: defaultStyleColor + (props.className || ""))}>
            <h1 className="text-3xl">{props.value}{props.type === "percent" ? "%" : " "+TranslateText("offer-label-currency")} {TranslateText("offer-rebate-box-rebate")}</h1>
            <p>
                <div className={"text"}>
                    {props.description}
                </div>
            </p>
        </div>

    )


}