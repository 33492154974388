import React, {Fragment, useEffect, useState} from "react";

export default function ELearningCard(props) {
    const [isOpen, setIsOpen] = useState(props.open ? props.open : false)

    useEffect(() => {
        setIsOpen(props.open)

    }, [props.open])


    const handleCard = () => {
        if (!props.disabled) {
            setIsOpen(!isOpen)
        }
    }

    return (
        <Fragment>
            <div onClick={handleCard} style={{cursor: props.disabled ? "auto" : "pointer"}}
                 className="bg-sanofi-gray rounded-md p-3 flex justify-between">

                <div className="inline-flex">

                    {props.svg && <Fragment>{props.svg}</Fragment>}

                    <div className="ml-3 text-bold text-xl text-sanofi-dark-lila">
                        {props.counter && <Fragment>{props.counter}. </Fragment>}{props.title}
                    </div>
                </div>

                {
                    isOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" className="w-6 h-6 mt-1 color-sanofi-dark-lila">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" className="w-6 h-6 mt-1 color-sanofi-dark-lila">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                        </svg>
                }

            </div>

            {
                isOpen &&
                <div className="border border-sanofi-gray rounded-md p-5 -mt-2">
                    {props.children}
                </div>
            }


        </Fragment>
    )
}