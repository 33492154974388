import React, {useRef,useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';
import Checkbox from "../Checkbox";
import Popover from "../../Popover";

export default function MultipleChoice(props) {

    let firstCheckboxRef = useRef();

    const myProps = {
        ...props.styles,
        className: (props.overwriteClassName === undefined
            ? ""
            : "") + props.className,
        children: undefined,
    };

    const isAnyChecked = props.options.some((option) => props.value[option.uuid] === option.value)

    return (
        <div className='flex flex-col md:flex-row flex-wrap'>
            {
                props.options && props.options.length > 0 && props.options.map((option, index) => {
                    if(props.required && index == 0) {
                        return <div className={props.optionWidth + ' flex'}>
                            <Checkbox
                                ref={firstCheckboxRef}
                                className='accent-sanofi-dark-lila'
                                type="checkbox"
                                value={option.value}
                                checked={props.value[option.uuid] === option.value}
                                onClick={(e) => {props.onValueChange(e, option.uuid)}}
                                name={props.name || 'checkbox'}
                                required={isAnyChecked? false : true}
                                onInvalid={(e) => {e.target.setCustomValidity('Please select at least one of these options.')}}
                                onInput={e => e.target.setCustomValidity('')}
                            />
                            <label className='flex'>{option.value} {option.info && <Popover info={option.info}/>} </label>
                        </div>
                    } else {
                        return <div className={props.optionWidth + ' flex'}>
                            <Checkbox
                                className='accent-sanofi-dark-lila'
                                type="checkbox"
                                value={option.value}
                                checked={props.value[option.uuid] === option.value}
                                onClick={(e) => { props.onValueChange(e, option.uuid) }}
                                name={props.name || 'checkbox'}
                                onInput={firstCheckboxRef.current && firstCheckboxRef.current.setCustomValidity('')}
                            />
                            <label className='flex'>{option.value} {option.info && <Popover info={option.info}/>} </label>
                        </div>
                    }
                }
                )
            }
        </div>
    )


}