import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import axios from "axios";
import OrderRow from "../../components/Orders/OrderTable/OrderRow";
import OrderDetailRow from "../../components/Orders/OrderTable/OrderDetailRow";
import {getLanguageContent, TranslateText} from "../../libs/LanguageProvider";
import Footer from "../../components/Footer";

export default function Orders(props) {
    const [showdetails, setShowDetails] = useState([])
    const [orderdata, setOrderdata] = useState(false)
    const [tablehtml, setTablehtml] = useState([])
    const [isAlert, setIsAlert] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getMyordersList/order",
            data: {}
        }).then(data => {
            setOrderdata(data.data)
            let showElem = [];
            Object.keys(data.data).map((order, index) => {
                showElem[index] = false;
            })
            setShowDetails(showElem)
        });
    }, []);

    useEffect(() => {
        getTabledata()
    }, [orderdata, showdetails]);

    const updateShowDetails = (index) => {
        let showdet = showdetails;
        showdet[index] = !showdet[index];
        setShowDetails(showdet)
        // Update table manually
        getTabledata()
    }

    const getTabledata = () => {
        let htmldata = []
        Object.keys(orderdata).map((order, index) => {
            let generalRow = [];
            let detailRow = [];
            let totalamount = 0;
            (orderdata[order]).map((article, articleIndex) => {
                totalamount += Number(article['quantity']) // calculate total quantity of medicine

                detailRow.push(<OrderDetailRow
                    showElem={showdetails[index]}
                    order={article}
                />)
            })

            generalRow.push(<OrderRow showElem={showdetails[index]}
                                      updateShowDetails={updateShowDetails}
                                      rowindex={index}
                                      order={orderdata[order]}
                                      totalAmount={totalamount}
                                      setIsAlert={() => {
                                          setIsAlert(true)
                                      }}
                                      setIsSuccess={() => {
                                          setIsSuccess(true)
                                      }}
            />)

            htmldata.push(generalRow)
            htmldata.push(detailRow)


        })
        setTablehtml(htmldata);
    }

    return (
        <Fragment>
            <Page>
                <TopBar/>
                <Hero/>

                <ContentArea className='h-100'>
                    <div className='px-4 h-100 flex flex-col'>
                        <h1 className="text-4xl color-sanofi-dark-lila font-bold mb-6">{TranslateText("orders-title")}</h1>


                        {(orderdata && Object.keys(orderdata).length > 0) ?
                            <div className="w-table-orders">
                                {(isAlert) ?
                                    <div
                                        className="alert bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-yellow-700 inline-flex items-center w-full alert-dismissible fade show"
                                        role="alert">
                                        <strong
                                            className="mr-1">{TranslateText("orders-error-pdf-generation-title")}</strong>
                                        {TranslateText("orders-error-pdf-generation-text")}
                                        <button type="button"
                                                className="btn-close box-content w-4 h-4 p-1 ml-auto text-red-900 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-yellow-900 hover:opacity-75 hover:no-underline"
                                                data-bs-dismiss="alert" aria-label="Close" onClick={() => {
                                            setIsAlert(false)
                                        }}></button>
                                    </div> : <></>
                                }
                                {
                                    (isSuccess) ?
                                        <div
                                            className="success bg-green-100 rounded-lg py-5 px-6 mb-3 text-base text-green-900 inline-flex items-center w-full alert-dismissible fade show"
                                            role="success">
                                            <strong
                                                className="mr-1">{TranslateText("orders-success-pdf-generation-title")}</strong>
                                            {TranslateText("orders-success-pdf-generation-text")}
                                            <button type="button"
                                                    className="btn-close box-content w-4 h-4 p-1 ml-auto text-green-900 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-green-900 hover:opacity-75 hover:no-underline"
                                                    data-bs-dismiss="alert" aria-label="Close" onClick={() => {
                                                setIsSuccess(false)
                                            }}></button>
                                        </div> : <></>
                                }
                                <div
                                    className="text-center text-sanofi-white sanofi-table-container offer-product-table-text sanofi-table-border-radius">
                                    <table
                                        className="offer-product-table text-left text-gray-500 dark:text-gray-400 ">
                                        <thead
                                            className="text-white bg-sanofi-table-head-bg-default offer-product-table-header bg-sanofi-table-head-bg-default">
                                        <tr className="">
                                            <th
                                                className="orders-icon-row px-6 py-3 text-sanofi-white offer-product-table-text">

                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                                {TranslateText("orders-label-date")}
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                                {TranslateText("orders-label-ordernumber")}
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                                {TranslateText("orders-label-status")}
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                                {TranslateText("orders-label-totalAmount")}
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                                {TranslateText("orders-label-rebate")}
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                                {TranslateText("orders-label-price")}
                                            </th>
                                            <th scope="col"
                                                className="px-6 py-3 text-sanofi-white offer-product-table-text">
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="table-body-orders">
                                        {tablehtml}
                                        </tbody>
                                    </table>
                                </div>
                            </div> :
                            <div>{TranslateText("orders-noOrders-text")}</div>
                        }
                    </div>
                </ContentArea>
                <Footer/>
            </Page>
        </Fragment>
    )
}

