import React, {useEffect, useState} from "react";
import axios from "axios";
import Page from "../../../components/Page";
import TopBar from "../../../components/Header/TopBar";
import ContentArea from "../../../components/ContentArea";
import PDBBrandList from "../../../views/PDB/BrandList/PDBBrandList";
import Footer from "../../../components/Footer";
import {LanguageProvider} from "../../../libs/LanguageProvider";
import {fetchTranslations} from "../../../index";
import {extractFileBaseFromUrl} from "../../../helper/Url";


export default function PDBBrandlistApp() {

    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoding] = useState(true);
    const [initialized, setInitialized] = useState(false);

    let preview = (window.location.pathname ?? '').split('/').find(element => element == 'preview') != undefined;
    const [isPreview, setIsPreview] = useState(preview);

    window.addEventListener('load', () => {
        const page = document.querySelectorAll('html, .bg-sanofi-page');
        page.forEach((el) => {
            el.style.backgroundColor = "white";
        })
    })

    const init = () => {
        axios({
            dataType: "json",
            method: "post",
            url: "/pdb/getBrandData/",
            // data: {}
        }).then(data => {
            return data.data;
        }).then(result => {
            setIsLoding(false);

            if(result.status == 'success') {
                for (let brand of result.data) {
                    brand.imgAlt = extractFileBaseFromUrl(brand.img ?? '');
                }

                setBrands(result.data ?? []);
            }

            setInitialized(true);
        });
    }

    useEffect(() => {
        init();
    }, []);

    // ------------------------------------------------


    // ------------------------------------------------

    return (
        <LanguageProvider fetchTranslations={fetchTranslations}>
            <Page>
                <TopBar type={'sanoficonnect'}/>
                <div class={"pt-10 md:pt-0"}>
                    <div class={"app cms grow flex relative"}>
                        <div class={"page-section grow flex content-start md:pt-3 sm:pt-0 lg:pt-7 md:pr-3 sm:pr-0 lg:pr-7 md:pl-3 sm:pl-0 lg:pl-7 md:pb-3 sm:pb-0 lg:pb-7 flex-row sm:flex-row lg:flex-row justify-center sm:justify-center lg:justify-center items-stretch sm:items-stretch lg:items-stretch container"} style={{justifyContent:"center"}}>
                            <div id={"pdb-list"} className={"product-details"}>
                                {initialized && brands && brands.length > 0 &&
                                    <PDBBrandList brands={brands} preview={isPreview}/>
                                }

                                <div id={"load-overlay"} className={"container" + (isLoading ? ' active' : '')} style={{position: "absolute", width: "100%", height: "100%"}}>
                                    <div className={"bg"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="load-icon h-12 w-12" fill="none" viewBox="0 0 24 24"
                                             stroke="white" strokeWidth={1.5}>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Page>
        </LanguageProvider>
    );
}

