import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import React, {Fragment, useEffect, useState} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import axios from "axios";
import {getUserLanguages} from "../../helper/User";
import {useParams, useLocation} from "react-router-dom";
import Spinner from "../../components/Spinner";


export default function DocCheckLogin(props) {

    let docCheckId = props.docCheckId;
    
    return (
        <Fragment>

                <Page>
                        <TopBar type={"sanoficonnect"}/>

                        <Hero 
                            type={'university'} 
                            headerType={""} 
                            menuType={0}
                            />

                    <ContentArea>

                        <iframe className="mx-auto mt-4" align="center" frameborder="0" width="467" height="231" name="dc_login_iframe" id="dc_login_iframe" src={`https://login.doccheck.com/code/de/${docCheckId}/login_xl/`}>
                            <a href={`https://login.doccheck.com/code/de/${docCheckId}/login_xl/`} target="_blank">LOGIN</a>
                        </iframe>
         
                    </ContentArea>

                <Footer/>
      
            </Page>

        </Fragment>
    )
}

