import React, {useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';

export default function Select(props){

    const myProps = {
        ...props,
        className: (props.overwriteClassName === undefined
            ? "form-select appearance-none\n" +
            "block\n" +
            "w-full\n" +
            "p-2.5\n" +
            "text-base\n" +
            "bg-white bg-clip-padding bg-no-repeat\n" +
            "border border-solid border-gray-300\n" +
            "rounded\n" +
            "transition\n" +
            "ease-in-out\n" +
            "m-0\n" +
            "text-sm text-gray-900 font-sans border-gray-300 border\n" +
            "focus:outline-none border-gray-300\n" +
            "focus:ring-border-sanofi-dark-lila\n" +
            "focus:border-sanofi-dark-lila "
            : "" ) +props.className,
        children: undefined,
    };


    return (
        <div className="flex justify-center">
            <div className="mb-3 w-full">
                <select {...myProps}>
                    {props.children}
                </select>
            </div>
        </div>
    )


}