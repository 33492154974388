import React, {forwardRef,useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';

export default forwardRef(function Checkbox(props, ref) {

    const myProps = {
        ...props,
        className: (props.overwriteClassName === undefined
            ? "shrink-0 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sanofi-dark-lila checked:border-sanofi-dark-lila focus:outline-none transition duration-200 mt-1 ml-1 mr-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer "
            : "" ) +props.className,
        children: undefined,
    };

    return (
        <input ref={ref} {...myProps} />
    )


})