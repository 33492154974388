//Required by CMS until forms are moved 
import React, {Fragment} from 'react';
import store from "../../frontend/src/components/Formdesigner/store/FormStore"; //Expected location in the project
import FormBundle from "../../frontend/src/components/Formdesigner";
import { Provider } from "react-redux";

export default function FormPage(props){    
    const component = props.component;
    
    return(
        <Fragment>
            { 
                <Provider store={store}>
                    <FormBundle formdata={props.formdata} type={props.type} parentData={props.parentData} />
                </Provider>
            }
        </Fragment>
    )
}