import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Footer from "../../components/Footer";

import OfferScreen from "../../components/OfferScreen";
import {
    useParams
} from "react-router-dom";


export default function Offer(props) {

    let {slug} = useParams();

    return (

        <Fragment>

            <Page>
                <TopBar type='marketplace'/>
                <OfferScreen slug={slug}/>
                <Footer/>
            </Page>

        </Fragment>

    )
}