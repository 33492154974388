import React from 'react';
import { TranslateText } from '../../../../helpers/language';
import { generateCSSString, getResponsiveStyles } from '../../../CmsPage/helpers/style';

import AccordionList from './components/AccordionList';
import Accordion from './components/AccordionList/components/Accordion';
import Button from './components/Button';
import Card from './components/Card';
import Carousel from './components/Carousel';
import Container from './components/Container';
import Form from './components/Form';

import UniversityCards from './components/Card/components/UniversityCards'
import Html from './components/Html';
import Menu from './components/Menu';
import Image from './components/Image';
import Title from './components/Title'
import Text from './components/Text';

import Header from './components/Header';
import Hero from './components/Hero';
import MainContainer from './components/MainContainer';
import Footer from './components/Footer';


export default function Component(props) {

    const {component, ...rest} = props;
   
    const getCssValueContainer = (componentName) => {
        switch (componentName){
            case `cmsPageHeader`:
                return 'w-full sticky top-0 z-10';
            case `cmsComponentContainer`:
                return `${generateCSSString(component, [...getResponsiveStyles('container', 'Width'),])} flex flex-column`;
            case 'cmsComponentCard':
                return `flex flex-col grow w-full`;
            case 'cmsPageMainContainer':
                return `grow flex relative`;
            default:
                return 'w-full';
        }
    }
    const cssValueContainer = getCssValueContainer(component.component);
    
    const getComponent = () => {
        switch (component.component) {
            case 'cmsComponentAccordion':
                return <Accordion component={component} {...rest}/>
            case 'cmsComponentAccordionList':
                return <AccordionList component={component} {...rest}/>
            case 'cmsComponentButton':
                return <Button component={component}/>
            case 'cmsComponentCard':
                return <Card component={component}/>
            case 'cmsComponentCardList':
                return <UniversityCards component={component}/>
            case 'cmsComponentCarousel':
                return <Carousel component={component} {...rest}/>
            case 'cmsComponentContainer':
                return <Container component={component} {...rest}/>       
                case 'cmsComponentForm':
                return <Form component={component} {...rest} parentData={props.parentData}/>
            case 'cmsComponentHtml':
                return <Html component={component}/>
            case 'cmsComponentImage':
                return <Image component={component}/>
            case 'cmsComponentMenu':
                return <Menu component={component} {...rest}/>
            case 'cmsComponentTitle':
                return <Title component={component}/>
            case 'cmsComponentText':
                return <Text component={component}/>
            case 'cmsPageHeader':
                return <Header component={component} {...rest}/>  
            case 'cmsPageHero':
                return <Hero component={component} {...rest}/>
            case 'cmsPageMainContainer':
                return <MainContainer component={component} {...rest}/>
            case 'cmsPageFooter':
                return <Footer component={component} {...rest}/>    
            default:
                return <div>{TranslateText("cmsComponentMissing")}</div>
        }
    }


    return (
        <div id={'CMS-' + component.uuid} className={`cms ${cssValueContainer}`} >
            {
                getComponent()
            }
        </div>
    )
}