import React, { Fragment } from "react";
import { generateCSSString } from "../../../../CmsPage/helpers/style";
import { getPaddingStyles, getResponsiveStyles } from "../../../../CmsPage/helpers/style";
import ComponentFrontend from "..";
import ComponentBackend from "../../../../CmsDesigner/components/Component";


export default function Footer(props) {

    const {component, draggable, ...rest} = props;

    const backend = props.backend ?? false;

    const cssValue = [...getPaddingStyles('footer'),
                            ...getResponsiveStyles('footer', 'FlexDirection'), 
                            ...getResponsiveStyles('footer', 'JustifyContent'), 
                            ...getResponsiveStyles('footer', 'AlignItems')
                        ];
                        

    return (
        <Fragment>
                <div
                    id={`'Footer-${component.uuid}`} 
                    className={`${generateCSSString(component, cssValue)} flex flex-wrap w-full`}
                    style={{
                            backgroundColor: `${component.footerBackground_color ? component.footerBackground_color : 'none'}`
                        }}
                    >
                        {
                            component.children && component.children.map((child) => {
                                if(backend) 
                                    return <ComponentBackend component={child} {...rest}/>
                                return <ComponentFrontend component={child} {...rest}/>
                            })
                        }
                    </div>
        </Fragment>
    )
}