import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import axios from "axios";
import {useModal} from "../../../libs/ModalProvider";
import SanofiButton from "../../../components/SanofiButton";
import {TranslateText} from "../../../libs/LanguageProvider";


export default function Contact(props) {

    const [data, setData] = useState({})
    const [hasLoaded, setHasLoaded] = useState(false)
    const { setModal, closeModal } = useModal()

    useEffect(() => {

        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/fetchUserSA",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setErrorState(true);
                return;
            }

            if (data.data.data) {
                const buff = {... data.data.data};
                setData(buff);
                setHasLoaded(true);
            }

        });


    }, [])

    return (

        <Fragment>

            {hasLoaded &&
                <div className={"flex items-center justify-center flex-col "}>
                    <h2 className={"text-4xl color-sanofi-dark-lila font-bold text-center"}>{TranslateText("contact-popup-title-heading")}</h2>

                    <div className={"mt-8 text-center w-128"}>
                        <img src={data.picture} alt={data.name} className={"inline-block h-70 rounded-full ring-2 ring-grey"}/>
                        <h2 className={"text-2xl color-sanofi-dark-lila font-bold mt-8"}>{data.name}</h2>

                        <div className={"mt-8 mb-8 text-lg"}>
                            <div>
                                <span className={"flex-1 text-right pl-4 pt-2 font-bold"}>{TranslateText("contact-popup-tel")}</span>
                                <span className={"flex-1 text-left p-2"}>
                                    <a href={"tel:"+data.phone}>{data.phone}</a>
                                </span>
                            </div>
                            <div>
                                <span className={"flex-1 text-right pl-4 pt-2 font-bold"}>{TranslateText("contact-popup-email")}</span>
                                <span className={"flex-1 text-left p-2"}>
                                    <a href={"mailto:"+data.email}>{data.email}</a>
                                </span>
                            </div>
                        </div>
                        <dic className={"pt-4 mt-8"}>
                            <SanofiButton onClick={(e) => {closeModal()}}>
                                {TranslateText("contact-popup-close-button-label")}
                            </SanofiButton>
                        </dic>
                    </div>

                </div>
            }

            {!hasLoaded &&
                <div className="flex p-8 items-center justify-center">
                    <div className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                </div>
            }

        </Fragment>

    )
}