import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import {faRotateRight, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import Footer from "../../components/Footer";
import {TranslateHtml, TranslateText, Translate, getLanguageContent} from "../../libs/LanguageProvider";
import Multiselect from 'multiselect-react-dropdown';
import Typeahead from "../../components/Form/Typeahead";
import SanofiButton from "../../components/SanofiButton";
import {useModal} from "../../libs/ModalProvider";
import MessagePrompt from "../Modals/MessagePrompt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GLN from "../../components/GLN";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/solid";
import UniversityCards from "../../../../../cms/react/src/CmsManager/CmsPage/components/Component/components/Card/components/UniversityCards"
import {generateCSSString} from "../../../../../cms/react/src/CmsManager/CmsPage/helpers/style";
import UniversityCard from "../../../../../cms/react/src/CmsManager/CmsPage/components/Component/components/Card/components/UniversityCards/components/UniversityCard";


export default function MyContent(props) {


    const [data, setData] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [displayDoneWebs, setDisplayDoneWebs] = useState(true)

    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/webinar/fetchMyWebinars",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                return;
            }

            if (data.data.data) {
                setData(data.data.data)
            }
        });


    }, [])

    useEffect(() => {
        setHasLoaded(true);

    }, [data])


    const hasCompleted = () => {

        for (let i = 0; i < data.length; i++) {
            if (data[i].is_done && data[i].completed) {
                return true;
            }
        }

        return false;

    }

    const hasOpen = () => {

        for (let i = 0; i < data.length; i++) {
            if (!data[i].is_done && !data[i].completed) {
                return true;
            }
        }

        return false;

    }


    return (

        <Fragment>

            <Page>
                <TopBar type={"university"}/>
                <Hero type={"university"}/>
                <ContentArea>

                    <h1 className={"text-4xl pb-8 color-sanofi-dark-lila font-bold"}>{getLanguageContent("my-content-title", "Meine Fortbildungen")}</h1>

                    {TranslateHtml("my-content-pre")}

                    {!hasLoaded &&
                        <div className="flex items-center justify-center h-screen">
                            <div
                                className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                        </div>
                    }

                    {hasLoaded &&
                        <>
                            <>
                                {data.length > 0 &&
                                    <label
                                        className='themeSwitcherTwo border-2 border-[#22004C] rounded-full relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white'>
                                        <input
                                            type='checkbox'
                                            className='sr-only'
                                            checked={displayDoneWebs}
                                            onChange={() => {
                                                setDisplayDoneWebs(!displayDoneWebs)
                                            }}
                                        />
                                        <span
                                            className={`flex items-center space-x-[6px] rounded-full py-2 px-[18px] text-sm font-medium ${
                                                displayDoneWebs ? 'text-white color-white text-primary bg-[#22004C]' : 'text-body-color'
                                            }`}
                                        > {getLanguageContent("my-content-finished", "Abgeschlossene")}</span>
                                        <span
                                            className={`flex items-center space-x-[6px] rounded-full py-2 px-[18px] text-sm font-medium ${
                                                !displayDoneWebs ? 'text-white color-white bg-[#22004C]' : 'text-body-color'
                                            }`}
                                        > {getLanguageContent("my-content-registered", "Registrierte")} </span>
                                    </label>
                                }
                            </>


                            {!displayDoneWebs &&
                                <>
                                    {hasOpen() &&
                                        <div
                                            className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-5 pb-5 pl-0 pr-0 gap-x-5 gap-y-5`}>
                                            {
                                                data.map(elem => {
                                                    return !elem.is_done ? <UniversityCard elem={elem}/> : null
                                                })
                                            }
                                        </div>
                                    }

                                    {!hasOpen() &&
                                        <p className={"pt-3 pb-8"}>{getLanguageContent("my-content-no-open", "Noch keine")}</p>
                                    }
                                </>
                            }

                            {displayDoneWebs &&
                                <>
                                    {hasCompleted() &&
                                        <div
                                            className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-5 pb-5 pl-0 pr-0 gap-x-5 gap-y-5`}>
                                            {
                                                data.map(elem => {
                                                    return (elem.is_done && elem.completed) ?
                                                        <UniversityCard elem={elem}/> : null
                                                })
                                            }
                                        </div>
                                    }

                                    {!hasCompleted() &&
                                        <p className={"pt-3 pb-8"}>{getLanguageContent("my-content-no-completed", "Noch keine")}</p>
                                    }
                                </>
                            }

                        </>
                    }

                    {TranslateHtml("my-content-post")}

                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>

    )
}