import React from "react";


export default function RatingMultiple(props) {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <div className="w-full">
            <table className="w-full ratingMultiple">
                <tbody>
                {props.options && props.options.length > 0 && props.options.map(option => {
                    return <tr>
                        <th className="text-black text-left"><strong>{option.value}</strong></th>
                        {
                            numbers.map(num => {
                                return <td><input className=""
                                                  type="radio"
                                                  value={num}
                                                  checked={props.value[option.uuid] === num}
                                                  onClick={() => props.onValueChange(option.uuid, num)}/></td>
                            })
                        }
                    </tr>
                })
                }
                </tbody>
            </table>
        </div>
    );
}



