// Get webinar, elearning, product training language (0, 1, 2) as string
export const getLanguageAsString = (lang) => {
    if (typeof lang === 'string' || lang instanceof String) {
        if(Number(lang) == 0) {
            return 'de';
        }
        if(Number(lang) == 1) {
            return 'fr';
        }
        if(Number(lang) == 2) {
            return 'it';
        }
        return lang;
    } 
    if (typeof lang === 'number') {
        if(lang == 0) {
            return 'de';
        }
        if(lang == 1) {
            return 'fr';
        }
        return 'it';
    }
    return null; 
}