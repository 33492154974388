import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import React, {useEffect} from "react";


export const formSlice = createSlice({
    name: 'formhandling',
    initialState: {
        formConditionals: [], // list of conditionals -> get by fetch - rules when to update fields
        formFields: [], // list of fields of the form
        formValues: {}, // values the user set in the fields etc
        loaded: false
    },
    reducers: {

        /**
         * Full Replacement
         * @param state formConditionals
         * @param action payload (Array) -> array of the conditions for this Form
         */
        setConditionals: (state, action) => {
            state.formConditionals = action.payload
        },


        /**
         * Full Replacement
         * @param state formFields
         * @param action payload (Array) -> array of the fields
         */
        setFields: (state, action) => {
            state.formFields = action.payload
        },

        /**
         *
         * @param state
         * @param action
         */
        updateValue: (state, action) => {
            if (state.formValues[action.payload.id]) {
                state.formValues[action.payload.id].value = action.payload.value;
            } else {
                state.formValues[action.payload.id] = {value: action.payload.value}
            }

            let updatedField = state.formFields.find(fElem => fElem.uuid == action.payload.id);
            updatedField.lastValue = action.payload.value;

            if (updatedField !== undefined) {
                updateFormByConditionals(state.formConditionals, state.formFields, updatedField, action.payload.value);
            }
        },

        /**
         *
         * @param state
         * @param action
         */
        updateSelect: (state, action) => {
            const options = action.payload.options
            const value = action.payload.value
            const id = action.payload.id

            // search for label of option
            {
                options.map((option) => {
                    if (option.uuid === value) { // right option
                        if (state.formValues[id]) {
                            state.formValues[id].value = option.value; // set label of option
                        } else {
                            state.formValues[id] = {value: option.value}

                        }
                    }
                })
            }
        },

        /**
         *
         * @param state
         * @param action
         */
        updateMultipleSelects: (state, action) => {
            const fieldId = action.payload.id;
            const optionId = action.payload.optionUUID;
            const optionValue = action.payload.value;

            if (!state.formValues[fieldId]) {
                state.formValues[fieldId] = {};
            }

            const updatedValue = [];
            if(state.formValues[fieldId][optionId]) {
                delete state.formValues[fieldId][optionId];
            } else {
                state.formValues[fieldId][optionId] = optionValue;
            }

            for (const key in state.formValues[fieldId]) {
                updatedValue.push(state.formValues[fieldId][key]);
            }

            let updatedField = state.formFields.find(fElem => fElem.uuid == action.payload.id);
            updatedField.lastValue = updatedValue;

            if (updatedField !== undefined) {
                updateFormByConditionals(state.formConditionals, state.formFields, updatedField, updatedValue);
            }
        },

        /**
         *
         * @param state
         * @param action
         */
        updateSingleSelects: (state, action) => {
            const fieldId = action.payload.id;
            const optionId = action.payload.optionUUID;
            const optionValue = action.payload.value;
            
            state.formValues[fieldId] = {};
            state.formValues[fieldId][optionId] = optionValue;

            const updatedValue = { value: optionValue };

            let updatedField = state.formFields.find(fElem => fElem.uuid == action.payload.id);
            updatedField.lastValue = updatedValue;

            if (updatedField !== undefined) {
                updateFormByConditionals(state.formConditionals, state.formFields, updatedField, updatedValue);
            }
        },

        /**
         *
         * @param state
         * @param action
         */
        updateMultipleRating: (state, action) => {
            const fieldId = action.payload.fieldId
            const optionId = action.payload.optionId
            const optionValue = action.payload.optionValue


            if (!state.formValues.hasOwnProperty(fieldId)) {
                state.formValues[fieldId] = {};
            }

            state.formValues[fieldId][optionId] = optionValue;
        },

        /**
         * Initializes the Form Fields by the initial values of the form and the conditional rules
         *
         * @param state
         * @param action
         */
        initFormConditionals: (state, action) => {
            let fieldValue = "__UNDEFINED__";

            state.formFields.forEach(fElem => {
                switch (fElem.type) {
                    case 4: // Select
                        fieldValue = state.formValues[fElem.uuid] != undefined ?
                            state.formValues[fElem.uuid].value :
                            null;
                        break;
                    case 5: // Checkbox Single
                        fieldValue = state.formValues[fElem.uuid] != undefined ?
                            state.formValues[fElem.uuid].value :
                            false;
                        break;
                    case 6: // Checkbox Multiselect
                        fieldValue = state.formValues[fElem.uuid] != undefined ?
                            state.formValues[fElem.uuid] :
                            [];
                        break;
                    case 7: // Radiogroup Single
                        fieldValue = state.formValues[fElem.uuid] != undefined ?
                            state.formValues[fElem.uuid].value :
                            null;
                        break;
                }

                if (fieldValue != "__UNDEFINED__") {
                    updateFormByConditionals(state.formConditionals, state.formFields, fElem, fieldValue);
                }
            })
        }
    },

});

const updateFormByConditionals = (stateConditionals, stateFormFields, updatedField, updatedValue) => {

    let fieldType = updatedField.type;
    let fieldGroupUuid = updatedField.group_uuid;

    let formFieldConditionals = stateConditionals.filter(cElem => cElem.form_field_group_uuid == fieldGroupUuid);

    for (let formFieldConditional of formFieldConditionals) {
        if (formFieldConditional != undefined) {
            let conditionalAction = formFieldConditional.action;

            let hasConditionalUpdate = false;
            let isConditionalActive = false;

            let conditionalTargetField = stateFormFields.find(fElem => fElem.group_uuid == formFieldConditional.form_conditional_field_group_uuid);
            if (conditionalTargetField) {
                let indexOfTarget = stateFormFields.indexOf(conditionalTargetField);

                switch (fieldType) {
                    case 4: // Select
                        let selectOptionString = getOptionByFieldGroupReferences(stateFormFields, formFieldConditional.form_field_group_uuid, formFieldConditional.form_field_value_group_uuid);
                        if (selectOptionString !== false && updatedValue !== null && updatedValue == selectOptionString) {
                            isConditionalActive = true;
                        }
                        hasConditionalUpdate = true;
                        break;
                    case 5: // Checkbox
                        isConditionalActive = updatedValue;
                        hasConditionalUpdate = true;
                        break;
                    case 6: // Checkbox (Multiselect)
                        let checkboxOptionString = getOptionByFieldGroupReferences(stateFormFields, formFieldConditional.form_field_group_uuid, formFieldConditional.form_field_value_group_uuid);
                        if (checkboxOptionString !== false && updatedValue.includes(checkboxOptionString)) {
                            isConditionalActive = true;
                        }

                        hasConditionalUpdate = true;
                        break;
                    case 7: // Radiobuttons (Singleselect)
                        let radioOptionString = getOptionByFieldGroupReferences(stateFormFields, formFieldConditional.form_field_group_uuid, formFieldConditional.form_field_value_group_uuid);
                        if (radioOptionString !== false && updatedValue !== null && updatedValue.value == radioOptionString) {
                            isConditionalActive = true;
                        }

                        hasConditionalUpdate = true;
                        break;
                }

                var lastFieldVal = null;

                if(hasConditionalUpdate) {
                    switch (conditionalAction) {
                        case 0: // show
                            stateFormFields[indexOfTarget].hidden = !isConditionalActive;

                            lastFieldVal = stateFormFields[indexOfTarget].lastValue ?? null;
                            updateFormByConditionals(stateConditionals, stateFormFields, stateFormFields[indexOfTarget], stateFormFields[indexOfTarget].hidden ? null : lastFieldVal);
                            break;
                        case 1: // hide
                            stateFormFields[indexOfTarget].hidden = isConditionalActive;

                            lastFieldVal = stateFormFields[indexOfTarget].lastValue ?? null;
                            updateFormByConditionals(stateConditionals, stateFormFields, stateFormFields[indexOfTarget], stateFormFields[indexOfTarget].hidden ? null : lastFieldVal);
                            break;
                        case 2: // required
                            stateFormFields[indexOfTarget].required = isConditionalActive;
                            break;
                        case 3: // not required
                            stateFormFields[indexOfTarget].required = !isConditionalActive;
                            break;
                    }
                }
            }
        }
    }
}

const getOptionByFieldGroupReferences = (formFields, formfieldGroupUUID, formFieldValueGroupUUID) => {
    let sourceFormField = formFields.find(fElem => fElem.group_uuid === formfieldGroupUUID);
    let option = sourceFormField.options.find(fElem => fElem.group_uuid === formFieldValueGroupUUID);
    if (option != undefined) {
        return option.value;
    }
    
    return false;
}

export const {
    updateValue,
    updateSelect,
    updateMultipleSelects,
    updateSingleSelects,
    updateMultipleRating,
    setFields,
    setConditionals,
    initFormConditionals
} = formSlice.actions

export const getValue = (state, action) => state.formValues

export const getFields = (state, action) => state.formFields

export const getFormConditionals = (state, action) => state.formConditionals

export default formSlice.reducer
