import React, { useState, useEffect } from "react";
import { generateCSSString, getPaddingStyles, getMarginStyles } from "../../../../helpers/style";

export default function Button(props) {

    const component = props.component;

    const cssValueContainer = ['btn_alignment'];
    const cssValue = ['btn_btnWidth', 'btn_fontSizeText', 'btn_borderRadius', ...getPaddingStyles('btn'), ...getMarginStyles('btn')];

    const [backgroundColor, setBackgroundColor] = useState(component.btnBackground_color);
    const [borderColor, setBorderColor] = useState(component.btnBorder_color);
    const [textColor, setTextColor] = useState(component.btnText_color);

    const setHoverCssStyles = () => {
        setBackgroundColor(component.btnHoverBackground_color);
        setBorderColor(component.btnHoverBorder_color);
        setTextColor(component.btnHoverText_color);
    }

    const resetCssStyles = () => {
        setBackgroundColor(component.btnBackground_color);
        setBorderColor(component.btnBorder_color);
        setTextColor(component.btnText_color);
    }

    useEffect(() =>{
        if(backgroundColor !== component.btnBackground_color) 
            setBackgroundColor(component.btnBackground_color);
        if(borderColor !== component.btnBorder_color) 
            setBorderColor(component.btnBorder_color);
        if(textColor !== component.btnText_color) 
            setTextColor(component.btnText_color);
    }, [component.btnBackground_color, component.btnBorder_color, component.btnText_color])


    const getComponent = () => {
        switch (component.btn_btnType) {
            case 'button':
                return (
                    <a className={`text-center ${generateCSSString(component, cssValue)} ${component.btnHideMobile_ == 1? 'hidden sm:block' : 'block'}`}
                        style={{
                            backgroundColor: backgroundColor,
                            border: `1px solid ${borderColor}`,
                            color: textColor
                        }}

                        onMouseEnter={() => setHoverCssStyles()}
                        onMouseLeave={() => resetCssStyles()}
        
                        href={component.btnLink_}
                        target={props.target?? component.btn_target?? '_blank'}
                        onClick={component.onClick ? component.onClick : null}
                    >
                    {component.btnText_}
        
                    </a>
                )
            case 'link':
                return (
                    <a className={`text-center ${generateCSSString(component, cssValue)}`}
                        style = {{color: textColor}}
                        onMouseEnter={() => setTextColor(component.btnHoverText_color)}
                        onMouseLeave={() => setTextColor(component.btnText_color)}
                        href={component.btnLink_}
                        target={props.target?? component.btn_target?? '_blank'}
                    >
                        <u>{component.btnText_}</u>
                    </a>
                    )
        }
    }
    return (
        <div className={`w-full flex ${generateCSSString(component, cssValueContainer)}`}>
            {getComponent()}
        </div>
    )
}