import React, {Fragment} from 'react';
import { generateCSSString, getPaddingStyles } from "../../../../helpers/style";
import { TranslateText } from '../../../../../../helpers/language';
import FormBackend from '../../../../../CmsDesigner/components/Component/components/Form'
import FormPage from '../../../../../../../../../react/src/components/FormPage' //Expected location in the project

export default function Form(props){

    const backend = props.backend;
    const previewPageRoleId = props.previewPageRoleId;
    
    const component = props.component;

    const cssValue = [...getPaddingStyles('form')];

    const getFormType = () => {
        if(component.form_formType == 1) return 'Survey'
        if(component.form_formType == 2) return 'Quiz'
        return 'Form';
    }

    
    return(
        <Fragment>
            {/* { 
                !component.data && !component.formData &&
                <div className='error-text text-center'>{TranslateText('cmsSaveFormFirst')}</div>

            } */}

            { 
                !component.data &&
                <FormBackend component={component} />
            }

            {
                component.data && component.data.status === 'success' &&
                <div className={generateCSSString(component, cssValue)}>
                    <FormPage formdata={component.data.data} type={getFormType().toLowerCase()} parentData={props.parentData}/>
                </div>    
            }

            {/* {
                component.formData &&
                <div className={generateCSSString(component, cssValue)}>
                    <FormPage formdata={component.formData} type={getFormType()} parentData={props.parentData}/>
                </div>    
            } */}
        </Fragment>
    )
}

