import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { TranslateText } from '../../../../../helpers/language';
import { getActiveLang, getForms } from '../../../store/page';
import { generateCSSString, getPaddingStyles } from '../../../../CmsPage/helpers/style';

export default function Form(props){


    const lang = useSelector(getActiveLang);

    const component = props.component;
    const forms = useSelector(getForms);
    const form = Object.values(forms).find((form => form.BASE.id == component.formId_))?.BASE
    const cssValue = [...getPaddingStyles('form')];


    const getFormType = () => {
        if(form?.type == 0)
            return `${TranslateText('cmsForm')}`;
        if(form?.type == 1)
            return `${TranslateText('cmsFormSurvey')}`;
        else
            return `${TranslateText('cmsFormQuestionnaire')}`;
    }

    return (
        <div className={generateCSSString(component, cssValue)}>
            {
                form && form[`lang_${lang}`] ?
                        <div className='text-center'>
                            <span>
                                <span>{TranslateText('cmsPlaceholderFor')} {getFormType()}:<br/></span> 
                                {form.name}
                            </span>
                        </div>
                :
                    <Fragment>
                        {
                            form ?
                            <div className='error-text text-center'>
                                {TranslateText('cmsFormErrorLanguageNotAvailable')}
                            </div>
                            :
                            <div className='error-text text-center'>
                                {TranslateText('cmsFormErrorFormNotAvailable')}
                            </div>
                        }
                    </Fragment>
            }
       </div>
    )
}