import {rule} from "postcss";
import {TranslateText} from "../../libs/LanguageProvider";


export const evaluateRebateRule = (rule, currentCart) => {

    let ruleMatch = false;
    let matchIndex = 0;

    if (rule.hasOwnProperty('options') && Array.isArray(rule['options'])) {
        for (let index = 0; index < rule['options'].length; index++) {
            let option = {...rule['options'][index]};
            if (option.type === '0') {
                let state = isOptionMatched(option, currentCart);
                if (state) {
                    ruleMatch = true;
                    matchIndex = index;
                }
            }
        }
    }

    return (ruleMatch) ? matchIndex : false;

}

export const getRuleAdditional = (rule) => {

    let additional = [];

    for (let i = 0; i < rule.options.length; i++) {
        let option = rule.options[i];
        if (option.type === '1' || option.type === '2') { // list additional 'Werbematerial' or 'Gratisprodukte'
            for (let index = 0; index < option.items.length; index++) {
                let item = option.items[index];
                additional.push(item);
            }
        }
    }
    return additional;

}

export const getAdditionalReduction = (additionalItems) => {

    let reduction = 0;

    for (let i = 0; i < additionalItems.length; i++) {
        let item = additionalItems[i];
        if (item.type === "2") { // only calculate additional items with type 2 => 'geltwertende Abzüge'
            reduction += parseFloat(item.value);
        }
    }

    return reduction;

}

export const getAdditionalItems = (additionalItems) => {

    let items = [];

    for (let i = 0; i < additionalItems.length; i++) {
        let item = additionalItems[i];
        if (item.type != "2") { // add all additional items of the rule except the 'geltwertende Abzüge'
            items.push(item);
        }
    }

    return items;

}

export const getProductOfOptionItem = (optionItem, products) => {

    let result = undefined;

    products.forEach(product => {
        if (product.id === optionItem.product) {
            result = product;
        }
    })

    return result;

}

const isOptionMatched = (option, currentCart) => {

    // this is an "and" evaluation, all items have to validate true
    if (option.hasOwnProperty('items') && Array.isArray(option['items'])) {

        for (let index = 0; index < option['items'].length; index++) {
            let item = option['items'][index];
            let state = cartRuleItemMatch(item, currentCart);
            if (!state) {
                return false;
            }
        }
    }
    return true;
}

const cartRuleItemMatch = (item, currentCart) => {

    let fullQuantity = 0;
    let fullPrice = 0;

    for (let index = 0; index < currentCart.length; index++) {
        let cartProduct = currentCart[index];

        if (cartProduct.priceReduction !== undefined) {
            continue;
        }

        if (cartProduct.type === "1") {
            continue;
        }

        fullQuantity += parseInt(cartProduct.quantity);
        fullPrice += parseFloat(cartProduct.price) * fullQuantity
    }


    // we check for any
    if (item.product == "-1") {
        switch (parseInt(item.checkFor)) {
            case 0:
                return parseInt(item.value) <= fullQuantity;
            case 1:
                return parseFloat(item.value) <= fullPrice;
            default:
                return false;
        }
    }

    for (let index = 0; index < currentCart.length; index++) {
        let cartProduct = currentCart[index];

        if (cartProduct.priceReduction !== undefined) {
            continue;
        }

        if (cartProduct.type === "1") {
            continue;
        }

        if (cartProduct.id == item.product) {
            switch (parseInt(item.checkFor)) {
                case 0:
                    return cartProduct.quantity >= parseInt(item.value);
                case 1:
                    return (parseFloat(cartProduct.price) * cartProduct.quantity) >= parseFloat(item.value);
                default:
                    return false;
            }
        }

    }

    return false;
}

/**
 * calculate Sum for single cart element (offer or linked offer)
 * @param cartelem the cart element (props.card or props.cartLinkedOffer)
 * @returns {{quantity: number, price: number}}
 */
export const calculateCartSumsForSingleOffer = (cartelem) => {
    let quantity = 0;
    let price = 0;

    if (cartelem !== undefined && Array.isArray(cartelem)) {
        for (let i = 0; i < cartelem.length; i++) {
            const cart = cartelem[i];

            if (cart.priceReduction !== undefined) {
                continue;
            }


            quantity += parseInt(cart.quantity);
            price += (parseFloat(cart.price) * parseInt(cart.quantity));

        }
    }

    return {quantity: quantity, price: price};
}

/**
 * Get the activated Rebate of the given offer
 * @param rules rules of the offer
 * @param activeRule index of the active rule
 * @returns {undefined|*}
 */
export const getActiveRebate = (rules, activeRule) => {

    if (activeRule === undefined) {
        return undefined;
    }

    if (rules === undefined) {
        return undefined;
    }

    return (rules[activeRule] === undefined ? undefined : rules[activeRule])

}

/**
 * Get the active rebate of the given order
 * @param key - key of the value you want from the rebate - e.g. 'type' or 'rebate'
 * @param rules - the rules of the given offer
 * @param elemActiveRule - the active rule
 * @returns {undefined|*|number}
 */
export const getActiveRebateValue = (key, rules, elemActiveRule) => {
    const activeRebate = getActiveRebate(rules, elemActiveRule);

    if (activeRebate === undefined) {
        return -1;
    }

    return (activeRebate[key] === undefined ? undefined : activeRebate[key]);
}

/**
 * get type of rebate
 * @param rules the rules of the offer
 * @param elemActiveRule index active rule
 * @returns {number|*|number|undefined}
 */
export const getRebateType = (rules, elemActiveRule) => {
    return (getActiveRebateValue("type", rules, elemActiveRule) === undefined ? -1 : getActiveRebateValue("type", rules, elemActiveRule));
}

/**
 * Calculate Reduction value
 * @param elem cart element of offer
 * @param rules rules of offer
 * @param elemActiveRule index of active rule
 * @returns {number} reduction
 */
export const calculateReduction = (elem, rules, elemActiveRule) => {

    let sum = calculateCartSumsForSingleOffer(elem)

    let reduction = 0;

    const rebateValue = getActiveRebateValue("value", rules, elemActiveRule);

    switch (parseInt(getRebateType(rules, elemActiveRule))) {
        case 0:
            if (parseFloat(rebateValue) > 0) {
                reduction = (sum.price * (parseFloat(rebateValue) / 100))
            }
            break;
        case 1:
            if (parseFloat(rebateValue) > 0) {
                reduction = parseInt(rebateValue)
            }
            break;
    }

    return reduction;

}


export const calculateAdditionalReduction = (rules, activeRuleIndex) => {

    const activeRule = getActiveRebate(rules, activeRuleIndex)

    if (activeRule === undefined) {
        return 0;
    }

    return getAdditionalReduction(getRuleAdditional(activeRule));

}


export const getAdditionalQuantity = (cartlist) => {

    let quantity = 0;
    if (cartlist !== undefined && Array.isArray(cartlist)) {

        for (let i = 0; i < cartlist.length; i++) {
            const cart = cartlist[i];
            if (cart.priceReduction !== undefined) {
                quantity += parseInt(cart.quantity);
            }
        }
    }

    return quantity;

}

export const getReductionDisplayType = (rules, elemActiveRule) => {

    switch (parseInt(getRebateType(rules, elemActiveRule))) {
        case 0:
            return "%"
        case 1:
            return TranslateText("offer-cart-label-currency")
    }

    return "";

}

