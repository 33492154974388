import React from "react";
import 'tw-elements';
import DatePicker from "react-datepicker";
import {getUserLanguages} from "../../../helper/User";
import {format} from "date-fns";
import './datepicker.css'

export default function Datepicker(props) {

    const myProps = {
        ...props,
        className: (props.overwriteClassName === undefined
            ? "form-block p-2.5 w-full text-sm text-gray-900 bg-white font-sans rounded border-gray-300 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila "
            : "" ) + props.className,
        children: undefined,
        locale: getUserLanguages(),
        //minDate: props.minDate ? props.minDate : new Date().toISOString().slice(0, 10),
        //maxDate: props.maxDate ? props.maxDate : '',
        dateFormat: props.dateFormat ? props.dateFormat : "dd.MM.yyyy",
        //selected: props.selected
    };
/*
<DatePicker
            {...myProps}
        />
 */
    const onDateChange = (date) => {
        let value = null;
        const dFormat = props.dateFormat ? props.dateFormat : "dd.MM.yyyy"
        if (date instanceof Date) {
            value = format(date, dFormat)
        }
       props.onChangeHandler(value)
    }


    return (
            <DatePicker
                {...myProps}
                onChange={(e) =>  onDateChange(e)}
            />

    )


}