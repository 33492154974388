import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Footer from "../../components/Footer";

import {TranslateHtml, TranslateText} from "../../libs/LanguageProvider";


export default function UniversityRegistrationSuccessful(props) {

    return (

        <Fragment>

            <Page>
                <TopBar logo={'Sanofi University'}/>
                <Hero/>
                <ContentArea>

                    <h1 className="text-4xl color-sanofi-dark-lila font-bold text-center mb-20">{TranslateText("registration-successful-title")}</h1>

                    <div className="text-center mb-20">
                        <TranslateHtml name="registration-successful-content"/>
                    </div>
                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>

    )
}