import React, {Fragment, useState, useEffect} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import {useParams} from "react-router-dom";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ELearningComponent from "../../components/eLearning";
import {getLanguageContent} from "../../libs/LanguageProvider";
import SanofiButtonDark from "../../components/SanofiButtonDark";
import axios from "axios";
import {getUserLanguages} from "../../helper/User";
import UniversityCards from "../../../../../cms/react/src/CmsManager/CmsPage/components/Component/components/Card/components/UniversityCards";


// PATH => /university/elearnings/{slug}
export default function ELearningSuccess(props) {
    const [eLearning, setELearning] = useState({})
    const [cardData, setCardData] = useState({})
    const [isLoadingCertificate, setIsLoadingCertificate] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("return_data")) {
            setELearning(JSON.parse(localStorage.getItem("return_data")))
            localStorage.removeItem("return_data");
        } else {
            window.location = '/university/home'
        }

        // gets the last three eLearnings, webinars, producttrainings
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getLastThreeWebinarsElearningsProducttrainings/formdesigner",
            data: {
                lang: getUserLanguages()
            }
        }).then(data => {

            // design from university/home
            const cardelem = {
                "id": 20,
                "created": "24.07.2023 09:48",
                "createdBy": 679,
                "updated": "24.07.2023 09:48",
                "updatedBy": 679,
                "uuid": "1fb64ec9-0ada-b3b4-b5fb-9fc1d6faf571",
                "page_uuid": "93a583fa-e947-2e04-c16d-543c545bd91a",
                "parent_component_uuid": "none",
                "component": "cardlist",
                "type": "all",
                "marginX": "",
                "marginY": "",
                "marginTop": "",
                "marginBottom": "",
                "marginLeft": "",
                "marginRight": "",
                "paddingX": "",
                "paddingY": "",
                "paddingTop": "pt-7",
                "paddingBottom": "pb-7",
                "paddingLeft": "pl-0",
                "paddingRight": "pr-0",
                "alignment": "",
                "font": "",
                "fontSize": "",
                "textTransform": "",
                "textDecoration": "",
                "width": "",
                "height": "",
                "color": "",
                "hoverColor": "",
                "backgroundColor": "",
                "hoverBackgroundColor": "",
                "borderColor": "",
                "hoverBorderColor": "",
                "backgroundImage": "",
                "borderRadius": "",
                "gapX": "gap-x-5",
                "gapY": "gap-y-5",
                "justifyContent": "",
                "alignItems": "",
                "form_uuid": "",
                "buttonColor": "",
                "buttonBackgroundColor": "",
                "boxShadow": null,
                "boxShadowColor": "",
                "overlay": null,
                "target": "",
                "position": 1,
                "data": data.data
            }

            setCardData(cardelem)

        });

    }, [])

    const getCertificate = () => {
        setIsLoadingCertificate(true)
        axios({
            dataType: "json",
            method: "post",
            url: "/module/getCertificate/certificate",
            data: {
                type: eLearning.type, //"producttraining" | "elearning" | "webinar"
                uuid: eLearning.uuid, // uuid
                lang: eLearning.lang,
                withoutGLN: eLearning.withoutGLN
            }
        }).then(data => {
            if (data.data && data.data.status === 'success') {
                //window.location = data.data.data.file
                const aEl = document.createElement('a');
                aEl.href = data.data.data.file;
                aEl.setAttribute('download', 'Certificate');
                aEl.target = '_blank';
                aEl.click();
            } else {
                if (data.data.message) {
                    alert(data.data.message)
                } else {
                    alert('Error while generating the certificate - please try again')
                }
            }
        });
    }

    return (
        <Page className="flex flex-column">
            <TopBar logo={'Sanofi University'}/>
            <Hero menuType={-1}/>
            <ContentArea>

                <div className="flex place-content-center gap-1 flex-col md:mt-28">
                    <div className="inline-flex  justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="77.713" height="77.714"
                             viewBox="0 0 77.713 77.714">
                            <path id="certificate"
                                  d="M71.42,44.189h-5.01V11.82A10,10,0,0,0,57.034,1.8H16.443C8.351,1.672,1.8,8.48,1.8,16.829v5.523a4.826,4.826,0,0,0,4.753,4.753H14.9V61.4a8.306,8.306,0,0,0,8.349,8.221H28v6.551a3.265,3.265,0,0,0,1.8,2.954,3.42,3.42,0,0,0,1.541.385,2.949,2.949,0,0,0,1.8-.514l5.909-3.854,5.78,3.725.257.128a3.426,3.426,0,0,0,3.34-.128,3.223,3.223,0,0,0,1.541-2.826V69.622H71.42a8.141,8.141,0,0,0,8.092-8.092V52.153A8.115,8.115,0,0,0,71.42,44.189ZM7.58,16.829a9.166,9.166,0,0,1,8.863-9.377H45.6a11.81,11.81,0,0,0-1.285,5.266v8.606H7.58Zm31.342,39.82A8.35,8.35,0,1,1,47.4,48.3,8.361,8.361,0,0,1,38.922,56.649Zm2.184,12.974a3.871,3.871,0,0,0-4.367,0l-3.083,2.055V61.4a14.776,14.776,0,0,0,5.266,1.028A13.744,13.744,0,0,0,44.189,61.4V71.678Zm8.863-5.78V57.163A13.651,13.651,0,0,0,53.18,48.3a14.259,14.259,0,0,0-28.516,0,14,14,0,0,0,3.211,8.992v6.551H23.123A2.47,2.47,0,0,1,20.554,61.4v-34.3H45.216a4.826,4.826,0,0,0,4.753-4.753V12.847a5.089,5.089,0,0,1,4.5-5.266h1.927A4.167,4.167,0,0,1,60.5,11.82V63.842ZM73.732,61.53a2.3,2.3,0,0,1-2.312,2.312h-5.01V49.969h5.01a2.3,2.3,0,0,1,2.312,2.312Z"
                                  transform="translate(-1.8 -1.799)" fill="#22004c"/>
                        </svg>
                    </div>
                </div>


                <div className="md:mt-10 md:pb-28 text-center center">
                    <h1 className="text-3xl text-sanofi-dark-lila font-bold mb-7"
                    >{getLanguageContent('elearning-success-title', '3. Herzlichen Glückwunsch!')}</h1>
                    <div className="flex items-center justify-center mb-8">
                        <div className="min-w-5/6">
                            <div className="max-w-md mb-2">{getLanguageContent('elearning-success-text-prename',
                                'Sie haben das ')} {eLearning.type}
                                <span className="font-bold"> "{eLearning.title}" </span>
                                {getLanguageContent('elearning-success-text-aftername',
                                    'erfolgreich bestanden.')}
                            </div>


                            <div className="max-w-md">{getLanguageContent('elearning-success-description',
                                'Vielen Dank, dass Sie an der Weiterbildung teilgenommen haben. Ihre Akkreditierungspunkte werden Ihnen in den nächsten 2-3 Wochen gutgeschrieben.')}</div>
                        </div>
                    </div>
                    <SanofiButtonDark className="mt-7" onClick={getCertificate} disabled={isLoadingCertificate}>
                        {getLanguageContent('text-eLearning-success-certificate-download', 'Zertifikat herunterladen')}
                    </SanofiButtonDark>

                </div>

                <h2 className="text-3xl text-sanofi-dark-lila font-bold mb-5 "
                >{getLanguageContent('text-eLearning-success-further-information', 'Aktuelle Weiterbildungen')}</h2>
                <UniversityCards elem={cardData}/>

            </ContentArea>
            <Footer className="mt-auto"/>
        </Page>
    )
}