import React, {useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';

export default function Input(props) {

    const myProps = {
        ...props,
        className: (props.overwriteClassName === undefined
                    ? "block p-2.5 w-full text-base text-gray-900 bg-white font-sans rounded border-gray-300 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila "
                    : "" ) +props.className,
        children: undefined,
    };

    return (
        <input {...myProps} />
    )


}