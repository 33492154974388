import React, {useState} from "react";
import clsx from "clsx";
import BoxWrapper from "./BoxWrapper";
import LoginForm from "./login/LoginForm";
import LoginSlider from "./login/LoginSlider";
import SecondDefaultButton from "../../../elements/Button/SecondDefaultButton";
import {getLanguageContent} from "../../../libs/LanguageProvider";

export default function LoginBox(props) {
    const [sucsessfulLogin, setsucsessfulLogin] = useState(true); // false: Error message for invalid login -> we net this information fir overflow: scroll

    return (
        <BoxWrapper id={"login-box"} class={"xl:flex-row"}>
            <div className={!sucsessfulLogin ? "box-basic box-left md:overflow-y-scroll" : "box-basic"}>
                <div
                    className={clsx("loginscreen-header", 'login-mb-1', 'fontRalewayRegular')}>
                    <a>
                        <img class="login-logo mx-auto" src="/design/frontend/img/sanoficonnect_logo_x2.png"
                             alt="sanofi Connect"/>
                    </a>
                </div>

                <div className="loginscreen-left-content" style={{borderBottom: props.frontendAllowRegistration ? "auto" : "none"}}>
                    <LoginForm
                        loginformaction={props.loginform}
                        swissRxPath={props.swissRxPath}
                        showPasswortResetForm={props.showPasswortResetForm}
                        setsucsessfulLogin={setsucsessfulLogin}
                        supportSwissRx={props.supportSwissRx}
                    />
                </div>

                {props.frontendAllowRegistration &&
                    <div className={"login-form-footer"}>
                        <p className="registration-label">
                            {getLanguageContent('noacc', 'Noch kein Kundenkonto?')}

                        </p>
                        <div className="registration text-center">
                            <SecondDefaultButton
                                title={getLanguageContent('register', 'Registrieren')}
                                clickevent={() => props.showRegistrationForm()}/>
                        </div>
                    </div>
                }
            </div>
            {props.sliderData && JSON.parse(props.sliderData).length > 0 &&
                <div className="right-row">
                    <LoginSlider sliderdata={props.sliderData}
                                 languageitemfunction={getLanguageContent}
                    />
                </div>
            }
        </BoxWrapper>
    );
}