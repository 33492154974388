import React, {Fragment, useState, useEffect} from "react";
import FieldElement from "../fieldElement";
import {useSelector} from "react-redux";
import SanofiButtonDark from "../../../SanofiButtonDark";
import {getLanguageContent} from "../../../../libs/LanguageProvider";
import SanofiButton from "../../../SanofiButton";
import axios from "axios";
import {getUserLanguages} from "../../../../helper/User";
import {getLanguageAsString} from "../../../../helper/Language";
import {reorganiseFields} from "../../helper/Pagination/pagination";


export default function FormQuiz(props) {

    let formRef = React.useRef()
    const data = useSelector((state) => state.formhandling.formValues);

    const formFields = props.fields;
    const [counter, setCounter] = useState(1)
    const [fields, setFields] = useState(formFields)

    useEffect(() => {
        const f = reorganiseFields(formFields, [5, 6, 7])
        setFields(f)
    }, [props.fields])


    const changeCounter = (state) => {
        if (state === 'up') {
            if (!formRef.current.checkValidity()) {
                formRef.current.reportValidity();
                return;
            }
            if (props.updateProgress) props.updateProgress(fields.length, counter + 1)
            setCounter(counter + 1)

        } else { // 'down'
            if (props.updateProgress) props.updateProgress(fields.length, counter - 1)
            setCounter(counter - 1)
        }

    }

    const getType = () => {
        if (props.parentData.therapyarea) {
            return 'producttraining';
        }
        if (props.parentData.time_date) {
            return 'webinar';
        }
        return 'elearning';
    }

    /**
     * Submit completed formular
     * @returns {boolean}
     */
    const onFormSubmit = () => {

        if (!formRef.current) {
            return false;
        }

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return false;
        }

        axios({
            dataType: "json",
            method: "post",
            url: "/module/submitForm/formdesigner",
            data: {
                uuid: props.formuuid,
                lang: props.lang,
                parentUUID: props.parentData.uuid ?? null,
                fields: props.reformatDataForSubmit(data)
            }
        }).then(data => {
            const return_data = {
                uuid: props.parentData.uuid,
                title: props.parentData.title,
                type: getType(),
                lang: getLanguageAsString(props.parentData.lang ?? null),
                withoutGLN: props.withoutGLN // has an influence on the certificate
            }
            if (data.data.status === 'success' && data.data.data.form.result == '1') {
                // Save data to local storage
                window.localStorage.setItem('return_data', JSON.stringify(return_data));
                window.location.href = props.returnURL;
            } else if (data.data.status === 'success' && data.data.data.form.result == '0') {
                window.localStorage.setItem('return_data', JSON.stringify(return_data));
                window.location.href = props.errorReturnURL;
            } else {
                if (data.data.message) {
                    alert(data.data.message)
                } else {
                    alert('Error while submitting the questionare - please try again')
                }
            }
        });
        //window.location.href = '/thankyou';
    }

    const slidecount = fields.length

    return (
        <Fragment>
            {slidecount > 0 &&
                <form ref={formRef}>
                    {slidecount > 1 &&
                        <div
                            className="text-center my-5"> {counter} {getLanguageContent('text-of', 'VON')} {slidecount} {getLanguageContent('text-questions', 'FRAGEN')}
                        </div>
                    }
                    {   // only one element
                        (fields[counter - 1].type) &&
                        <FieldElement fElem={fields[counter - 1]} type='quiz'/>
                    }

                    {   // multiple elements
                        !(fields[counter - 1].type) && Object.keys(fields[counter - 1]).map(fElemKey => {
                            return <FieldElement fElem={fields[counter - 1][fElemKey]}
                                                 type='quiz'/>

                        })
                    }
                    <div className="grid grid-cols-2 mt-8">

                        <div className="justify-self-start">
                            {counter !== 1 &&
                                <SanofiButtonDark className="px-3 sm:px-14" type="button"
                                                  onClick={() => changeCounter('down')}>
                                    {getLanguageContent('text-question-back', 'Vorherige Frage')}
                                </SanofiButtonDark>
                            }
                        </div>

                        {counter < slidecount ?
                            <div className="justify-self-end">
                                <SanofiButtonDark className="px-3 sm:px-14" type="button"
                                                  onClick={() => changeCounter('up')}>
                                    {getLanguageContent('text-question-continue', 'Nächste Frage')}
                                </SanofiButtonDark>
                            </div>
                            :
                            <div className="justify-self-end">
                                <SanofiButton className="px-3 sm:px-14" disabled={props.submitDisabled} type="button"
                                              onClick={(e) => onFormSubmit()}>
                                    {props.submitButtonLabel}
                                </SanofiButton>
                            </div>
                        }
                    </div>

                </form>
            }

        </Fragment>
    )
}

