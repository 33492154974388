import React, {useEffect, useState, Fragment} from 'react';
import '../../Logindesign.css'
import CustomInput from "../../../../elements/Input/CustomInput";
import BackButton from "../../../../elements/Button/BackButton"
import SecondDefaultButton from "../../../../elements/Button/SecondDefaultButton";
import axios from "axios";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleExclamation, faArrowLeftLong, faDoorOpen, faCircleCheck} from '@fortawesome/free-solid-svg-icons'
import Typeahead from "../../../Form/Typeahead";
import {getLanguageContent, TranslateHtml} from "../../../../libs/LanguageProvider";
import DefaultButton from "../../../../elements/Button/DefaultButton";

/*
        Login Form
        props : {
            language: browser language
            swissRx: is true when it is a swissRxx login
            showLoginForm: method to switch to the login form
        }
 */
const RegistrationForm = (props) => {

    let formRef = React.useRef();

    // Array [0: input , 1: errormessage (errormessage if true)]
    const [firstname, setFirstname] = useState(['', false])
    const [lastname, setLastname] = useState(['', false])
    const [pharmacy, setPharmacy] = useState(['', false])
    const [email, setEmail] = useState(['', false])
    const [password, setPassword] = useState(['', false])
    const [locationCode, setLocationCode] = useState({})
    const [windowstate, setWindowstate] = useState('...')
    const [error, setErrormessage] = useState('');
    const [loginerror, setLoginErrormessage] = useState('');
    const [language, setLanguage] = useState('de');

    const [disableRegistrationButton, setDisableRegistrationButton] = useState(false);

    const validEmail = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
    const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\.#?!@$%^&*-]).{8,}$');

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const swissRx = urlParams.get('swissRx');
    const usrID = urlParams.get('usrID');

    /*Called by initialization*/
    useEffect(() => {
        setLanguage(props.language);

        if (swissRx) {
            setFirstname([urlParams.get('firstname'), false]);
            setLastname([urlParams.get('lastname'), false]);
            setPharmacy([urlParams.get('company'), false])
            setEmail([urlParams.get('email'), false]);
        }
    }, []);

    const registerUser = () => {
        validateEmail(email[0]);
        validatePassword(password[0]);

        if (!formRef.current) {
            return;
        }

        if (firstname[0] && lastname[0] && !email[1] && email[0] && pharmacy[0] && !password[1] && password[0]) {
            setDisableRegistrationButton(true);
            // try to register user
            axios.post('/register/Marketplaceuser', {
                language: language,
                firstname: firstname[0],
                lastname: lastname[0],
                email: email[0],
                password: password[0],
                pharmacy: pharmacy[0],
                locationCode: locationCode.id || null,
            }).then(r => {
                const statusmessage = r.data
                if (statusmessage.status === 'success') {
                    setWindowstate('RegisterSuccess')
                } else {
                    setErrormessage(statusmessage.message);
                    setWindowstate('RegisterError')
                }
                setDisableRegistrationButton(false);
            })
        } else {
            setFirstname([firstname[0], (firstname[0] === '')])
            setLastname([lastname[0], (lastname[0] === '')])
            setPharmacy([pharmacy[0], (pharmacy[0] === '')])
            // email and password are already validated
        }
    }

    const register = () => {
        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        if (swissRx) {
            registerSwissRxUser();
        } else {
            registerUser();
        }
    }

    const registerSwissRxUser = () => {
        validateEmail(email[0]);
        if (firstname[0] && lastname[0] && !email[1] && email[0] && pharmacy[0]) {
            // try to register user
            axios.post('/module/registerSwissRxUser/user', {
                language: language,
                firstname: firstname[0],
                lastname: lastname[0],
                email: email[0],
                pharmacy: pharmacy[0],
                usrID: usrID,
                locationCode: locationCode.id || null,
            }).then(r => {
                const statusmessage = r.data
                // Redirect to home
                if (statusmessage.status === 'success') {
                    setWindowstate('RegisterSuccess')
                } else {
                    setErrormessage(statusmessage.message);
                    setWindowstate('RegisterError')
                }
                setDisableRegistrationButton(false);
            })
        } else {
            setFirstname([firstname[0], (firstname[0] === '')])
            setLastname([lastname[0], (lastname[0] === '')])
            setPharmacy([pharmacy[0], (pharmacy[0] === '')])
            // email and password are already validated
        }
    }


    const validateEmail = (newEmail) => {
        if (!validEmail.test(newEmail)) {
            setEmail([newEmail, true]);
        } else {
            setEmail([newEmail, false]);
        }
    }


    const validatePassword = (newPwd) => {
        if (!validPassword.test(newPwd)) {
            setPassword(() => [newPwd, true]);
        } else {
            setPassword(() => [newPwd, false]);
        }
    }


    return (
        <div className="registration-container">
            <div className="loginscreen-left-content no-border">

                <div style={{marginTop: "1.2rem"}}>
                    {(() => {
                        switch (windowstate) {
                            case 'RegisterSuccess':
                                return (
                                    <div className='grid-one-row '>
                                        <div className='center-content'><FontAwesomeIcon icon={faCircleCheck} size='7x'
                                                                                         color='#22004C'/></div>
                                        <div className='mt-3'>
                                            <div
                                                className='login-message-header login-success-message-header'>
                                                {getLanguageContent("registration-Success-header", 'Sie wurden erfolgreich registriert')}
                                            </div>
                                            <div
                                                className='login-error-message-text login-message-text mt-3'>
                                                {getLanguageContent("registration-Success-text", 'Sie müssen nun Ihre E-Mail-Adresse bestätigen. Bitte überprüfen Sie dafür Ihr E-Mail-Postfach.')}
                                            </div>
                                        </div>
                                    </div>
                                )

                            case 'RegisterError':
                                return (
                                    <div className='grid-one-row '>
                                        <div className='center-content'><FontAwesomeIcon icon={faCircleExclamation}
                                                                                         size='7x' color='#9B0805'/>
                                        </div>
                                        <div className='mt-3'>
                                            <div
                                                className='login-message-header login-error-message-header max-w-screen-sm mb-4'>
                                                {getLanguageContent("registration-error-header", 'Ein Fehler ist aufgetreten!')}
                                            </div>
                                            <div
                                                className='login-error-message-text login-message-text mb-4'>
                                                {getLanguageContent("registration-error-text", 'Wir konnten Sie nicht registrieren.')}
                                            </div>

                                            <div className='center-content mt-2'>
                                                <SecondDefaultButton
                                                    title={<div><FontAwesomeIcon icon={faArrowLeftLong}
                                                                                 color='#BBBAC6'/>{getLanguageContent("back", 'Zurück')}
                                                    </div>}
                                                    clickevent={() => setWindowstate('...')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )

                            default:
                                return (
                                    <Fragment>
                                        <div
                                            className="box-head loginscreen-item-align mb-2">
                                            <div className="flex flex-row">
                                                <h1 className="LoginHeader AvantGardeGothicBold">{getLanguageContent("registration-title", 'Registration')}</h1>
                                            </div>
                                        </div>
                                        <form ref={formRef} autoComplete="off" id={"reg-form"}>
                                            <div className="login-font-arial">
                                                <div className="login-mt-small">
                                                    <label>{getLanguageContent('registration-label-language', 'Sprache')}</label>
                                                    <select
                                                        style={{
                                                            border: "2px solid gray",
                                                            width: "100%",
                                                            height: "3rem",
                                                            borderRadius: "4px",
                                                        }}
                                                        errormessage={getLanguageContent("validation-input", 'Bitte wählen Sie eine Sprache')}
                                                        onChange={(event) => {
                                                            let searchStr = window.location.search;
                                                            searchStr = searchStr.replace(/[&]?view=[^&]*?/, '');
                                                            searchStr = searchStr.replace(/[&]?langOW=[a-zA-Z]{2}/, '');

                                                            if (searchStr.length == 0) {
                                                                searchStr = '?';
                                                            }

                                                            window.location = window.location.origin + window.location.pathname + searchStr + '&view=register&langOW=' + event.target.value;
                                                        }}
                                                    >
                                                        <option value={"de"}
                                                                selected={"de" == language}>{getLanguageContent('profile-language-option-german', 'Deutsch')}</option>
                                                        <option value={"fr"}
                                                                selected={"fr" == language}>{getLanguageContent('profile-language-option-french', 'Französisch')}</option>
                                                        <option value={"it"}
                                                                selected={"it" == language}>{getLanguageContent('profile-language-option-italian', 'Italienisch')}</option>
                                                    </select>
                                                </div>
                                                <div className="login-mt-small">
                                                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                                                        <CustomInput title={getLanguageContent('firstname', 'Vorname')}
                                                                     type='text'
                                                                     errormessage={getLanguageContent("validation-input", 'Bitte füllen Sie dieses Feld aus')}
                                                                     validation={firstname[1]}
                                                                     changeEvent={(e) => setFirstname([e, false])}
                                                                     inputValue={urlParams.get('firstname')}
                                                        />
                                                        <CustomInput
                                                            title={getLanguageContent('lastname', 'Nachname')}
                                                            type='text'
                                                            errormessage={getLanguageContent("validation-input", 'Bitte füllen Sie dieses Feld aus')}
                                                            validation={lastname[1]}
                                                            changeEvent={(e) => setLastname([e, false])}
                                                            inputValue={urlParams.get('lastname')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="login-mt-small">
                                                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                                                        <CustomInput
                                                            title={getLanguageContent('pharmacy', 'Apotheke/Drogerie')}
                                                            type='text'
                                                            errormessage={getLanguageContent("validation-input", 'Bitte füllen Sie dieses Feld aus')}
                                                            validation={pharmacy[1]}
                                                            changeEvent={(e) => setPharmacy([e, false])}
                                                            inputValue={urlParams.get('company')}
                                                        />
                                                        <div>
                                                            <label>{getLanguageContent('login-label-locationcode', 'PLZ / Ort')}</label>
                                                            <Typeahead
                                                                onSelect={(value) => {
                                                                    setLocationCode(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="login-mt-small">
                                                    <CustomInput
                                                        title='Email'
                                                        type='email'
                                                        required={'true'}
                                                        validation={email[1]}
                                                        changeEvent={(e) => setEmail([e, false])}
                                                        errormessage={<TranslateHtml name='validation-email'/>}
                                                        inputValue={urlParams.get('email')}
                                                    />
                                                </div>


                                                <div className="login-mt-small">
                                                    {(!props.swissRx) ?
                                                        <CustomInput
                                                            title={getLanguageContent('password', 'Passwort')}
                                                            type='password'
                                                            errormessage={<TranslateHtml name='validation-password'/>}
                                                            showErrorAsDefaultText={true}
                                                            validation={password[1]}
                                                            changeEvent={(e) => validatePassword(e)}
                                                        />
                                                        :
                                                        <div></div>}
                                                </div>


                                                <div className="loginscreen-two-grid mt-7 login-back">
                                                    <div className="login-form-pwd-forgot clickable" onClick={() => {
                                                        props.showLoginForm()
                                                    }}>
                                                        {getLanguageContent("back-to-login", 'Zurück zur Anmeldung')}
                                                    </div>
                                                    <DefaultButton
                                                        title={getLanguageContent("register", "Registrieren")}
                                                        bSize="xl"
                                                        clickevent={() => register()}
                                                        disable={disableRegistrationButton}
                                                    />
                                                </div>

                                            </div>
                                        </form>
                                    </Fragment>
                                )
                        }
                    })()
                    }
                </div>
            </div>
        </div>
    )
}

export default RegistrationForm;