/**
 * Gets the field array and organise it by page and position
 * @param fields array of Fields
 * @param supportedFields list with number of supported field types
 */
export const reorganiseFields = (fields, supportedFields) => {
    let newFieldList = [];
    /*fields.forEach((field) => {
        if (isFieldSupported(field.type, supportedFields) !== undefined) {
            const pos = field.position

            console.log('suits', 'id ' + field.uuid + ' type: ' + field.type + ' page: ' + field.page + ' pos: ' + pos)
            console.log('newFieldList[field.page]', newFieldList[field.page])
            // does key exists (page)
            if (newFieldList[field.page]) {
                newFieldList[field.page][pos] = field;
            } else { // insert new array
                console.log('field.page', field.page)
                newFieldList.push(field.page, {[pos]: field})
            }
            console.log('newFieldList', newFieldList)
        }
    })*/

    fields.forEach(obj => {
        if (supportedFields.includes(obj.type)) {
            if (!newFieldList[obj.page]) {
                newFieldList[obj.page] = [];
            }

            newFieldList[obj.page][obj.position] = obj;
        }
    });

    return clearArray(newFieldList);
}

/**
 * Clear array from empty positions - that are not fitting
 * @param arr
 */
const clearArray = (arr) => {
    // Entferne leere Positionen und Seiten aus dem Array
    return arr.filter(page => page !== undefined)
        .map(page => page.filter(position => position !== undefined));

}


/**
 *
 * @param fieldType type to compare with
 * @param supportedFields list with number of supported field types
 * @returns {boolean} exists or not
 */
const isFieldSupported = (fieldType, supportedFields) => {
    return supportedFields.find(sField => sField === fieldType)
}