import React, { useState, useEffect, Fragment } from "react";
import { generateCSSString } from "../../../../../../CmsPage/helpers/style";
import { getPaddingStyles } from "../../../../../../CmsPage/helpers/style";
import { userComponentAccess } from "../../../../../helpers/user";
import ComponentFrontend from "../../..";
import ComponentBackend from "../../../../../../CmsDesigner/components/Component";

export default function Accordion(props){

    const {component, draggable, ...rest} = props;

    const backend = props.backend ?? false;
  

    let [collapsed, setCollapsed] = useState(component.accordion_accordionInitialDisplay == 0 ? component.initialDefault : component.accordion_initialDisplay);

    useEffect(() => {
        if(component.accordion_accordionInitialDisplay == 0)
            setCollapsed(component.initialDefault)
        else
            setCollapsed(component.accordion_accordionInitialDisplay)
    }, [component.initialDefault, component.accordion_accordionInitialDisplay]);

    useEffect(() => {
        if(component.autocollapse == 1  && component.expandedElement && component.uuid !== component.expandedElement)
            setCollapsed(1);
    }, [component.expandedElement])


    let handleCollapse = () => {
        if(collapsed == 1){
            if(component.autocollapse == 1)
                component.handleExpandedElement(backend ? component.internId : component.uuid);
            return setCollapsed(2);
        }
        return setCollapsed(1);
    }


    const cssValue = [...getPaddingStyles('accordion'), 'border', 'borderRadius', 'title_textTransform'];

    return (
        <Fragment>
            {
                (backend || (component.hidden_ != 1 && userComponentAccess(component.roleId_, props.previewPageRoleId))) &&
                <div id={`${component.anchor_ ? component.anchor_ : 'Accordion-' + component.uuid}`} className={'overflow-hidden '} >

                    <div className={`flex w-full items-center cursor-pointer border-solid ${generateCSSString(component, cssValue)}`} 
                        style={{
                            backgroundColor:  collapsed == 1 ? component.backgroundColor : component.backgroundColorExpanded,
                            borderColor: collapsed == 1 ? component.borderColor : component.borderColorExpanded
                            }}
                        onClick={() => handleCollapse()}
                    >
                        
                        { component.iconPos === 'left' && (collapsed == 1 ?
                        
                            <span className="material-icons" style={{color: component.iconColor}}>expand_more</span>
                            :
                            <span className="material-icons" style={{color: component.iconColorExpanded}}>expand_less</span>)
                        }

                        <div className='grow' 
                            style={{color: collapsed == 1 ? component.textColor : component.textColorExpanded}} 
                            dangerouslySetInnerHTML={{__html: component.textContent_}}
                        />
                        
                        { component.iconPos === 'right' && (collapsed == 1?
                            <span className="material-icons" style={{color: component.iconColor}}>expand_more</span>
                            :
                            <span className="material-icons" style={{color: component.iconColorExpanded}}>expand_less</span>)
                        }

                    </div>
                        
                    <div>
                        {
                            collapsed != 1 && component.children && component.children.map((child) => {
                                        if(backend) 
                                            return <ComponentBackend component={child} {...rest} />
                                        return <ComponentFrontend component={child} {...rest} />
                                    })
                                }
                    </div>


                </div>
            }
        </Fragment>
    )
}