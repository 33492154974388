import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import {TranslateHtml, TranslateText, Translate} from "../../../libs/LanguageProvider";
import SanofiButton from "../../../components/SanofiButton";
import {parse} from "date-fns";
import {Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import "../helper/CountdownHandler";
import {calculateTimeLeft, renderCountDown} from "../helper/CountdownHandler";


export default function UpcomingOfferItem(props) {

    const [offer, setOffer] = useState(props.offer)
    const [timer, setTimer] = useState({})


    useEffect(() => {

        const countDown = setInterval(() => {
            setTimer(calculateTimeLeft(parse(props.offer.startTime, "dd.M.yyyy HH:mm", new Date()), new Date))
        }, 1000);

        return () => clearInterval(countDown);

    }, [])


    return (

        <Fragment>

            <div className={"w-full lg:w-1/2 p-4 mb-0 lg:mb-12"}>
                <div className={"text-large items-center flex m-2"}>
                    <div className={"inline-block mr-3"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                    </div>

                    <div className={"text-2xl uppercase"}>
                        {TranslateText('offer-box-timer-title-upcoming-offer')} <span
                        className={"color-sanofi-dark-lila font-bold"}>{renderCountDown(timer)}</span>
                    </div>

                </div>


                <div className={"rounded-xl h-full p-8 flex flex-col justify-between offer-item bg-backdrop-grayscale"}
                     style={
                         {
                             background: ' url(' + offer.hero + ') no-repeat center, linear-gradient(to bottom, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, 1) 100%)',
                             backgroundSize: 'cover',
                             filter: 'grayscale(100%)'
                         }
                     }>


                    <div>
                        <h2 className={"text-3xl color-sanofi-dark-lila font-bold offer-item-title"}>{offer.title}</h2>
                        <div className={"py-4"}>
                            <div dangerouslySetInnerHTML={{__html: offer.descriptionShort}}/>
                        </div>
                    </div>

                    <div className={"flex justify-between items-center"}>

                        <div>

                        </div>

                        <div className={"flex items-center"}>
                            {offer.brand.map((brand) => (
                                <div className={"p-2"}>
                                    <img className={"inline-block w-24"} src={brand.picture} alt={brand.name}/>
                                </div>
                            ))}
                        </div>

                    </div>


                </div>

            </div>


        </Fragment>

    )
}