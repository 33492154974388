"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTransitionId = getTransitionId;
exports.extend = extend;
exports.once = once;
exports.isNamespace = isNamespace;
exports.timingDefaults = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var transitionId = 0;

function getTransitionId() {
  return ++transitionId;
}

function extend(obj, props) {
  for (var i in props) {
    obj[i] = props[i];
  }
}

function once(func) {
  var called = false;
  return function transitionEvent() {
    if (!called) {
      called = true;
      func.call(this);
    }
  };
}

function isNamespace(prop) {
  return _typeof(prop) === 'object' && Array.isArray(prop) === false;
}

var linear = function linear(t) {
  return +t;
};

var timingDefaults = {
  delay: 0,
  duration: 250,
  ease: linear
};
exports.timingDefaults = timingDefaults;