import React from "react";
import { generateCSSString, getPaddingStyles } from "../../../../../helpers/style";
import Title from "../../Title";
import Text from "../../Text";
import Button from "../../Button";

export default function BgImageCard(props) {

    const component = props.component;

    const cssValueContainer = ['card_borderRadius'];
    const cssValue = [...getPaddingStyles('card')];

    return (
        <div id={`BgImageCard-${component.uuid}`} 
            className={`h-full ${generateCSSString(component, cssValueContainer)} card-shadow flex flex-col grow overflow-hidden`} 
            style={{
                backgroundImage: `${component.image_ ? 'url("' + component.image_ + '")': 'unset'}`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: `${component.cardBackground_color ? component.cardBackground_color : 'unset'}`,
                backgroundSize: 'cover',
                backgroundPosition: component.image_backgroundPosition,
                border: `${component.cardBorder_color ? '1px solid' + component.cardBorder_color : 'none'}`
            }}>
            
            <div className={`card-shadow w-full h-full ${generateCSSString(component, cssValue)} grow flex flex-col justify-between`} 
                style={{background: `${component.image_overlay ?? 'unset'}`}}
            >
            <div>
                {               
                    component.titleText_ && 
                    <Title component={component}/>
                }

                {   
                    component.textContent_ && 
                    <Text component={component}/>
                }
            </div>

                {
                    component.btnText_ &&
                    <Button 
                        component={component}
                        target={props.target}
                    />
                }

            </div>
        </div>
    )
}