const dummyUniversityCards = [{
    title: 'Webinar Placeholder',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    time_date: "1.03.2023",
    time_from: "19:30:00",
    time_to: "21:00:00",
    link: '',
    image: 'https://www.sanoficonnect.ch/react/frontend/src/views/UniversityHome/img/img_5.png',
    accreditation: [{ accreditation_points: "12 Punkte FPH-Punkte"}],
},{
    title: 'Certified Training Placeholder',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    duration: '00:50:00',
    link: '',
    image: 'https://www.sanoficonnect.ch/react/frontend/src/views/UniversityHome/img/img_4.png',
    accreditation: [{ accreditation_points: "12.5 FPH-Punkte"}, { accreditation_points: "2 SDV-Punkte"}]
},{
    title: 'Product Training Placeholder',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    duration: '00:30:00',
    link: '',
    image: 'https://www.sanoficonnect.ch/react/frontend/src/views/UniversityHome/img/img_6.png',
    therapyarea: 'Verstopfung'
}]

//TO-DO: Translate DummyCards
export const getDummyUniversityCards = (type) => {
    if(type === 'webinars')
        return [dummyUniversityCards[0], dummyUniversityCards[0], dummyUniversityCards[0]]
    if(type === 'elearnings')
        return [dummyUniversityCards[2], dummyUniversityCards[2], dummyUniversityCards[2]]
    if(type === 'producttrainings')
        return [dummyUniversityCards[1], dummyUniversityCards[1], dummyUniversityCards[1]]
    return dummyUniversityCards;
}
