"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Events = void 0;

var _utils = require("./utils");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Events = function Events(config) {
  var _this = this;

  _classCallCheck(this, Events);

  this.start = null;
  this.interrupt = null;
  this.end = null;

  if (config.events) {
    Object.keys(config.events).forEach(function (d) {
      if (typeof config.events[d] !== 'function') {
        throw new Error('Event handlers must be a function');
      } else {
        _this[d] = (0, _utils.once)(config.events[d]);
      }
    });
  }
};

exports.Events = Events;
var _default = Events;
exports.default = _default;