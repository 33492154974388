import React from "react";

export default function BoxWrapper (props) {
    return (
        <div id={props.id ?? ''} className="round-box" style={props.style}>
            <div className={'head-elem flex flex-col ' + props.class}>
                {props.children ?? ''}
            </div>
        </div>
    );
}