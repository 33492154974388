import React, {useEffect, useState, Fragment, useCallback} from "react";
import { Link, NavLink } from "react-router-dom";


export default function MenuLink(props) {

    const activeClass = props.activeClass || "is-active";
    const inactiveClass = props.inactiveClass || ""

    const render = () => {

        if (props.external === true) {

            return (
                <a
                    className={
                        (
                            (props.defaultStyle !== false
                                ? " sanofi-menu-default uppercase "
                                : "")
                            + (props.className || "")
                            || ""
                            + inactiveClass
                        )
                    }
                    href={props.href}
                    {...props}
                >
                    {props.children}
                </a>
            )

        }

        return (
            <NavLink
                className={({ isActive }) =>
                    (isActive ? (activeClass) : inactiveClass) +
                    (
                        (props.defaultStyle !== false
                            ? " sanofi-menu-default uppercase "
                            : "")
                        + (props.className || "")
                        || "" )
                }
                to={props.href}
                {...props}
            >
                {props.children}
            </NavLink>
        )


    }


    return render();


}