import React, {useEffect, useState, Fragment, useCallback} from "react";
import SanofiButton from "../../SanofiButton";
import {TranslateText} from "../../../libs/LanguageProvider";
import useCurrentWidth from "../../../helper/currentWidth";

export default function OfferButton(props) {

    let width = useCurrentWidth();
    let ref = React.useRef();

    const [showSelector, setShowSelector] = useState(false);

    const [iteratorCount, setIteratorCount] = useState(15)
    const [multiplier, setMultiplier] = useState(1);
    const [entities, setEntites] = useState([])
    const [selectedValue, setSelectedValue] = useState(props.quantity)
    const [inputValue, setInputValue] = useState("")

    const [x, setX] = useState(0);
    const [y, setY] = useState(0)


    useEffect(() => {

        const buff = [];

        let value = 0;
        for (let i = 0; i < iteratorCount; i++) {
            if (value < 55) {
                value = (5 * i) * multiplier
            }
            if (value >= 50 && value <= 100) {
                value += 10;
            }
            //if (value >= 90) {value += 50;}
            if (value >= (props.maxOrderQuantity || 1000)) {
                break;
            }
            buff.push(value);
        }

        setEntites(buff);

    }, [])


    const onSelectValue = (value) => {
        setShowSelector(!showSelector);

        if (props.onValueChange) {
            props.onValueChange(value)
        }

    }


    const validateSetValue = () => {

        const quantity = parseInt(inputValue, 10)

        if (isNaN(quantity)) {
            setInputValue("")
            setShowSelector(!showSelector);

            if (props.onValueChange) {
                props.onValueChange(0)
            }

            return;
        }

        setInputValue("");

        if (props.onValueChange) {
            props.onValueChange(quantity)
        }

        setShowSelector(!showSelector);

    }

    const onKeyPress = (e) => {

        if (e.keyCode !== 13) {
            return;
        }

        validateSetValue();

    }

    const onValueInput = (value) => {

        if (isNaN(value)) {
            return;
        }

        if (parseInt(value) >= 0) {
            setInputValue(value);
        }

    }


    return (

        <Fragment>

            <div>
                <div
                    className={"offer-selector absolute p-6 bg-sanofi-selector rounded-lg border-2 border-b-sanofi-selector-border " + (showSelector ? "block" : "hidden")}>
                    <div className="flex flex-wrap">

                        {entities.map((x, i) => {
                            return <button type="button"
                                           className="m-1 w-12 h-12 inline-block border-2 border-b-sanofi-selector-border hover:bg-sanofi-white rounded-lg"
                                           onClick={() => onSelectValue(x)}>{x}</button>;
                        })}

                        <div className="relative w-full p-1.5 mt-1">
                            <input type="number"
                                   defaultValue={props.quantity}
                                   value={inputValue}
                                   onKeyDown={(e) => {
                                       onKeyPress(e)
                                   }}
                                   onInput={(e) => {
                                       onValueInput(e.target.value)
                                   }}
                                   className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 font-sans rounded-lg border-gray-100 border-l-2 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila offer-product-button"
                            />
                            <button type="button"
                                    onClick={(e) => {
                                        validateSetValue()
                                    }}
                                    className="absolute top-1.5 right-1.5 p-2.5 text-sm font-medium text-white hover:text-sanofi-white rounded-r-lg border border-blue-700 bg-sanofi-lila hover:bg-sanofi-dark-lila focus:ring-4 focus:outline-none focus:ring-blue-300 offer-product-button">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                </svg>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <SanofiButton onClick={(e) => {
                    setShowSelector(!showSelector)
                }} className="offer-product-button hover:text-sanofi-white">
                    {props.quantity > 0 ? props.quantity : TranslateText("offer-button-select-quantity")}
                </SanofiButton>
            </div>
        </Fragment>

    )


}