import React, {useEffect, useRef} from "react";

export default function Html(props){

    const divRef = useRef(null)
    const component = props.component;
    
    useEffect(() => {
        const content = document.createRange().createContextualFragment(component.htmlContent_) 
        divRef.current.appendChild(content) 

        return () => {
            divRef.current.innerHTML = '';
        }
    
    }, [component.htmlContent_]);


    return(
        <div className={`w-full`} ref={divRef}>
        </div>
    )
}