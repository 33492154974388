import React, {useState} from 'react';
import CustomInput from "../../../../elements/Input/CustomInput";
import DefaultButton from "../../../../elements/Button/DefaultButton";
import axios from "axios";
import {getLanguageContent, TranslateHtml} from "../../../../libs/LanguageProvider";

/*
    Password Reset Page
    props:
        lang: browser language
        showLoginForm: method to switch to the Login form
 */

export default function PasswordReset(props) {
    const [resetMail, setResetMail] = useState('') // Mail Adress to reset the account password for
    const [statusmessage, setStatusMessage] = useState('')
    const [disableResetButton, setDisableResetButton] = useState(false)
    const [validEmail, setValidEmail] = useState(true)

    const validEmailRegEx = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');

    const validateEmail = () => {
        if (!validEmailRegEx.test(resetMail)) {
            return false
            //setEmail([newEmail, true]);
        } else {
            return true
            //setEmail([newEmail, false]);
        }
    }

    const defaultMessage = () => {
        return (
            <div>
                Sie haben keine E - Mail erhalten? <br/>
                1. Warten Sie bitte 10 Minuten, bevor Sie es erneut versuchen. <br/>
                2. Überprüfen Sie Ihren Spam-Ordner. <br/>
                3. Versuchen Sie es erneut.
            </div>
        )
    }

    const requestNewPassword = () => {
        if (resetMail && validateEmail()) {
            setDisableResetButton(true)
            axios.post('/resetPassword', {
                emailAddress: resetMail,
                language: props.lang,
                pathname: window.location.pathname
            }).then(r => {
                setStatusMessage(defaultMessage())
                setDisableResetButton(false);
            })
        } else {
            setValidEmail(false)
        }
    }

    return (
        <div className="pwreset-container">
            <div className="loginscreen-left-content pwreset">
                {(!statusmessage) ?
                    <div>
                        <div className="flex flex-row" style={{borderBottom: "2px solid #BBBAC6"}}>
                            <h1 className="LoginHeader AvantGardeGothicBold">{getLanguageContent("demand-new-pwd", "Passwort vergessen")}</h1>
                        </div>
                        <p className="pwdForgetText mb-4" style={{marginTop: "1.2rem"}}>
                            <TranslateHtml name={"reset-pwd-text"}/>
                        </p>
                        <div className='login-font-arial'>
                            <CustomInput title='Email' type='email' changeEvent={(e) => setResetMail(e)}
                                         validation={!validEmail}
                                         errormessage={getLanguageContent("validation-email", 'Bitte tragen Sie eine valide email-Adresse ein.')}
                            />
                        </div>

                        <div className="loginscreen-two-grid mt-8 login-back">
                            <div className="login-form-pwd-forgot clickable" onClick={() => {
                                props.showLoginForm()

                            }}>
                                {getLanguageContent("back-to-login", 'Zurück zur Anmeldung')}
                            </div>
                            <DefaultButton
                                title={getLanguageContent("demand-new-pwd-btn", "Neues Passwort anfordern")}
                                bSize="xl"
                                clickevent={requestNewPassword}
                                disable={disableResetButton}
                            />
                        </div>
                    </div>
                    :
                    <div className='grid-one-row '>
                        <div className='mt-3'>
                            <div
                                className='login-success-message-header'>
                                {getLanguageContent("email-passwordreset-Success-header", 'Passwort erfolgreich angefordert')}
                            </div>
                            <div className='login-pwd-reset-info-message mt-3'>
                                <p>{getLanguageContent("email-passwordreset-Success-descritption",
                                    'Wir haben Ihnen eine E-Mail gesendet, sofern die von Ihnen eingegebene E-Mail Adresse registriert ist.')}</p>
                                <TranslateHtml name="email-passwordreset-Success-tipps"/>
                            </div>
                            <div className="login-form-pwd-back-login clickable" onClick={() => {
                                props.showLoginForm();
                            }}>
                                {getLanguageContent("back-to-login", 'Zurück zur Anmeldung')}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}