import * as React from "react";
import * as ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {LanguageProvider} from "./libs/LanguageProvider";
import axios from "axios"
import {ModalProvider} from "./libs/ModalProvider";
import Login from "./views/Login"
import {getUserLanguages} from "./helper/User";
import PDBBrandlistApp from "./views/PDB/BrandList/PDBBrandlistApp";
import PDBProductDetailsApp from "./views/PDB/ProductDetails/PDBProductDetailsApp";

export const fetchTranslations = ({language = "de"}) =>
    axios.get("/design/frontend/language/" + getUserLanguages() + ".json")
        .then((data) => {
            return data.data;
        });


if (document.getElementById("app") !== null) {
    ReactDOM.render(
        <BrowserRouter>
            <LanguageProvider fetchTranslations={fetchTranslations}>
                <ModalProvider>
                    <App
                        data={document.getElementById("app").getAttribute('doccheckid')}
                    />
                </ModalProvider>
            </LanguageProvider>
        </BrowserRouter>,
        document.getElementById("app")
    );
}

if (document.getElementById("pdb-app") !== null) {
    if (window.location.href.indexOf('brand') > -1) {
        ReactDOM.render(
            <BrowserRouter>
                <LanguageProvider fetchTranslations={fetchTranslations}>
                    <ModalProvider>
                        <PDBBrandlistApp frontendLanguages={JSON.parse(document.getElementById("pdb-app").getAttribute('frontendLanguages'))}/>
                    </ModalProvider>
                </LanguageProvider>
            </BrowserRouter>,
            document.getElementById("pdb-app")
        );
    } else {
        ReactDOM.render(
            <BrowserRouter>
                <LanguageProvider fetchTranslations={fetchTranslations}>
                    <ModalProvider>
                        <PDBProductDetailsApp frontendLanguages={JSON.parse(document.getElementById("pdb-app").getAttribute('frontendLanguages'))}/>
                    </ModalProvider>
                </LanguageProvider>
            </BrowserRouter>,
            document.getElementById("pdb-app")
        );
    }
}

//import Login from "./components/Login/Loginscreen";
const loginscreen = document.querySelectorAll("[loginscreen]");
loginscreen.forEach((el) => {
    ReactDOM.render(
        React.createElement(Login, {
            lang: el.getAttribute("lang"),
            languageitems: el.getAttribute("languagecomponent"),
            loginform: el.getAttribute("loginformAction"),
            sliderdata: el.getAttribute("sliderdata"),
            swissRxPath: el.getAttribute("swissRxPath"),
            supportSwissRx: el.getAttribute("supportSwissRx"),
            frontendAllowRegistration: el.getAttribute("frontendAllowRegistration")
        }),
        el
    );
});

window.addEventListener('load', () => {
    let chevron = document.createElement('i');
    chevron.classList.add("lnr");
    chevron.textContent = "^";

    let scrollEl = document.createElement('a');
    scrollEl.classList.add("scroll-to-top");
    scrollEl.classList.add("squared");

    scrollEl.appendChild(chevron);

    scrollEl.addEventListener('click', (e) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    })

    document.querySelector('body').appendChild(scrollEl);

    var offset = 200;
    window.addEventListener('scroll', function() {
        let scrollToTop = document.querySelector('.scroll-to-top');
        if (scrollToTop) {
            if (window.pageYOffset > offset) {
                if (!scrollToTop.classList.contains('visible-scroller') && !scrollToTop.classList.contains('is-fading')) {
                    scrollToTop.classList.add('visible-scroller');
                }
            } else {
                if (scrollToTop.classList.contains('visible-scroller') && !scrollToTop.classList.contains('is-fading')) {
                    scrollToTop.classList.remove('visible-scroller');
                }
            }
        }
    });
})