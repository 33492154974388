import React from 'react';
import {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import './Inputdesign.css'


/*
    Design an Input field
    props:
        type: type of the input (e.g. text, number ... )
        title: label of the Input field
        changeEvent: function that give set the input value (not required)
        errormessage: text for error message
        iRequired (boolean): whether input field ist required (default: false)
        iName: name of the inputfield - relevant for form (default none)
        iId: id of the inputfield - relevant for form (default none)
        onKeyPress: function to handle certain input
 */
const CustomInput = (props) => {
    const {useState} = React;
    const [inputValue, setValue] = props.inputValue ? useState(props.inputValue) : useState('');
    const [eye, seteye] = useState(true);
    const [pswtype, setpswtype] = useState("password");


    const handleInputChange = e => {
        if (props.changeEvent) {
            props.changeEvent(e.target.value)
        }
        setValue(e.target.value)
    }

    const Eye = () => {
        if (pswtype === "password") {
            setpswtype("text");
            seteye(false);

        } else {
            setpswtype("password");
            seteye(true);
        }
    }

    return (
        <div className="w100">
            <label className="input_label">
                {props.title}
            </label>
            <br/>
            <div className="field">

                { // customized password input
                    (props.type === 'password') ?
                        <div className="input-container">
                            <input
                                className={(props.validation) ? "field__input__error border_error" : "field__input border_normal"}
                                type={pswtype}
                                id={props.iId}
                                name={(props.iName) ? props.iName : 'none'}
                                onChange={handleInputChange}
                                required={props.iRequired}
                                onKeyPress={props.onKeyPress}
                                autoComplete="off"
                            />
                            <span className=''><a tabIndex="0" role="button"
                                                  data-toggle="popover" data-trigger="focus"
                                                  data-placement="left"
                                                  data-original-title="" title="">
                                {
                                    (eye) ?
                                        <FontAwesomeIcon onClick={Eye}
                                                         className="fa-eye"
                                                         icon={faEyeSlash}
                                                         color="#BBBAC6"
                                                         size="lg"
                                        />
                                        :
                                        <FontAwesomeIcon onClick={Eye}
                                                         className="fa-eye-slash"
                                                         icon={faEye}
                                                         color="#BBBAC6"
                                                         size="lg"
                                        />

                                }

                            </a></span>

                        </div>

                        :
                        <input
                            className={(props.validation) ? "field__input__error border_error" : "field__input border_normal"}
                            id={props.iId}
                            name={(props.iName) ? props.iName : 'none'}
                            type={props.type}
                            required={props.iRequired}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyPress={(event) => {
                                if (props.onKeyPress) {
                                    props.onKeyPress(event)
                                }
                            }}
                        />


                }
                {(props.validation || props.showErrorAsDefaultText ) ? <div className={'validation-errormessage' + ((!props.validation && props.showErrorAsDefaultText) ? ' show-error-as-default' : '')}>{props.errormessage}</div> : ''}
            </div>
        </div>
    )
}

export default CustomInput;