import React from 'react';
import {useState} from "react";
import './Buttondesign.css'
import {Spinner} from "react-bootstrap";
import {ArrowSmLeftIcon} from '@heroicons/react/solid';

/*
    Custom Button
    props:
        title: title of the Button
        clickevent: function to handle clickevent
        btype: type of button (default:button)
        bSize: define size/width of button (currently xl and default)
        disable: disable button
 */
const BackButton = (props) => {

    return (
        <div>
            {
                (props.bSize && props.bSize === 'xl') ?
                    <button className="defaultBtn backBtn w-xl h flex flex-row content-center items-center" onClick={props.clickevent}
                            type={(props && props.btype) ? props.btype : 'button'} disabled={props.disable}>
                        <ArrowSmLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        {(props.disable) ?
                            <div className="flex justify-center items-center mr-2">
                                <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div> : <div className={"mr-6"}>{props.title}</div>}
                    </button>
                    :
                    <button className="defaultBtn backBtn h flex flex-row content-center items-center" onClick={props.clickevent}
                            type={(props && props.btype) ? props.btype : 'button'}>
                        <ArrowSmLeftIcon className="h-5 w-5 ml-4 mr-2" aria-hidden="true"/>
                        {(props.disable) ?
                            <div className="flex justify-center items-center mr-6">
                                <div className="spinner-grow inline-block w-8 h-8 bg-current rounded-full opacity-0"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div> : <div className={"mr-6"}>{props.title}</div>}
                    </button>
            }
        </div>
    )
}

export default BackButton;