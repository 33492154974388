import React, {useEffect, useState, Fragment, useCallback} from "react";
import { Link, NavLink } from "react-router-dom";
import MenuLink from "../MenuLink";
import {TranslateText} from "../../../../libs/LanguageProvider";

export default function MenuDropDown(props) {

    const [isOpen, setIsOpen] = useState(false)


    return (

        <div className="relative inline-block text-left">

            <span style={{cursor:"pointer", display:"flex"}} onClick={() => setIsOpen(!isOpen)}>{props.children} {props.title}</span>

            {isOpen &&
                <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div className="py-1" role="none">
                        {props.linkChildren.map(link =>
                                <a className='flex text-gray-700 block px-4 py-2 text-sm' href={link.to}>
                                        {props.isCustom ? link.title : TranslateText(link.title)}
                                </a>
                         )}
                    </div>
                </div>
            }

        </div>
    )



}