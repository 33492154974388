import React, {Fragment, useState, useEffect} from "react";
import DefaultCard from "./components/DefaultCard";
import BgImageCard from "./components/BgImageCard";
import { screens } from "../../../../../../helpers/responsive";


export default function Card(props) {

    const component = props.component;

    const mobileSize = screens.sm;
    const [mobileView, setMobileView] = useState(window.innerWidth > mobileSize? false : true);

    useEffect(() => {

        const handleResize = () => {
            if(window.innerWidth > mobileSize) 
                return setMobileView(false);
            return setMobileView(true);
        }
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, [])

    
    const getElement = () => {
        switch (component.card_cardType) {
            case 'default':
                return <DefaultCard component={component} target={props.target}/>
            case 'bgImage':
                return <BgImageCard component={component} target={props.target}/>
            case 'university':
                return <UniversityCard component={component} target={props.target}/>
        }
    }

    
    return (
        <Fragment>
            {
                component.cardLink_ && (mobileView || !component.btnText_) ? 
                    <a 
                        id={`Card-${component.uuid}`}
                        className={`w-full flex flex-clumn grow`}
                        href={component.cardLink_}
                        target={props.target?? component.card_target?? '_blank'}
                    >

                        {getElement()}

                    </a>
                :
                    <div id={`Card-${component.uuid}`}
                        className={`w-full flex flex-col grow`}
                    >

                        {getElement()}

                    </div>
            }
        </Fragment>
    )
}