import React, {useEffect, useState, Fragment, useCallback} from "react";
import 'tw-elements';

export default function Textarea(props) {

    const myProps = {
        ...props,
        className: (props.overwriteClassName === undefined
            ? "form-control block p-2.5 w-full text-sm text-gray-900 bg-white font-sans rounded border-gray-300 border focus:outline-none border-gray-300 focus:ring-border-sanofi-dark-lila focus:border-sanofi-dark-lila "

            : "" ) +props.className,
        children: undefined,
    };

    return (
        <textarea {...myProps} />
    )


}