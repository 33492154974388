import React, { Fragment } from "react";
import { generateCSSString } from "../../../../../CmsPage/helpers/style";
import { getMarginStyles, getPaddingStyles, getResponsiveStyles } from "../../../../../CmsPage/helpers/style";
import ComponentFrontend from "../..";
import { userComponentAccess } from "../../../../helpers/user";
import ComponentBackend from "../../../../../CmsDesigner/components/Component";


export default function Container(props) {

    const {component, draggable, ...rest} = props;

    const backend = props.backend ?? false;

    const cssValue = [...getMarginStyles('container'), 'container_borderWidth', 'container_borderRadius'];
    const cssValueInnerPart = [...getPaddingStyles('container'), 
                            ...getResponsiveStyles('container', 'FlexDirection'), 
                            ...getResponsiveStyles('container', 'JustifyContent'), 
                            ...getResponsiveStyles('container', 'AlignItems')
                        ];
    

    return (
        <Fragment>
            {
                (backend || (component.hidden_ != 1 && userComponentAccess(component.roleId_, props.previewPageRoleId))) &&
                <div
                    id={`${component.anchor_ ? component.anchor_ : 'Container-' + component.uuid}`} 
                    className={`${generateCSSString(component, cssValue)} flex grow border-solid overflow-hidden`}
                    style={{
                        backgroundImage: `${component.image_ ? 'url("' + component.image_ + '")': 'unset'}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundColor: `${component.containerBackground_color ? component.containerBackground_color : 'unset'}`,
                        backgroundPosition: component.image_backgroundPosition,
                        borderColor: `${component.containerBorder_color}`
                    }}>

                    <div className={`overflow-hidden w-full flex flex-wrap ${generateCSSString(component, cssValueInnerPart)}`}
                        style={{
                            background: `${component.image_overlay ?? 'unset'}`,
                        }}>
                        {
                            component.children && component.children.map((child) => {
                                if(backend) 
                                    return <ComponentBackend component={child} {...rest} />
                                return <ComponentFrontend component={child} {...rest}/>
                            })
                        }
                    </div>
                </div>
            }     
        </Fragment>
    )
}