import {staticLinks, staticLinksSanoficonnect, staticLinksUniversity} from "../../data/staticLinks";
import { getSubURL } from "../User";

export const getLinks = (type = null, links = null) => {
    if (links) {
        return links
    } else {
        const pageType = type ? type : getSubURL()
        switch (pageType) {
            case 'marketplace':
                return staticLinks
            case 'sanoficonnect':
                return staticLinksSanoficonnect
            case 'university':
                return staticLinksUniversity
            default:
                return staticLinks;
        }
    }
}