import React, {useEffect, useState} from 'react';
import axios from "axios";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCircleExclamation,
    faTriangleExclamation,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import {CogIcon} from '@heroicons/react/solid';
import {getLanguageContent, TranslateHtml} from "../../../../libs/LanguageProvider";

/*
    Account Verify Page
    props:
        lang: browser language
        verificationToken: token used for verification
 */

export default function AccountVerify(props) {
    const [windowstate, setWindowstate] = useState('...');

    useEffect(async () => {
        if (props.runTokenCheck) {
            await checkForValidVerificationToken();
        }
    }, []);

    async function checkForValidVerificationToken() {
        axios.post('/verify/user', {
            verificationToken: props.verificationToken,
        }).then(r => {
            if (r.data.status === 'success') {
                setWindowstate('SuccessVerification')
            } else {
                setWindowstate('ErrorVerification')
            }
        })
    }

    return (
        <div className={"loginscreen-left-content no-border"}>
            <div className="loginscreen-left col-12 col-xl-5">
                <div
                    className="box-head loginscreen-item-align mb-2">
                    <div className="flex flex-row">
                        <h1 className="LoginHeader AvantGardeGothicBold">{getLanguageContent("registration-verify-head", 'Account Verifizierung')}</h1>
                    </div>
                </div>
                <div>
                    {(() => {
                        switch (windowstate) {
                            case 'SuccessVerification':
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className='center-content'><FontAwesomeIcon icon={faCircleCheck}
                                                                                         size='7x'
                                                                                         color='#089B05'/></div>

                                        <h2
                                            className='login-message-header login-success-message-header'>
                                            {getLanguageContent("registration-verify-successhead", 'Verifizierung erfolgreich')}
                                        </h2>
                                        <div
                                            className='login-success-message-text login-message-text mt-4'
                                            dangerouslySetInnerHTML={{ __html: getLanguageContent(
                                            "registration-verify-successtext",
                                            '<p>Das Sanofi Team prüft in Kürze ihre Daten um den Account freizuschalten.<br/><br/>Sie erhalten im Anschluss eine E-Mail.</p>'
                                            ) }}>
                                        </div>
                                    </div>
                                )
                            case 'ErrorVerification':
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className='center-content'><FontAwesomeIcon icon={faTriangleExclamation}
                                                                                         size='7x'
                                                                                         color='#9B0805'/></div>

                                        <h2
                                            className='login-message-header login-error-message-header'>
                                            {getLanguageContent("registration-verify-errorhead", 'Verifizierung fehlgeschlagen')}
                                        </h2>
                                        <div
                                            className='login-error-message-text login-message-text mt-4'
                                            dangerouslySetInnerHTML={{ __html: getLanguageContent(
                                                    "registration-verify-errortext",
                                                    '<p>Es ist ein Fehler bei der Verifizierung aufgetreten.<br/><br/>Bitte versuchen Sie zunächst sich mit dem Account anzumelden.</p>'
                                            ) }}>
                                        </div>
                                    </div>
                                )
                            default:
                                return (
                                    <div className='grid-one-row login-mt-2'>
                                        <div className={'flex flex-row place-content-center items-center'}>
                                            <div class="h-7 w-7 mr-3" viewBox="0 0 40 40" style={{animation: "spin 1.5s linear infinite"}}>
                                                <CogIcon className="h-7 w-7" aria-hidden="true"/>
                                            </div>
                                            <span>{getLanguageContent(
                                                "registration-verify-loadingtext",
                                                'Verifiziere Account...'
                                            )}</span>
                                        </div>
                                    </div>
                                )
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}