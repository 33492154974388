import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import {faRotateRight, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import Footer from "../../components/Footer";
import {TranslateHtml, TranslateText, Translate, getLanguageContent} from "../../libs/LanguageProvider";
import Multiselect from 'multiselect-react-dropdown';
import Typeahead from "../../components/Form/Typeahead";
import SanofiButton from "../../components/SanofiButton";
import {useModal} from "../../libs/ModalProvider";
import MessagePrompt from "../Modals/MessagePrompt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GLN from "../../components/GLN";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/solid";
import Checkbox from "../../components/Form/Checkbox";


export default function Profile(props) {

    let defaultPWD = "__PASSWORD_UNCHANGED__"
    let formRef = React.useRef()

    const {setModal, closeModal} = useModal()

    const [data, setData] = useState({})
    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorState, setErrorState] = useState(false)
    const [labels, setLabels] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false)
    const [noGLN, setNoGLN] = useState(false);
    const [password, setPassword] = useState(['', false])

    const validatePassword = (newPwd) => {
        if (!validPassword.test(newPwd)) {
            setPassword(() => [newPwd, true]);
        } else {
            setPassword(() => [newPwd, false]);
        }
    }


    const customMultiselectDropdownCss = {
        chips: {
            background: '#22004C'
        }
    }

    const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\.#?!@$%^&*-]).{8,}$');



    useEffect(() => {
        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/fetchProfile",
            data: {}
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setHasLoaded(true);
                setErrorState(true);
                return;
            }

            if (data.data.data) {
                if(data.data.data.profile?.userGln == '') {
                    setNoGLN(true);
                }
                const buff = {...data.data.data.profile, password: defaultPWD, passwordValidate: defaultPWD}
                setData(buff);
                setLabels(data.data.data.labels);
                setHasLoaded(true);
            }
        });
        
    }, [])


    const onFormSubmit = (e) => {

        if (!formRef.current) {
            return false;
        }

        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity();
            return;
        }

        if (data.passwordValidate !== data.password) {
            setModal(
                <MessagePrompt
                    content={<TranslateHtml name="profile-error-password-invalid"/>}
                />
            )
            return;
        }

        if (data.locationCode != undefined) {
            if (data.locationCode[0] != undefined) {
                data.locationCode = data.locationCode[0];
            }
            if (data.locationCode.id != undefined) {
                data.locationCode = data.locationCode.id;
            } else {
                delete (data.locationCode);
            }
        }

        if (data.companyAddressLocationCode != undefined) {
            if (data.companyAddressLocationCode[0] != undefined) {
                data.companyAddressLocationCode = data.companyAddressLocationCode[0];
            }
            if (data.companyAddressLocationCode.id != undefined) {
                data.companyAddressLocationCode = data.companyAddressLocationCode.id;
            } else {
                delete (data.companyAddressLocationCode);
            }
        }

        setHasLoaded(false);

        axios({
            dataType: "json",
            method: "post",
            url: "/module/frontend/updateProfile",
            data: data
        }).then(data => {

            if (data.data.status && data.data.status === "error") {
                alert(data.data.message);
                setHasLoaded(true);
                setErrorState(true);
                return;
            }
            window.location.reload();
        });

        setHasLoaded(false);

    }


    const onValueChange = (field, value) => {
        const buff = {...data};
        buff[field] = value;
        // console.log(buff);
        setData(buff);
    }

    const changeUserLocation = (value) => {
        onValueChange("locationCode", value);
    }

    const changeInstitutionalLocation = (value) => {
        onValueChange("companyAddressLocationCode", value);
    }

    const switchPasswordInputState = (switchStateOfRepeatedPassword = false) => {
        if (!switchStateOfRepeatedPassword) {
            setShowPassword(!showPassword);
        } else {
            setShowRepeatedPassword(!showRepeatedPassword);
        }
    }

    const passwordConstraintsMatching = (password) => {

        let result =
            password.length >= 8 &&
            password.match(/[A-Z]/) != null &&
            password.match(/[0-9]/) != null &&
            password.match(/[!@#$%&*()]/) != null;

        return result;
    }

    const handleOnNoGLNChange = () => {
        setNoGLN(oldValue => !oldValue);
        onValueChange("userGln", '');
    }

    return (

        <Fragment>

            <Page>
                <TopBar type={document.getElementById("app") && document.getElementById("app").getAttribute("frontendLogin") ? "university" : "sanoficonnect"}/>
                <Hero type={document.getElementById("app") && document.getElementById("app").getAttribute("frontendLogin") ? "university" : "sanoficonnect"} menuType={-1}/>
                <ContentArea>

                    <h1 className={"text-4xl color-sanofi-dark-lila font-bold"}>{TranslateText("profile-main-title")}</h1>

                    <br/>

                    <h2 className={"text-3xl color-sanofi-dark-lila"}>{TranslateText("profile-sub-title-personal")}</h2>

                    {hasLoaded &&
                        <form ref={formRef} id={"profile-form"}>

                            <div>
                                <TranslateHtml name="profile-content-top"/>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-sprache")}*</label>
                                    </div>
                                    <Select name={"language"} value={data.language} onChange={(e) => {
                                        onValueChange("language", e.target.value)
                                    }} required>
                                        <option value="0">{TranslateText("profile-language-option-german")}</option>
                                        <option value="1">{TranslateText("profile-language-option-french")}</option>
                                        <option value="2">{TranslateText("profile-language-option-italian")}</option>
                                    </Select>
                                </div>

                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-whoami")}*</label>
                                    </div>
                                    <Select name={"userType"} value={data.userType} onChange={(e) => {
                                        onValueChange("userType", e.target.value)
                                    }} required>
                                        <option value="0">{TranslateText("profile-label-please-select")}</option>
                                        <option value="1">{TranslateText("profile-label-whoami-pharmacist")}</option>
                                        <option value="2">{TranslateText("profile-label-whoami-druggist")}</option>
                                        <option
                                            value="3">{TranslateText("profile-label-whoami-druggist-assistant")}</option>
                                        <option value="4">{TranslateText("profile-label-whoami-pa")}</option>
                                        <option value="5">{TranslateText("profile-label-whoami-referent")}</option>
                                        <option value="6">{TranslateText("profile-label-whoami-sanofi-member")}</option>
                                    </Select>
                                </div>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-3">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-gender")}*</label>
                                    </div>

                                    <Select name={"gender"} value={data.gender} onChange={(e) => {
                                        onValueChange("gender", e.target.value)
                                    }} required>
                                        <option value="0">{TranslateText("profile-gender-option-male")}</option>
                                        <option value="1">{TranslateText("profile-gender-option-female")}</option>
                                        <option value="2">{TranslateText("profile-language-option-diverse")}</option>
                                    </Select>
                                </div>
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-title")}</label>
                                    </div>
                                    <Input value={data.title} onChange={(e) => {
                                        onValueChange("title", e.target.value)
                                    }} required={false} type="text"/>
                                </div>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-2">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-first-name")}*</label>
                                    </div>
                                    <Input value={data.firstName} onChange={(e) => {
                                        onValueChange("firstName", e.target.value)
                                    }} required type="text"/>
                                </div>
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-last-name")}*</label>
                                    </div>
                                    <Input value={data.lastName} onChange={(e) => {
                                        onValueChange("lastName", e.target.value)
                                    }} required type="text"/>
                                </div>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-4">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-gln-personal")}{!noGLN && <span>*</span>}</label>
                                    </div>
                                    <Input
                                        className={noGLN ? "bg-gray-100 cursor-not-allowed text-gray-700" : ""}
                                        required={!noGLN}
                                        readOnly={noGLN} 
                                        value={data.userGln} 
                                        onChange={(e) => {onValueChange("userGln", e.target.value)}} 
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div>
                                <Checkbox
                                    type="checkbox"
                                    id="noGLNCheckbox"
                                    onChange={handleOnNoGLNChange}
                                    defaultChecked={noGLN}
                                />
                                <label htmlFor="noGLNCheckbox" className="cursor-pointer">
                                    {getLanguageContent("profile-label-gln-none", 'Keine')}
                                </label>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-4">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-locationcode")}*</label>
                                    </div>
                                    <Typeahead
                                        selected={data.locationCode || undefined}
                                        onSelect={(value) => {
                                            changeUserLocation(value);
                                        }}
                                    />
                                </div>
                                <div className="flex-1">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("profile-label-street")}*</label>
                                        </div>
                                        <Input value={data.street} onChange={(e) => {
                                            onValueChange("street", e.target.value)
                                        }} required type="text"/>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-4">
                                <div className="flex-1">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("profile-label-email")}*</label>
                                        </div>
                                        <Input value={data.emailAddress} onChange={(e) => {
                                            onValueChange("emailAddress", e.target.value)
                                        }} required type="email"/>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className={"mb-1.5 hidden md:block"}>
                                        <label>&nbsp;</label>
                                    </div>
                                    <label
                                        dangerouslySetInnerHTML={{__html: TranslateText("profile-label-email-permissioninfo")}}></label>
                                </div>
                            </div>


                            {(data.loginType === "0" || data.loginType === "null") &&
                                <>
                                <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 mt-6 md:space-y-0">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("profile-label-password")}*</label>
                                        </div>
                                        <div style={{position: "relative"}}>
                                            <Input
                                                style={{
                                                    borderColor: (passwordConstraintsMatching(data.password) || data.password == defaultPWD) ? 'inherit' : 'red',
                                                }}
                                                value={data.password} onChange={(e) => {
                                                onValueChange("password", e.target.value)
                                            }} required type={showPassword ? "text" : "password"}/>
                                            <div className={"password-input-helper"}>
                                                {!showPassword &&
                                                    <EyeIcon className="helper-icon h-5 w-5" aria-hidden="true"
                                                             onClick={() => switchPasswordInputState()}/>}
                                                {showPassword &&
                                                    <EyeOffIcon className="helper-icon h-5 w-5" aria-hidden="true"
                                                                onClick={() => switchPasswordInputState()}/>}
                                            </div>
                                        </div>
                                        <p className={"error-notice " + ((passwordConstraintsMatching(data.password) || data.password == defaultPWD) ? "hidden" : "")}
                                           dangerouslySetInnerHTML={{__html: TranslateText("validation-password")}}></p>
                                    </div>
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("profile-label-password-repeat")}*</label>
                                        </div>
                                        <div style={{position: "relative"}}>
                                            <Input
                                                style={{
                                                    borderColor: (passwordConstraintsMatching(data.passwordValidate) || data.passwordValidate == defaultPWD) ? 'inherit' : 'red',
                                                }}
                                                value={data.passwordValidate} onChange={(e) => {
                                                onValueChange("passwordValidate", e.target.value)
                                            }} required type={showRepeatedPassword ? "text" : "password"}/>
                                            <div className={"password-input-helper"}>
                                                {!showRepeatedPassword &&
                                                    <EyeIcon className="helper-icon h-5 w-5" aria-hidden="true"
                                                             onClick={() => switchPasswordInputState(true)}/>}
                                                {showRepeatedPassword &&
                                                    <EyeOffIcon className="helper-icon h-5 w-5" aria-hidden="true"
                                                                onClick={() => switchPasswordInputState(true)}/>}
                                            </div>
                                        </div>
                                        <p className={"error-notice " + ((passwordConstraintsMatching(data.passwordValidate) || data.passwordValidate == defaultPWD) ? "hidden" : "")}
                                           dangerouslySetInnerHTML={{__html: TranslateText("validation-password")}}></p>
                                    </div>
                                </div>

                                <div>
                                    <TranslateHtml name='validation-password'/>
                                </div>
                                </>
                            }

                            <br/>
                            <br/>
                            <h2 className={"text-3xl color-sanofi-dark-lila"}>{TranslateText("profile-sub-title-institutional")}</h2>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-kette")}*</label>
                                    </div>
                                    <Select required value={data.chain} onChange={(e) => {
                                        onValueChange("chain", e.target.value)
                                    }}>
                                        <option value="1">{TranslateText("profile-label-kette-option-none")}</option>
                                        {labels.chain.map((label) => (<option value={label.id}>{label.name}</option>))}
                                    </Select>
                                </div>
                            </div>
                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-3">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-name-apotheke")}*</label>
                                    </div>
                                    <Input required value={data.companyName} onChange={(e) => {
                                        onValueChange("companyName", e.target.value)
                                    }} type="text"/>
                                </div>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-locationcode")}*</label>
                                    </div>
                                    <Typeahead
                                        selected={data.companyAddressLocationCode || undefined}
                                        onSelect={(value) => {
                                            changeInstitutionalLocation(value);
                                        }}
                                    />
                                </div>
                                <div className="flex-1">
                                    <div className="flex-1">
                                        <div className={"mb-1.5"}>
                                            <label>{TranslateText("profile-label-street")}*</label>
                                        </div>
                                        <Input value={data.companyAddressStreet} onChange={(e) => {
                                            onValueChange("companyAddressStreet", e.target.value)
                                        }} required type="text"/>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-wholesaler")}*</label>
                                    </div>
                                    <Select name={"wholesaler"} value={data.wholesaler} onChange={(e) => {
                                        onValueChange("wholesaler", e.target.value)
                                    }} required>
                                        <option value="" selected>{getLanguageContent('profile-label-please-select', 'Bitte auswählen')}</option>
                                        {labels.wholesaler.map((label) => (
                                            <option value={label.id}>{label.name}</option>))}
                                    </Select>
                                </div>
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-wholesaler-number")}</label>
                                    </div>
                                    <Input value={data.wholesalerNumber} onChange={(e) => {
                                        onValueChange("wholesalerNumber", e.target.value)
                                    }} type="text"/>
                                </div>
                            </div>

                            <GLN loginType={data.loginType} gln={data.gln} onValueChange={onValueChange}
                                 proPharmaActive={data.proPharmaActive} proPharmaGLN={data.proPharmaGLN}
                                 setErrorState={setErrorState} proPharmaToken={data.proPharmaToken}/>

                            <div className="flex-none md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0 mt-6">
                                <div className="flex-1">
                                    <div className={"mb-1.5"}>
                                        <label>{TranslateText("profile-label-prefered-contact-type")}</label>
                                    </div>
                                    <Select name={"companyContactPref"} value={data.companyContactPref}
                                            onChange={(e) => {
                                                onValueChange("companyContactPref", e.target.value)
                                            }} required>
                                        <option value="0">{TranslateText("profile-label-please-select")}</option>
                                        <option
                                            value="1">{TranslateText("profile-label-contact-type-externalsales")}</option>
                                        <option value="2">{TranslateText("profile-label-contact-type-phone")}</option>
                                        <option value="3">E-Mail</option>
                                    </Select>
                                </div>
                            </div>

                            {(data.loginType === "0" || data.loginType === "null") &&
                                <div>
                                    <TranslateHtml name="profile-content-bottom-password"/>
                                </div>
                            }

                            {(data.loginType === "1") &&
                                <div>
                                    <TranslateHtml name="profile-content-bottom-no-password"/>
                                </div>
                            }

                            <div className="md:text-right text-center mt-8">
                                <SanofiButton type="button" onClick={(e) => onFormSubmit(e)}>
                                    {TranslateText("profile-button-save")}
                                </SanofiButton>
                            </div>
                        </form>
                    }

                    {!hasLoaded &&
                        <div className="flex items-center justify-center h-screen">
                            <div
                                className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                        </div>
                    }

                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>

    )
}