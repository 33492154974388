import React from "react";

export default function RatingSingle(props) {
    const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <div className="flex justify-between px-3 scale-singleRating-container flex-wrap">
            <div className="flex mb-2 justify-between w-full">
                <div className="mb-2"><label
                    className="ml-2 text-black mb-0">{(props.options && props.options[0] && props.options[0].value) ? props.options[0].value : ' '} </label>
                </div>
                <div className="mb-2"><label
                    className="ml-2 text-black mb-0">{(props.options && props.options[1] && props.options[1].value) ? props.options[1].value : ' '}</label>
                </div>
            </div>

            {
                number.map((n) => {
                    return <button onClick={(e) => {
                        e.preventDefault()
                        props.changeEvent(n)
                    }}
                                   className={(props.value === n) ? "scale-singleRating-checked w-full" : "scale-singleRating w-full hover:scale-singleRating-checked "}>
                        <div>{n}</div>
                    </button>
                })
            }
        </div>
    )
}