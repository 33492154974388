import React, {useEffect, useState, Fragment, useMemo} from "react";
import {usePopper} from 'react-popper';
import {createPopper} from '@popperjs/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";


export default function Popover(props) {
    const [showSelector, setShowSelector] = useState(false);

    const [referenceEl, setReferenceEl] = useState(null);
    const [popperEl, setPopperEl] = useState(null);

    const {styles, attributes} = usePopper(referenceEl, popperEl, {
        placement: "top",
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true, // false by default
                },
            },
        ],
    });

    return (
        <Fragment>
            <div>
                <button
                    className="info-button-custom rounded-2xl text-sanofi-dark-lila ml-2 rounded"
                    ref={setReferenceEl}
                    type="button"
                    onClick={(e) => {
                        setShowSelector(!showSelector)
                    }}
                >
                {/* <FontAwesomeIcon icon={faCircleInfo} size='1x'/> */}
                <div className={`text-white text-sm w-5 h-5 rounded-full bg-sanofi-dark-lila justify-center items-center flex`}>
                    <div><strong>i</strong></div>
                </div>
                </button>

                <div
                    className={'bg-white border p-2 border-sanofi-dark-lila w-60 md:w-80 rounded-lg relative overflow-hidden popover-content text-xs ' + (showSelector ? "block" : "hidden")}
                    ref={setPopperEl}
                    style={styles.popper}
                    data-show="true"
                    {...attributes.popper}
                >
                    <div className="flex flex-col">
                        {props.info}
                    </div>
                </div>

            </div>
        </Fragment>
    );
    
}