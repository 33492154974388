import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import axios from "axios";
import Page from "../../components/Page";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import ContentArea from "../../components/ContentArea";
import Footer from "../../components/Footer";
import {TranslateHtml, TranslateText, Translate} from "../../libs/LanguageProvider";
import {useModal} from "../../libs/ModalProvider";
import SanofiButton from "../../components/SanofiButton";
import OfferSelection from "../../components/Home";
import {useParams} from "react-router-dom";
import OfferOverview from "../../components/Home";


export default function Home(props) {


    const {setModal, closeModal} = useModal()

    const [offers, setOffers] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorState, setErrorState] = useState(false)
    const [labels, setLabels] = useState(false)

    const params = useParams();
    
    document.title = `Sanofi Marketplace`; 

    // console.log("CALLING HOME!");
    // console.log("WHAT??", params);

    return (

        <Fragment>

            <Page>
                <TopBar/>
                <Hero/>
                <ContentArea>
                    <OfferOverview showUpcomingOffer={true}/>
                </ContentArea>
                <Footer/>
            </Page>

        </Fragment>
    )
}