import React from 'react';
import Carousel from 'nuka-carousel';
import '../../SliderStyle.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DefaultButton from "../../../../elements/Button/DefaultButton";
import {faHistory, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'
import clsx from "clsx";
import Countdown, {zeroPad, calcTimeDelta, formatTimeDelta} from 'react-countdown';

/*
    Custom Carousel
    props:
        actualMessages: { }
        sliderdata: JSON Object
        languageitemfunction: function to get the languageitem by code

 */
const LoginSlider = (props) => {

    const sliderdata = props.sliderdata ? JSON.parse(props.sliderdata) : {};//JSON.parse(props.sliderdata)

    const str_days = props.languageitemfunction('login-countdown-days', 'Tage')
    const str_day = props.languageitemfunction('login-countdown-day', 'Tag')
    const str_std = props.languageitemfunction('login-countdown-std', 'Std')

    const sliderRotation = () => {
        return (sliderdata && sliderdata.length > 1)
    }

    //console.log(JSON.parse(props.sliderdata))


    const renderer = ({days, hours}) => {
        let stringDay = str_days

        const stringHours = str_std
        if (!(days > 1)) {
            stringDay = str_day
        }
        return (
            <span>
                {zeroPad(days)} {stringDay} {zeroPad(hours)} {stringHours}
            </span>)
    }


    const getSliderdata = (data) => {
        switch (data.type) {

            case '0': // General Information
                // console.log('Type 0')
                return (
                    <div className='w-100 informationslide'
                         style={{
                             backgroundImage: `url(${data.picture})`,
                         }}>
                        <div className="w-100">
                            <div className="centerItem content-infoslider">
                                <h2 className='content-infoslider-header'>{data.title}</h2>
                                <p className='mt-2 mb-2'>
                                    {data.description}
                                </p>
                                {(data.link && data.linkTitle) ?
                                    <div className="center-button mb-2">
                                        <a href={data.link}>
                                            <DefaultButton title={data.linkTitle}/>
                                        </a>
                                    </div> :
                                    <div></div>
                                }

                            </div>
                        </div>
                    </div>
                )
            case '1': // Offerten Information
                // because of Safari we have to replace ' ' with T -> https://stackoverflow.com/questions/21883699/safari-javascript-date-nan-issue-yyyy-mm-dd-hhmmss
                let expirationDate = new Date();
                if (data.expirationDate.date) {
                    expirationDate = new Date((data.expirationDate.date).replace(/\s/, 'T'));
                }

                return (
                    <div className='carouselOfferte h-100'>
                        <div className='carouselHeader flex flex-col'>
                            <div className='sliderclock place-content-center'>
                                <FontAwesomeIcon
                                    icon={faHistory} size="auto"
                                    color="#22004C"/>
                            </div>
                            <div className="carouselHeadertext">
                                <div
                                    className="carouselHeadertextHeader">
                                    {props.languageitemfunction('login-countdown-offerte-text', 'Sonderangebot endet in')}

                                </div>
                                <div className="carouselHeadertextSubheader">
                                    <Countdown
                                        date={expirationDate}
                                        intervalDelay={0}
                                        precision={3}
                                        renderer={renderer}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='slidercontent-oferteninformation-container'>
                            <div className='slidercontent-oferteninformation-item-1'>
                                <h2 className='carouselTitle'> {data.title} </h2>
                            </div>
                            <div className='slidercontent-oferteninformation-item-2' style={{
                                backgroundImage: `url(${data.picture})`,
                            }}>
                            </div>
                        </div>
                    </div>
                )
            default:
                return (<div></div>)

        }
    }

    return (
        <div className={clsx("carouselBackground", 'box-right', 'w-100', 'h-100')}>

            {
                (sliderdata) ?
                    <Carousel
                        renderCenterLeftControls={({previousSlide}) => (
                            (sliderdata.length > 1) ?
                                <button className="carousel-navigationbutton ml-1" onClick={previousSlide}>
                                    <FontAwesomeIcon icon={faAngleLeft} size="5x"/>
                                </button> : <></>
                        )}
                        renderCenterRightControls={({nextSlide}) => (
                            (sliderdata.length > 1) ?
                                <button className="carousel-navigationbutton mr-1" onClick={nextSlide}>
                                    <FontAwesomeIcon icon={faAngleRight} size="5x"/>
                                </button> : <></>
                        )}
                        autoplay={sliderRotation()}
                        autoplayInterval={3000}
                        wrapAround={sliderRotation()}
                        pauseOnHover={true}

                    >
                        {sliderdata.map((data) => {
                            return (<div>{getSliderdata(data)}</div>)

                        })}

                    </Carousel>

                    :
                    <div></div>

            }

        </div>
    )

}

export default LoginSlider;