import { mapLangstoInitString } from "./language";

export const sections = ['cmsPageHeader', 'cmsPageHero', 'components', 'cmsPageFooter'];

export const getComponentList = (componentName, availableComponents) => {
    for(let list in availableComponents)
    {
        if (availableComponents[list].includes(componentName))
            return availableComponents[list];
    }
    return availableComponents.default;
}


export const setComponentObject = (type, cmsproperties) => {
    
    let component = { component: type };
    let componentProps = cmsproperties.properties[type] ?? [];

    for(let prop of componentProps){
        if(prop.option === 'children' && prop.value === '_array_'){
            component[prop.option] = [];
        }
        else if(prop.value.includes('_stringByLang_')){
            let defaultValue = prop.value.split('_').pop();
            component[prop.option] = mapLangstoInitString(cmsproperties.languages, defaultValue);
        }
        else 
            component[prop.option] = prop.value;
    }

    return component;
}

export function findComponentByKey(data, key, value) {

    function find(component) {
        if (component[key] === value  && !component.deleted) {
            result = component;
            return true;
        }
        return component.children && component.children.some(find);
    }

    let result;
    data.some(find);
    return result;
}

export const getComponentByLang = (component, lang, hideDeleted = false) => {

    function getPropertiesByLang (component, lang){
        for(let key in component){
            if(key === 'formData'){
                component[key].fields = component[key].fields[lang];
            }
            component[key] = component[key] ? (component[key][lang] ?? component[key]) : component[key];
        }
        
        if(hideDeleted && component.children)
            component.children = component.children.filter((child) => child.deleted !== 1);


        if(component.children)
            for(let child of component.children)
                getPropertiesByLang(child, lang);
    }

    let componentByLang = JSON.parse(JSON.stringify(component));

    getPropertiesByLang(componentByLang, lang);

    return componentByLang;
}

export const findComponentBySection = (data, key, value, sections_ = sections) => {
    for(let section of sections_){
        let component = findComponentByKey(data[section], key, value)
        if (component) return {section, component};
    }
}

export const getPageByLang = (page, lang) => {
    page = getComponentByLang(page, lang)
    for(let section of sections)
        page[section] = page[section]?.length > 0 ? [getComponentByLang(page[section][0], lang, true)] : [];

    return page;
};


export const getComponentForBackend = (section, cmsproperties) => {
    let newSection = JSON.parse(JSON.stringify(section));
    const languages = cmsproperties.languages;

    const getValueId = (prop, value) => {
        let properties = cmsproperties.properties;

        let id = null;

        let propName = prop.split('_').pop();
        let option = properties[propName]?.find((option) => option.value === value);

        if(option !== undefined)
            id = option.id;
        
        return id;
    }

    const setComponentObj = (component, index) => {
        
        component.properties = [];

        let properties = cmsproperties.properties[component.component].map((prop) => prop.option);
        
        for(let prop of properties){
            if(prop !== 'children' && component[prop]!== undefined) {
                for(let lang of languages){
                    let value = component[prop] ? component[prop][lang] ?? component[prop] : component[prop];
                    let valueId = getValueId(prop, value);
                    component.properties.push({
                        "lang": lang,
                        "property": prop,
                        "valueId": valueId, 
                        "override": valueId === null ?  value : null
                    })
                }
                delete component[prop];
            }
        }
        component.position = index;
        component.children?.forEach(setComponentObj);
    

    }

    newSection.forEach(setComponentObj);

    return newSection
}