import React, { useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

function CustomSlider({ children, showPreview }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDone, setSlideDone] = useState(true);
    const [timeID, setTimeID] = useState(null);

    useEffect(() => {
        if (slideDone) {
            setSlideDone(false);
            setTimeID(
                setTimeout(() => {
                    slideNext();
                    setSlideDone(true);
                }, 5000)
            );
        }
    }, [slideDone]);

    const slideNext = () => {
        setActiveIndex((val) => {
            if (val >= children.length - 1) {
                return 0;
            } else {
                return val + 1;
            }
        });
    };

    const slidePrev = () => {
        setActiveIndex((val) => {
            if (val <= 0) {
                return children.length - 1;
            } else {
                return val - 1;
            }
        });
    };

    const AutoPlayStop = () => {
        if (timeID > 0) {
            clearTimeout(timeID);
            setSlideDone(false);
        }
    };

    const AutoPlayStart = () => {
        if (!slideDone) {
            setSlideDone(true);
        }
    };

    return (
        <div className={"slider-wrapper"}>
            <div
                className="container__slider"
                onMouseEnter={AutoPlayStop}
                onMouseLeave={AutoPlayStart}
            >
                <div>
                    {children.map((item, index) => {
                        return (
                            <div
                                className={"slider__item slider__item-active-" + (activeIndex + 1)}
                                key={index}
                            >
                                <div className={"slider__img__wrapper"} onClick={(e) => {
                                    showPreview(children[index].props.src ?? false);
                                }}>
                                    {item}
                                </div>
                            </div>
                        );
                    })}
                    <button
                        className="slider__magnifier"
                        onClick={(e) => {
                            showPreview(children[activeIndex].props.src ?? false);
                        }}
                    >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                </div>

                <div className="container__slider__links">
                    {children.map((item, index) => {
                        return (
                            <button
                                key={index}
                                className={
                                    activeIndex === index
                                        ? "container__slider__links-small container__slider__links-small-active"
                                        : "container__slider__links-small"
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveIndex(index);
                                }}
                            ></button>
                        );
                    })}
                </div>
            </div>
            <button
                className="slider__btn-next"
                onClick={(e) => {
                    e.preventDefault();
                    slideNext();
                }}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <button
                className="slider__btn-prev"
                onClick={(e) => {
                    e.preventDefault();
                    slidePrev();
                }}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        </div>
    );
}

export default CustomSlider;
