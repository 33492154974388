import React, {Fragment} from "react";
import ContentArea from "../../components/ContentArea";
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import {Provider} from "react-redux";
import FormBundle from "../../components/FormDesigner";
import {useParams} from "react-router-dom";
import TopBar from "../../components/Header/TopBar";
import Hero from "../../components/Header/Hero";
import WebinarregistrationScreen from "../../components/Webinarregistration";
import store from "../../components/FormDesigner/store/FormStore";
import {getLanguageContent} from "../../libs/LanguageProvider";


// PATH => /webinarregistration/{slug}
export default function Webinarregistration(props) {

    let {slug} = useParams();

    return (
        <Page>
            <TopBar logo={'Sanofi University'}/>
            <Hero back='/university/home'
                  backTitle={getLanguageContent('webinarregistration-back-button', 'Zurück zu allen Angeboten ')}/>
            <ContentArea>
                <WebinarregistrationScreen slug={slug}/>
            </ContentArea>
            <Footer/>
        </Page>
    )
}

