import React, {useEffect, useState, Fragment, useCallback} from "react";

export default function ContentArea(props) {


    return (

        <div className={"md:container md:mx-auto bg-white p-6 pb-10 " + (props.className || "")}>
            {props.children}
        </div>

    )


}