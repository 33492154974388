import React, { useState, useEffect } from 'react';

export default function SucButton(props){

    const [buttonClassNames, setButtonClassNames] = useState(props.className);

    useEffect(() => {
		let classN = props.className;
		if (props.addType == 'saveButton' && props.changedFields) {
			if (props.changedFields.length > 0) {
				classN += ' changedFieldsClass';
			}
		}
		setButtonClassNames(classN);
	}, [props.changedFields, props.className]);

    return(
        <div {...props} className={buttonClassNames}>
            {props.children && props.children} {props.text && props.text}
        </div>
    )
}