import React, {useEffect, useState, Fragment, useCallback, useContext} from "react";
import {TranslateHtml, TranslateText, Translate} from "../../../libs/LanguageProvider";
import {
    evaluateRebateRule,
    getAdditionalItems,
    getAdditionalReduction,
    getProductOfOptionItem,
    getRuleAdditional
} from "../../../helper/OfferCalculation";


export default function SimpleCart(props) {

    const calculateCartSums = () => {

        let quantity = 0;
        let price = 0;

        if (props.cart !== undefined && Array.isArray(props.cart)) {

            for (let i = 0; i < props.cart.length; i++) {
                const cart = props.cart[i];


                if (cart.priceReduction !== undefined) {
                    continue;
                }


                quantity += parseInt(cart.quantity);
                price += (parseFloat(cart.price) * parseInt(cart.quantity));


            }
        }

        return {quantity: quantity, price: price};

    }

    const getCartSum = () => {
        let sum = calculateCartSums()
        return sum.price
    }

    const getCartQuantity = () => {
        let sum = calculateCartSums()
        return sum.quantity
    }

    const getFullSum = () => {

        let sum = calculateCartSums()
        let reduction = calculateReduction()
        let additionalReduction = calculateAdditionalReduction()
        return sum.price - reduction - additionalReduction;

    }

    const getActiveRebate = () => {

        if (props.activeRule === undefined) {
            return undefined;
        }

        if (props.rules === undefined) {
            return undefined;
        }

        return (props.rules[props.activeRule] === undefined ? undefined : props.rules[props.activeRule])

    }

    const getActiveRebateValue = (key) => {

        const activeRebate = getActiveRebate();

        if (activeRebate === undefined) {
            return -1;
        }

        return (activeRebate[key] === undefined ? undefined : activeRebate[key]);

    }

    const getRebateType = () => {
        return (getActiveRebateValue("type") === undefined ? -1 : getActiveRebateValue("type"));
    }

    const calculateReduction = () => {

        let sum = calculateCartSums()
        let reduction = 0;
        const rebateValue = getActiveRebateValue("value");

        switch (parseInt(getRebateType())) {
            case 0:
                if (parseFloat(rebateValue) > 0) {
                    reduction = (sum.price / 100) * parseFloat(rebateValue)
                }
                break;
            case 1:
                if (parseFloat(rebateValue) > 0) {
                    reduction = parseInt(rebateValue)
                }
                break;
        }

        return reduction;

    }

    const calculateAdditionalReduction = () => {

        const activeRule = getActiveRebate()

        if (activeRule === undefined) {
            return 0;
        }

        return getAdditionalReduction(getRuleAdditional(activeRule));

    }

    const getReductionDisplayType = () => {

        switch (parseInt(getRebateType())) {
            case 0:
                return "%"
            case 1:
                return TranslateText("offer-cart-label-currency")
        }

        return "";

    }

    const getAdditionalQuantity = () => {

        let quantity = 0;
        if (props.cart !== undefined && Array.isArray(props.cart)) {

            for (let i = 0; i < props.cart.length; i++) {
                const cart = props.cart[i];
                if (cart.priceReduction !== undefined) {
                    quantity += parseInt(cart.quantity);
                }
            }
        }

        return quantity;

    }


    return (


        <div>
            <div className={"p-4"}>
                <div className="flex justify-between items-center">
                    <div>
                        {TranslateText("offer-cart-label-subtotal")}:
                    </div>
                    <div>
                        {getCartSum().toFixed(2)} {TranslateText("offer-cart-label-currency")}
                    </div>
                </div>

                {getRebateType() !== -1 &&
                    <div className="flex justify-between items-center">
                        <div>
                            {TranslateText("offer-cart-label-you-save")}:
                        </div>
                        <div>
                            {calculateReduction().toFixed(2)} {TranslateText("offer-cart-label-currency")} ({parseInt(getActiveRebateValue("value")).toFixed(2)} {getReductionDisplayType()})
                        </div>
                    </div>
                }

                {calculateAdditionalReduction() > 0 &&

                    <div className="flex justify-between items-center">
                        <div>
                            {TranslateText("cart-label-additional-rebate-sub")}:
                        </div>
                        <div>
                            - {calculateAdditionalReduction().toFixed(2)} {TranslateText("offer-cart-label-currency")}
                        </div>
                    </div>
                }

                <div className="flex justify-between items-center">
                    <div>
                        {TranslateText("offer-cart-label-full-sum")}:
                    </div>
                    <div>
                        {getFullSum().toFixed(2)} {TranslateText("offer-cart-label-currency")}
                    </div>
                </div>
            </div>

        </div>


    )


}