
const validEmail = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
const validNumber = new RegExp('^[1-9]\\d{0,2}(\\.\\d{3})*(,\\d+)?$');

const validateEmail = (newValue) => {
    return validEmail.test(newValue)
}

const validateNumber = (newValue) => {
    return validNumber.test(newValue)
}

export const fValidation = (newValue, type) => {
    switch(type){
        case 11 : // check whether it is a valid Mailadress
            return validateEmail(newValue)
        case 12: // check for valid number
            return validateNumber(newValue)
        default:
            return true
    }
}