import React, { Fragment } from 'react';

import { TranslateText } from '../../../../helpers/language';
import ComponentWrapper from   './components/ComponentWrapper';
import { getDummyUniversityCards } from '../../helpers/dummys/universityCards';

import Button from '../../../CmsPage/components/Component/components/Button';
import Card from '../../../CmsPage/components/Component/components/Card';
import UniversityCards from '../../../CmsPage/components/Component/components/Card/components/UniversityCards';
import Html from '../../../CmsPage/components/Component/components/Html';
import Image from '../../../CmsPage/components/Component/components/Image';
import Menu from '../../../CmsPage/components/Component/components/Menu';
import Title from '../../../CmsPage/components/Component/components/Title'
import Text from '../../../CmsPage/components/Component/components/Text';

import AccordionList from '../../../CmsPage/components/Component/components/AccordionList';
import Accordion from '../../../CmsPage/components/Component/components/AccordionList/components/Accordion';
import Carousel from '../../../CmsPage/components/Component/components/Carousel';
import Container from '../../../CmsPage/components/Component/components/Container';

import Header from '../../../CmsPage/components/Component/components/Header';
import Hero from '../../../CmsPage/components/Component/components/Hero';
import MainContainer from '../../../CmsPage/components/Component/components/MainContainer';
import Footer from '../../../CmsPage/components/Component/components/Footer';

import Form from './components/Form';


export default function Component(props) {

    const {component, draggable, ...rest} = props;

    const getComponent = () => {
        switch (component.component) {
            case 'cmsComponentAccordion':
                return <Accordion component={component} {...rest}/>
            case 'cmsComponentAccordionList':
                return <AccordionList component={component} {...rest}/>
            case 'cmsComponentButton':
                return <Button component={component} target='_blank'/>
            case 'cmsComponentCard':
                return <Card component={component} target='_blank'/>
            case 'cmsComponentCardList':
                return <UniversityCards component={{...component, data: getDummyUniversityCards(component.cardListType)}} target='_blank' />
            case 'cmsComponentCarousel':
                return <Carousel component={component} {...rest}/>
            case 'cmsComponentContainer':
                return <Container component={component} {...rest}/>       
                case 'cmsComponentForm':
                return <Form component={component} {...rest}/>
            case 'cmsComponentHtml':
                return <Html component={component}/>
            case 'cmsComponentImage':
                return <Image component={component} target='_blank'/>
            case 'cmsComponentMenu':
                return <Menu component={component} {...rest}/>
            case 'cmsComponentTitle':
                return <Title component={component}/>
            case 'cmsComponentText':
                return <Text component={component}/>
            case 'cmsPageHeader':
                return <Header component={component} {...rest}/>        
            case 'cmsPageHero':
                return <Hero component={component} {...rest}/>                
            case 'cmsPageMainContainer':
                return <MainContainer component={component} {...rest}/>
            case 'cmsPageFooter':
                return <Footer component={component} {...rest}/>    
            default:
                return <div>{TranslateText("cmsComponentMissing")}</div>
        }
    }

    return (
        <Fragment>
            {
                !component.deleted && 
                <ComponentWrapper {...props}>
                    {getComponent()}
                </ComponentWrapper>  
            }
        </Fragment>
    )
}