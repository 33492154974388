import React, {useEffect, useState, Fragment, useCallback} from "react";
import MenuLink from "./MenuLink";
import {staticLinks} from "../../../data/staticLinks";
import {getSubURL, hasBackendAccess, userLevel} from "../../../helper/User";
import {getLinks} from "../../../helper/Links";
import {getLanguageContent, TranslateText} from "../../../libs/LanguageProvider";
import {useModal} from "../../../libs/ModalProvider";
import Contact from "../../../views/Modals/Contact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

export default function Menu(props) {

    const {setModal, closeModal} = useModal()

    const onModalClick = (child) => {
        setModal(<div><Contact/></div>);
    }

    // props. back -> means there should not be an menu, instead an back-button
    return (
        <Fragment>
            {props.back &&
                <div
                    className={"md:container md:mx-auto bg-white sm:p-0 md:p-8 hidden md:block " + (props.className || "")}>
                    <a href={props.back}><FontAwesomeIcon icon={faAngleLeft} color="#2B2B38"/> <span
                        className="ml-2 text-sanofi-font-default hover:font-bold">{props.backTitle}</span></a>
                </div>

            }
            {!props.back &&
                <div
                    className={"md:container md:mx-auto bg-white sm:p-0 md:p-8 hidden md:block " + (props.className || "")}>


                    <div className={"md:flex text-center"}>

                        {getLinks(props.type, props.menueItems).map((link, idx) => {

                            if (!link.inMenuBar) {
                                return null
                            }

                            if (link.backendAccessRequired) {
                                if (!hasBackendAccess()) {
                                    return null
                                }
                            }

                            if (link.accessRequired) {
                                if (link.userLevelRequired < userLevel()) {
                                    return null
                                }
                            }

                            if (link.modal !== null) {
                                return (
                                    <div className={"grow"}>
                                        <a title={props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                           onClick={(e) => {
                                               onModalClick(link.modal)
                                           }}
                                           className={"cursor-pointer sanofi-menu-default uppercase"}>
                                            {props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                        </a>
                                    </div>
                                )
                            }

                            return (
                                <div className={"grow"}>
                                    <MenuLink href={link.to}
                                              title={props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                              target={props.target ?? '_self'}
                                              >
                                        {props.isCustom ? link.title : getLanguageContent(link.title, link.defaultTitle)}
                                    </MenuLink>
                                </div>
                            )

                        })}

                    </div>
                </div>
            }
        </Fragment>
    )


}